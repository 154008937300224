import React, { useState } from 'react'
import './HomeRegistration.scss'
import { Button } from 'react-bootstrap'
import Login from '../../Login/Login';
import SignUp from '../../SignUp/SignUp';
import OtpModal from '../../../Modal/OtpModal';
import EmailLinkForPassword from '../../EmailLinkForPassword/EmailLinkForPassword';
import SetUpAccount from '../../../Modal/SetUpAccount/SetUpAccount';

const HomeRegistration = () => {
    const [showLogin, setShowLogin] = useState(false);
    const [showModal, setShowModel] = useState(false)
    const [emailLinkModal, setEmailLinkModal] = useState(false)
    const [showSignUp, setShowSignUp] = useState(false)
    const [showSetUp, setShowSetUp] = useState(false)
    const [user, setUser] = useState(null)
    const [id, setId] = useState(null)
    const [token2, setToken2] = useState(null)
    const handleLoginClose = () => {
        setShowLogin(false)
      }
      const handleClose = () => {
        setShowModel(false)
      }
      const handleSignUpClose = () => {
        setShowSignUp(false)
      }
    
      const handleSetUpClose = () => {
        setShowSetUp(false)
      }
      const handleEmailLinkClose = () => {
        setEmailLinkModal(false)
      }
    return (
        <>
            <div className='home-register-wrapper'>
                <div className='main-wrapper'>
                    <div className='main-container'>
                        <div className='home-register-content-wrap'>
                            <div className='hr-title-wrap'>BATTLE AND RISE UP THE RANKS</div>
                            <div className='hr-description-wrap'>Join the leading global community of music creators to battle it out on the main stage! Showcase your talent, top the leaderboard, and earn Clout and recognition while connecting with others along the way.</div>
                            <div className='hr-btn-wrap'>
                                <Button variant='light' onClick={() =>{setShowLogin(true)}}>Login</Button>
                                <Button variant='primary' onClick={() =>{setShowSignUp(true)}}>Get Started</Button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {showLogin && <Login showLogin={showLogin} handleLoginClose={handleLoginClose} setShowLogin={setShowLogin} setShowModel={setShowModel} setEmailLinkModal={setEmailLinkModal} setShowSignUp={setShowSignUp} setUser={setUser} setShowSetUp={setShowSetUp} setId={setId} setToken2={setToken2} />}
            {showSignUp && <SignUp showSignUp={showSignUp} handleSignUpClose={handleSignUpClose} setShowSignUp={setShowSignUp} setShowLogin={setShowLogin} setShowModel={setShowModel} setUser={setUser} user={user} setId={setId} setToken2={setToken2} />}
            {showModal && <OtpModal showModal={showModal} handleClose={handleClose} setShowModel={setShowModel} user={user} setShowLogin={setShowLogin} setShowSetUp={setShowSetUp} id={id} token2={token2} />}
            {emailLinkModal && <EmailLinkForPassword emailLinkModal={emailLinkModal} handleEmailLinkClose={handleEmailLinkClose} setShowLogin={setShowLogin} />}
            {showSetUp && <SetUpAccount showSetUp={showSetUp} handleSetUpClose={handleSetUpClose} />}
        </>
    )
}

export default HomeRegistration