import React, { useEffect, useRef, useState } from 'react'
import { CloutIcon, PreviousArrow } from '../Icons'
import PlaceHolderImg from '../../assets/images/placeholder.png'
import Scrollbars from 'react-custom-scrollbars-2'
import './Wallet.scss'
import ProgressBar from 'react-bootstrap/ProgressBar';
import * as cs from '../../Api/cs'
import useHeader from '../../Helper/useHeader'
import { useDispatch, useSelector } from 'react-redux'
import { updateBadgeId, updateIsBadgeInfo, updateOpenDirectly } from '../../Redux/Slices/badge.slice'
import Badges from '../Badges/Badges'
import { updateActivityShow, updateWalletShow } from '../../Redux/Slices/modal.slice'

const Wallet = ({ setWalletShow, setBadgeShow, clout, setActivityShow }) => {

    const { walletShow } = useSelector(state => state.modal)
    const dispatch = useDispatch()
    const walletRef = useRef(null);

    const [earnBadge, setEarnBadge] = useState([])
    const [isWindow, setIsWindow] = useState(false);

    const handleBagdesViewAll = () => {
        // setWalletShow(false)
        dispatch(updateWalletShow(false))
        document.body.classList.remove('hidden');
        setBadgeShow(true)
    }
    const headers = useHeader()
    const { userId } = useSelector(state => state.user)


    useEffect(() => {
        getUserEarnedBagdes()
    }, [])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1200) {
                setIsWindow(true);
            } else {
                setIsWindow(false);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isWindow) {
            document.body.classList.add('hidden');
        } else {
            document.body.classList.remove('hidden');
        }
    }, [isWindow]);
    
    useEffect(() => {
        document.body.classList.toggle('hidden', isWindow);
        const handleClickOutside = (event) => {
            if (walletRef.current && !walletRef.current.contains(event.target)) {
                dispatch(updateWalletShow(false));
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        document.body.classList.toggle('hidden', isWindow);
        const handleClickOutside = (event) => {
            if (walletRef.current && !walletRef.current.contains(event.target)) {
                dispatch(updateWalletShow(false));
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const getUserEarnedBagdes = async () => {
        const response = await cs.get('getEarnedBadges/' + userId, 'badge', {}, headers)
        setEarnBadge(response?.data?.data)
    }

    const calculateProgress = (currentLevel, totalLevels) => {
        return (currentLevel / totalLevels) * 100;
    };

    const handleBadgeInfo = (id) => {
        dispatch(updateIsBadgeInfo(true))
        dispatch(updateBadgeId(id))
        dispatch(updateOpenDirectly(true))
        setBadgeShow(true)
    }
    const handleTotalBalance = () => {
        // setWalletShow(false)
        dispatch(updateWalletShow(false))
        dispatch(updateActivityShow(true))
        document.body.classList.remove('hidden');
        // setActivityShow(true)

    }
    const handlePreviousClick = () => {
        dispatch(updateWalletShow(false))
        document.body.classList.remove('hidden');

    }
    return (
        <>
            <div className='wallet-container' ref={walletRef}>
                <div className='wallet-header'>
                    <div className='back-btn' onClick={() => { handlePreviousClick() }}><PreviousArrow /></div>
                    <div className='wallet-title'>Wallet</div>
                </div>
                <div className='total-balance-wrapper cursor-pointer' onClick={() => { handleTotalBalance() }}>
                    <div className='total-balance box'>
                        <div className='total-balance-title'>Total balance</div>
                        <div className='clout-wrapper'>
                            <div className='icon-wrap'><CloutIcon /></div>
                            <div className='clout-balance'>{clout} Clout</div>
                        </div>
                        {/* <div className='clout-progress-wrap'>
                            <span> +10% since last week</span>
                        </div> */}
                    </div>
                </div>
                <div className='wallet-content-wrapper'>
                    <Scrollbars className='wallet-scroll-wrapper'>
                        <div className='wallet-section-wrapper'>
                            {/* Badge Section Start Here  */}
                            <div className='wallet-badge-section'>
                                <div className='wallet-badge-title-wrap'>
                                    <div className='wallet-badge-title'>Badges</div>
                                    <div className='view-all' onClick={() => { handleBagdesViewAll() }}>View all</div>
                                </div>
                                <div className='wallet-badge-list-wrap'>
                                    {earnBadge && earnBadge.length > 0 && earnBadge.map((item) => (
                                        <div className='wallet-badge-wrapper box cursor-pointer' key={item.id} onClick={() => { handleBadgeInfo(item?.badgeId?._id) }}>
                                            <div className='wallet-badge-img-wrap'>
                                                {item?.badgeId?.levels?.map(level => level.levelNumber === item.level && (
                                                    <img key={level._id} src={level.levelImage} alt='Badge' />
                                                ))}
                                            </div>
                                            <div className='wallet-badge-content-wrap'>
                                                <div className='wallet-badge-win-wrap'>
                                                    <div className='wallet-badge-win-title'>{item?.badgeId?.badgeName} Level {item?.level}</div>
                                                    {/* <div className='wallet-badge-win-count'>32/50 wins</div> */}
                                                </div>
                                                {/* <div className='total-win-text'>Total Battles Won</div> */}
                                                <div className='wallet-badge-progress-wrap'><ProgressBar variant='primary' now={item?.badgeId?.totalLevels === 1
                                                    ? 100
                                                    : calculateProgress(
                                                        item?.userQuantity != null ? item?.userQuantity : 0,
                                                        item?.badgeId?.levels[item?.level != null ? item?.level : 0]?.quantity
                                                    )} />
                                                </div>
                                            </div>
                                        </div>
                                    ))}



                                </div>
                            </div>
                            {/* My Trophies Section Start Here  */}
                            {/* <div className='my-trophy-wrapper'>
                                <div className='my-trophy-title'>My Trophies</div>
                                <div className='my-trophy-list-wrap'>
                                    <div className='my-trophy box'>
                                        <div className='trophy-img-wrap'><img src={PlaceHolderImg} alt='Trophy' /></div>
                                        <div className='trophy-info-wrap'>
                                            <div className='trophy-title'>
                                                Grand Champion
                                            </div>
                                            <div className='trophy-session'>Season 1</div>
                                        </div>
                                        <div className='trophy-recived-wrap'>
                                            <div className='trophy-recived-title'>Received</div>
                                            <div className='trophy-recived-date'>11/18/23</div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                    </Scrollbars>
                </div>
            </div>

        </>
    )
}

export default Wallet