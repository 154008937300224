import React, { useEffect, useState } from 'react'
import BattleCard from '../BattelCard/BattleCard'
import { Button } from 'react-bootstrap'
import './battles.scss'
import { ViewAllIcon } from '../Icons'
import useHeader from '../../Helper/useHeader'
import * as cs from '../../Api/cs'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateBattleId } from '../../Redux/Slices/battle.slice'

const EndingBattles = ({ genre = null }) => {

    const [endingBattle, setEndingBattle] = useState([])
    const headers = useHeader()
    const dispatch = useDispatch()
    const navigate = useNavigate()


    useEffect(() => {
        if (genre == null) {
            getEndingBattle()
        }
        else if (genre != null) {
            getEndingBattleOfGenre()
        }

    }, [])


    const getEndingBattle = async () => {
        const response = await cs.get('all/' + "end", 'battel', {}, headers)
        setEndingBattle(response?.data?.data.slice(0, 10))
    }

    const getEndingBattleOfGenre = async () => {
        const response = await cs.get('getNewOrEndingBattleByGenre/' + genre + '/' + "end", "battel", {}, headers)
        setEndingBattle(response?.data?.data.slice(0, 10))
    }

    const handleBattleCard = (item) => {
        
        dispatch(updateBattleId(item))
        navigate(`/battle/${item}`, { state: { itemData: item, battles: endingBattle } })
    }
    return (
        <>
            {endingBattle && endingBattle.length > 0 &&
                <div className='battles-container ending-battles'>
                    <div className='battles-title-wrapper'>
                        <div className='battles-title'>Ending Soon</div>
                        <div className='btn-wrap' onClick={() => {
                            navigate(`/genre/${genre ? genre : 'all'}/ending-soon`, {
                                state: {
                                    type: "battle",
                                    title: "Ending Soon",
                                    ...(genre !== null && { genre })
                                }
                            });
                        }}><Button variant='secondary'>View all</Button></div>
                    </div>
                    <div className='battles-content-wrap'>
                        {endingBattle && endingBattle?.length > 0 ?
                            endingBattle.map((item) => (
                                <div onClick={() => { handleBattleCard(item?._id) }}>
                                    <BattleCard battleId={item?._id} type={item?.battleOpponentStatus ? null : 'awaiting'} />
                                </div>
                            )) :
                            <div style={{ color: "white" }}>No Battle to End Soon</div>}
                    </div>
                    <div className='viewall-btn-wrap' onClick={() => {
                        navigate(`/genre/${genre ? genre : 'all'}/ending-soon`, {
                            state: {
                                type: "battle",
                                title: "Ending Soon",
                                ...(genre !== null && { genre })
                            }
                        });
                    }}><Button variant='secondary'><ViewAllIcon /> View all</Button></div>
                </div>
            }
        </>
    )
}

export default EndingBattles