import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import './ThanksModal.scss'
const ThanksModal = ({showModal, handleClose}) => {
    return (
        <>
            <Modal show={showModal} onHide={handleClose}  className='report-a-modal' size="sm"  aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                <Modal.Title >Thank you for letting us<br/> know about this</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='thanks-wrapper'>
                        <div className='thanks-message'>Your feedback helps us maintain a friendly and fun environment for everyone at CNTNDR</div>
                        <div className='btn-wrap'><Button variant='primary' onClick={handleClose}>Done</Button></div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ThanksModal