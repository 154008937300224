import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import GenreBannerImg from '../../../assets/images/genre-banner.jpg';
import './GenreBanner.scss'
import { Button } from 'react-bootstrap';
import useHeader from '../../../Helper/useHeader';
import * as cs from '../../../Api/cs'
import StartBattleModal from '../../../Modal/StartBattleModal/StartBattleModal';
import OpenBattleModal from '../../../Modal/OpenBattleModal/OpenBattleModal';
import Login from '../../Login/Login';
import SignUp from '../../SignUp/SignUp';
import OtpModal from '../../../Modal/OtpModal';
import EmailLinkForPassword from '../../EmailLinkForPassword/EmailLinkForPassword';
import SetUpAccount from '../../../Modal/SetUpAccount/SetUpAccount';


import { capitalizeFirstLetter, updateDocumentTitle } from '../../../Utils';

const GenreBanner = ({ genre }) => {
  const headers = useHeader()
  const { userId } = useSelector(state => state.user)

  const [genreData, setGenreData] = useState({})
  const [showModal, setShowModal] = useState(false);
  const [showOpenBattleModal, setShowOpenBattleModal] = useState(false);
  const [trackInfo, setTrackInfo] = useState('');
  const [battleOption, setBattleOption] = useState('')
  const [addToPlaylist, setAddToPlaylist] = useState(false);
  const [sort, setSort] = useState('dsc')
  const [filter, setFilter] = useState('abcd')
  const [battleList, setBattleList] = useState([])
  const [showLogin, setShowLogin] = useState(false);
  const [showOtpModal, setShowOtpModel] = useState(false)
  const [emailLinkModal, setEmailLinkModal] = useState(false)
  const [showSignUp, setShowSignUp] = useState(false)
  const [showSetUp, setShowSetUp] = useState(false)
  const [user, setUser] = useState(null)
  const [UserId, setUserId] = useState(null)
  const [token2, setToken2] = useState(null)

  const handleOpenBattleClose = () => {
    setShowOpenBattleModal(false);
  };
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleLoginClose = () => {
    setShowLogin(false)
  }
  const handleOtpClose = () => {
    setShowOtpModel(false)
  }
  const handleSignUpClose = () => {
    setShowSignUp(false)
  }
  const handleSetUpClose = () => {
    setShowSetUp(false)
  }
  const handleEmailLinkClose = () => {
    setEmailLinkModal(false)
  }

  const getBattleList = async () => {
    const response = await cs.get('list/' + sort + "/" + filter, 'battel', {}, headers)

    setBattleList(response?.data?.data)

  }
  useEffect(() => {
    getBattleList()
    getGenreDetail()

    return () => {
      updateDocumentTitle('');
    }
  }, [])

  const startBattle = () => {
    if (userId == null) {
      setShowLogin(true)
    }
    else {
      setShowModal(true)
    }
  }
  const getGenreDetail = async (req, res) => {
    const response = await cs.get('getGenreDetail/' + genre, 'genre', {}, headers);
    const genreLabels = response?.data?.data;
    handleUpdatePageTitle(genreLabels)
    setGenreData(response?.data?.data)
  }


  const handleUpdatePageTitle = data => {
    if (data?.label) {
      const title = `${capitalizeFirstLetter(data?.label)} Beat Battles | CNTNDR`
      updateDocumentTitle(title);
    }
  }

  return (
    <>
      <div className='genre-banner-wrapper'>
        <img src={genreData?.bannerImage ? genreData?.bannerImage : GenreBannerImg} alt='' />
        <div className='genre-banner'>
          <div className='genre-banner-content'>
            <div className='genre-banner-title'>{genreData?.label}</div>
            <div className='genre-banner-description'>Check out all of the {genreData?.label} battles </div>
            <div className='genre-banner-btn-wrap'>
              <Button variant='primary' type="button" onClick={startBattle}>Start Battle</Button>
            </div>
          </div>
        </div>
      </div>
      {showModal && <StartBattleModal showModal={showModal} handleClose={handleClose} setShowModal={setShowModal} setShowOpenBattleModal={setShowOpenBattleModal} setTrackInfo={setTrackInfo} setBattleOption={setBattleOption} setAddToPlaylist={setAddToPlaylist} addToPlaylist={addToPlaylist} genre={genre} />}
      {showOpenBattleModal && <OpenBattleModal showOpenBattleModal={showOpenBattleModal} trackInfo={trackInfo} setShowModal={setShowModal} setShowOpenBattleModal={setShowOpenBattleModal} battleOption={battleOption} getBattleList={getBattleList} handleOpenBattleClose={handleOpenBattleClose} addToPlaylist={addToPlaylist} />}
      {showLogin && <Login showLogin={showLogin} handleLoginClose={handleLoginClose} setShowLogin={setShowLogin} setShowModel={setShowOtpModel} setEmailLinkModal={setEmailLinkModal} setShowSignUp={setShowSignUp} setUser={setUser} setShowSetUp={setShowSetUp} setId={setUserId} setToken2={setToken2} />}
      {showSignUp && <SignUp showSignUp={showSignUp} handleSignUpClose={handleSignUpClose} setShowSignUp={setShowSignUp} setShowLogin={setShowLogin} setShowModel={setShowOtpModel} setUser={setUser} user={user} setId={setUserId} setToken2={setToken2} />}
      {showOtpModal && <OtpModal showModal={showOtpModal} handleClose={handleOtpClose} setShowModel={setShowOtpModel} user={user} setShowLogin={setShowLogin} setShowSetUp={setShowSetUp} id={UserId} token2={token2} />}
      {emailLinkModal && <EmailLinkForPassword emailLinkModal={emailLinkModal} handleEmailLinkClose={handleEmailLinkClose} setShowLogin={setShowLogin} />}
      {showSetUp && <SetUpAccount showSetUp={showSetUp} handleSetUpClose={handleSetUpClose} />}
    </>
  )
}

export default GenreBanner