import React, { useEffect, useState } from 'react'
import './BattleLeaderBoard.scss'
import GenreCard from '../../../Component/GenreCard/GenreCard'
import { Button } from 'react-bootstrap'
import { ViewAllIcon } from '../../../Component/Icons'
import useHeader from '../../../Helper/useHeader'
import * as cs from '../../../Api/cs'
import { useNavigate } from 'react-router-dom'

const BattleLeaderBoard = () => {
    const [leaderboard, setLeaderBoard] = useState([])

    const headers = useHeader()

    const navigate = useNavigate()
    useEffect(() => {
        getLeaderBoard()
    }, [])


    const getLeaderBoard = async () => {
        const response = await cs.get('allTopWinner', 'leaderboard', null, headers)
        setLeaderBoard(response?.data?.data)
    }
    return (
        <>
            <div className='battle-leaderboard-wrapper'>
                <div className='main-wrapper'>
                    <div className='main-container'>
                        <div className='battle-lb-title-wrap'>
                            <div className='battle-lb-title'>Leaderboards</div>
                        </div>
                        <div className='battle-lb-card-wrapper'>
                            {leaderboard && leaderboard?.length > 0 ?
                                leaderboard.map((item) => (
                                    <GenreCard item={item} key={item?.topWinners?.[0]?.genreId?._id}/>
                                ))
                                : <div>No Record Available!!</div>}

                            
                        </div>
                        <div className='viewall-btn-wrap'
                         onClick={() => {
                            navigate("/battle/all/leaderBoard", {
                              state: {
                                type: "leaderboard",
                                title: "Battle Leaderboards",
                              },
                            });
                          }}
                        ><Button variant='secondary'><ViewAllIcon /> View all</Button></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BattleLeaderBoard