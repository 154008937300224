import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { signUpSchema } from '../../ValidationSchema/validationSchema';
// import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './SignUp.css'
import { useNavigate } from 'react-router-dom';
import * as cs from '../../Api/cs'
import { EyeIcon, EyeIconOff, FacebookIcon, GoogleIcon } from '../../Component/Icons';
// import { auth, provider } from '../../Helper/config';
// import { signInWithPopup } from 'firebase/auth'
import { Modal, Button, Form } from 'react-bootstrap';
// import OtpModal from '../../Modal/OtpModal';
import { useDispatch } from 'react-redux';
import { updateLoginStatus, updateUserData, updateUserId } from '../../Redux/Slices/user.slice';
import { BallTriangle } from 'react-loader-spinner';
import { googleLogin, facebookLogin } from '../../Helper/SocialLogin.js';
import { SocketContext } from '../../Context/socket';
import Cookies from 'js-cookie';


const SignUp = ({ showSignUp, handleSignUpClose, setShowSignUp, setShowModel, setShowLogin, setUser, user, setId, setToken2, setShowSetUp }) => {

    const navigate = useNavigate();
    const [passwordFieldType, setPasswordFieldType] = useState('password');
    const [repeatPasswordFieldType, setRepeatPasswordFieldType] = useState('password');
    const [rememberMe, setRememberMe] = useState(false);
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);
    const socket = useContext(SocketContext)

    // const [showModal, setShowModel] = useState(false)
    // const [user, setUser] = useState(null)

    const dispatch = useDispatch()
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        setError
    } = useForm({
        resolver: yupResolver(signUpSchema),
        mode: 'onChange',
    });;

    const onSubmit = async (data) => {

        setLoading(true);
        setUser(data)
        const _data = {
            email: data?.email,
            password: data?.password,
            roleType: 2,
            provider: "Website"
        }
        const response = await cs.post('signUp', 'users', _data, null)

        if (response?.data?.code === 200) {
            setLoading(false);
            handleSignUpClose()
            setShowModel(true)
            dispatch(updateUserData(response?.data?.data?.user))
            setToken2(response?.data?.data.token)
            setId(response?.data?.data?.userId)

        } else {
            setLoading(false)
            toast.error(response?.data?.message);
        }

    }

    const handleGoodleLogin = async () => {

        const response = await googleLogin()

        dispatch(updateLoginStatus(true))
        dispatch(updateUserId(response?.user?._id))
        // toast.success("Login successful!");
        handleLiveStatus(response?.user?._id)
        localStorage.setItem("token", response?.token)
        handleSignUpClose()
        dispatch(updateUserData(response?.user))
        if (response?.user?.userName == null) {
            setShowSetUp(true)
        }
        navigate('/')

    }

    const handleFacebookLogin = async () => {
        const response = await facebookLogin()
        dispatch(updateLoginStatus(true))
        localStorage.setItem("token", response?.token)
        dispatch(updateUserId(response?.user?._id))
        handleLiveStatus(response?.user?._id)
        handleSignUpClose()
        dispatch(updateUserData(response?.user))
        if (response?.user?.userName == null) {
            setShowSetUp(true)
        }
        navigate('/')
    }

    const handleLiveStatus = (userId) => {
        let data = {
            id: userId,
            status: true,
        }

        socket.emit('live', data);
    }
    const togglePasswordVisibility = (fieldType, setFieldType) => {
        setFieldType((prevType) => (prevType === 'password' ? 'text' : 'password'));
    };

    const handleRememberMeChange = async (e) => {
        setRememberMe(e.target.checked);
        if (e.target.checked) {
            const email = getValues('email');
            const password = getValues('password');
            const userData = { email, password };
            const response = await cs.post('rememberMe', 'users', userData, null);
            if (response) {
                Cookies.set('rememberId', response?.data.data?._id, { expires: 365 });
            }
        }
    };



    const handleOtpSubmit = async () => {
        const _data = {
            "email": user?.email,
            "otp": otp
        }
        const res = await cs.post('otp', 'users', _data, null)
        if (res?.data?.code == 200) {
            setShowModel(false)
            setShowLogin(true)
            toast.success('User created successfully!');

        }

    }

    const handleClose = () => {
        setShowModel(false)
    }
    const handleInputChange = (e) => {
        setOtp(e.target.value)
    }

    const handleLoginModal = () => {
        setShowSignUp(false)
        setShowLogin(true)
    }

    return (

        <Modal show={showSignUp} onHide={handleSignUpClose} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
                {loading && ( // Show loader if loading state is true
                    <div className="loader-container">
                        <div className="loader">
                            <BallTriangle
                                type="TailSpin"
                                color="#00BFFF"
                                height={50}
                                width={50}
                            />
                        </div>
                    </div>
                )}
                <div className='auth-form-wrapper-main'>
                    <form className="auth-form-wrapper" onSubmit={handleSubmit(onSubmit)}>
                        <div className='auth-form-header'>
                            <div className='auth-form-title'>Sign up</div>
                            <div className='auth-form-link' onClick={handleLoginModal}> Already a member ?</div>
                        </div>

                        <div className="social-btn-wrap">
                            <Button variant="outline-secondary" type="button" onClick={handleGoodleLogin} ><GoogleIcon />Login with Google</Button>
                        </div>
                        <div className="social-btn-wrap">
                            <Button variant="outline-secondary" onClick={handleFacebookLogin}><FacebookIcon /> Login with Facebook</Button>
                        </div>
                        <div className='d-flex  w-100 justify-content-center text-white'>Or</div>
                        <div className='auth-form'>

                            <div className="form-row">

                                <Form.Control placeholder='E-mail' type="text" name="email"  {...register("email")}
                                    {...register('email')}
                                    onChange={(e) => {
                                        setValue('email', e.target.value, { shouldValidate: true });
                                    }}
                                />
                                {errors.email && <div className="errorMsg">{errors.email.message}</div>}
                            </div>
                            <div className="form-row">

                                <Form.Control placeholder='Password' type={passwordFieldType} name="password" {...register("password")}

                                    onChange={(e) => {
                                        setValue('password', e.target.value, { shouldValidate: true });
                                        if (errors.repeatPassword) {
                                            setError('repeatPassword', { message: '' });
                                        }
                                    }}
                                />
                                <div className='icon-wrap'
                                    type="button"
                                    onClick={() => togglePasswordVisibility(passwordFieldType, setPasswordFieldType)}
                                >
                                    {passwordFieldType === 'password' ? <EyeIcon /> : <EyeIconOff />}
                                </div>
                                {errors.password && <div className="errorMsg">{errors.password.message}</div>}
                            </div>

                            <div className="form-row">

                                <Form.Control placeholder='Repeat password' type={repeatPasswordFieldType} name="repeatPassword"  {...register("repeatPassword")}
                                    {...register('repeatPassword')}
                                    onChange={(e) => {
                                        setValue('repeatPassword', e.target.value, { shouldValidate: true, valueAsNumber: false });
                                        if (errors.password) {
                                            setError('password', { message: '' });
                                        }
                                    }}
                                />
                                <div className='icon-wrap'
                                    type="button"
                                    onClick={() => togglePasswordVisibility(repeatPasswordFieldType, setRepeatPasswordFieldType)}
                                >
                                    {repeatPasswordFieldType === 'password' ? <EyeIcon /> : <EyeIconOff />}
                                </div>
                                {errors.repeatPassword && <p className="errorMsg">{errors.repeatPassword.message}</p>}
                            </div>

                            <div className="form-row terms-container">
                                <div className="terms-text">
                                    <span>By signing up, you agree to CNTNDR's</span> <a href="#/legal" target="_blank">Terms & Conditions</a>,

                                </div>
                                <div className="terms-text">
                                    <a href="#/legal?menu=privacy_policy" target="_blank">Privacy Policy</a>, <span className="terms-text">and</span> <a href="#/legal?menu=community_guidelines" target="_blank">Community Guidelines</a>.
                                </div>

                            </div>


                            <div className="form-action-row">
                                <div className='remember-wrap flex-grow-1'>
                                    <Form.Check
                                        type='checkbox'
                                        id='rememberMe'
                                        {...register('rememberMe')}
                                        checked={rememberMe}
                                        onChange={handleRememberMeChange}
                                        label='Remember Me'
                                    />

                                </div>
                                <div className='flex-shrink-0 btn-wrap'> <Button variant='primary' type="submit">Sign Up</Button></div>
                            </div>
                        </div>
                    </form>





                    {/* <OtpModal showModal={showModal} handleClose={handleClose} setShowModel={setShowModel} user={user}/> */}

                </div>
            </Modal.Body>
        </Modal>
    )
}

export default SignUp
