import React, { useEffect, useState } from "react";
import "./TrendingBeats.scss";
import { Button } from "react-bootstrap";
import TrackPlayer from "../TrackPlayer/TrackPlayer";
import { ViewAllIcon } from "../Icons";
import useHeader from "../../Helper/useHeader";
import * as cs from "../../Api/cs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Login from "../../Pages/Login/Login";
import SignUp from "../../Pages/SignUp/SignUp";
import OtpModal from "../../Modal/OtpModal";
import EmailLinkForPassword from "../../Pages/EmailLinkForPassword/EmailLinkForPassword";
import SetUpAccount from "../../Modal/SetUpAccount/SetUpAccount";

const TrendingBeats = ({ genre = null }) => {
  const [trackList, setTrackList] = useState([]);
  const [showLogin, setShowLogin] = useState(false);
  const [showModal, setShowModel] = useState(false);
  const [emailLinkModal, setEmailLinkModal] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showSetUp, setShowSetUp] = useState(false);
  const [user, setUser] = useState(null);
  const [id, setId] = useState(null);
  const [token2, setToken2] = useState(null);
  const handleLoginClose = () => {
    setShowLogin(false);
  };
  const handleClose = () => {
    setShowModel(false);
  };
  const handleSignUpClose = () => {
    setShowSignUp(false);
  };

  const handleSetUpClose = () => {
    setShowSetUp(false);
  };
  const handleEmailLinkClose = () => {
    setEmailLinkModal(false);
  };

  const navigate = useNavigate();

  const { track, isPlaying } = useSelector((state) => state.player);
  const headers = useHeader();

  useEffect(() => {
    if (genre == null) {
      getTrendingTracks();
    } else if (genre != null) {
      getTrendingTracksOfGenre();
    }
  }, []);

  const getTrendingTracks = async () => {
    const response = await cs.get("all/countList", "track", null, headers);
    setTrackList(response?.data?.data?.slice(0, 10));
  };

  const getTrendingTracksOfGenre = async () => {
    const response = await cs.get(
      "trendingBeats/" + genre,
      "genre",
      null,
      headers
    );
    setTrackList(response?.data?.data.slice(0, 10));
  };
  return (
    <>
      {trackList && trackList.length > 0 && <div className="trending-beats-wrapper">
        <div className="main-wrapper">
          <div className="main-container">
            <div className="trending-beats-content-wrap">
              <div className="trending-beats-title-wrapper">
                <div className="trending-beats-title">Trending Beats</div>
                <div
                  className="btn-wrap"
                  onClick={() => {
                    navigate(`/genre/${genre ? genre : 'all'}/trending-beats`, {
                      state: {
                        type: "track",
                        title: "Trending Beats",
                        ...(genre !== null && { genre })
                      },
                    });
                  }}
                >
                  <Button variant="secondary">View All</Button>
                </div>
              </div>
              <div className="track-beats-wrapper">
                <div className="trending-beats">
                  {trackList &&
                    trackList?.length > 0 &&
                    trackList.map((item) => (
                      <TrackPlayer
                        key={item?._id}
                        trackToBePlayed={item}
                        isPlayingSong={
                          track?._id === item?._id && isPlaying ? true : false
                        }
                        list={trackList}
                      />
                    ))}
                </div>
                <div className="viewall-btn-wrap"
                  onClick={() => {
                    navigate("/treanding/all/beats", {
                      state: {
                        type: "track",
                        title: "Trending Beats",
                        ...(genre !== null && { genre })
                      },
                    });
                  }}
                >
                  <Button variant="secondary">
                    <ViewAllIcon /> View all
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
      {showLogin && (
        <Login
          showLogin={showLogin}
          handleLoginClose={handleLoginClose}
          setShowLogin={setShowLogin}
          setShowModel={setShowModel}
          setEmailLinkModal={setEmailLinkModal}
          setShowSignUp={setShowSignUp}
          setUser={setUser}
          setShowSetUp={setShowSetUp}
          setId={setId}
          setToken2={setToken2}
        />
      )}
      {showSignUp && (
        <SignUp
          showSignUp={showSignUp}
          handleSignUpClose={handleSignUpClose}
          setShowSignUp={setShowSignUp}
          setShowLogin={setShowLogin}
          setShowModel={setShowModel}
          setUser={setUser}
          user={user}
          setId={setId}
          setToken2={setToken2}
        />
      )}
      {showModal && (
        <OtpModal
          showModal={showModal}
          handleClose={handleClose}
          setShowModel={setShowModel}
          user={user}
          setShowLogin={setShowLogin}
          setShowSetUp={setShowSetUp}
          id={id}
          token2={token2}
        />
      )}
      {emailLinkModal && (
        <EmailLinkForPassword
          emailLinkModal={emailLinkModal}
          handleEmailLinkClose={handleEmailLinkClose}
          setShowLogin={setShowLogin}
        />
      )}
      {showSetUp && (
        <SetUpAccount
          showSetUp={showSetUp}
          handleSetUpClose={handleSetUpClose}
        />
      )}
    </>
  );
};

export default TrendingBeats;
