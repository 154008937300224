import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showPlayer: false,
  track: null,
  artistId: null,
  progress: 0,
  isPlaying: false,
  tracksPlayingState: {},
  playerFocus: true,
  playerSpaceBarRead:false,
  trackList : []
}

export const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    handleShowPlayer: (state, action) => {
      state.showPlayer = action.payload
    },
    updateTrack: (state, action) => {

      state.track = action.payload;
    },
    updateArtistId: (state, action) => {
      state.artistId = action.payload
    },
    updateTrackProgress: (state, action) => {
     
      state.progress = action.payload
    },
    updateIsPlaying: (state, action) => {
      state.isPlaying = action.payload
    },
    updatePlayerFocus: (state, action) => {
     
      state.playerFocus = action.payload
    },
    updateSpaceBarRead: (state, action) => {
     
      state.playerSpaceBarRead = action.payload
    },
    updateTrackPlayingState(state, action) {
      const { trackId, isPlaying } = action.payload;
      state.tracksPlayingState[trackId] = isPlaying;
    },
    updateTrackList(state,action)
    {
      state.trackList=action.payload
    }
  },
})

export const { handleShowPlayer, updateTrack, updateArtistId, updateTrackProgress,
   updateIsPlaying, updateTrackPlayingState, updatePlayerFocus, updateSpaceBarRead,updateTrackList } = playerSlice.actions

export default playerSlice.reducer