import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { CloseIcon, DeleteIcon, ImageIcon, TrackIcon } from '../../Component/Icons';
import Form from 'react-bootstrap/Form';
import { useForm, Controller } from 'react-hook-form';
import { addTrackSchema, trackSchema } from '../../ValidationSchema/validationSchema';
import CreatableSelect from 'react-select/creatable';
import { useSelector } from 'react-redux';
import * as cs from "../../Api/cs"
import useHeader from '../../Helper/useHeader';
import './AddNewTrackModal.scss'
import { useDropzone } from 'react-dropzone';
import { BallTriangle } from 'react-loader-spinner';

const AddNewTrackModal = ({ showAddTrack, handleAddTrackClose,getMyTrack }) => {
    const { control, handleSubmit, setValue, formState: { errors, isValid }, register, watch, useWatch, reset, clearErrors, trigger } = useForm({
        resolver: yupResolver(addTrackSchema),
        mode: 'onChange',
    });


    const [genreList, setGenreList] = useState([]);
    const [image, setImage] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [tag, setTag] = useState([]);
    const [moodValue, setMoodValue] = useState('');
    const [mood, setMood] = useState([]);
    const [trackDuration, setTrackDuration] = useState(0)
    const [loading, setLoading] = useState(false);
    

    const { userId } = useSelector(state => state.user)
    const headers = useHeader()

    const tagRef = useRef()
    const moodRef = useRef()

    useEffect(() => {

        if (watch('mood')) {
            trigger('mood');
        }
        if (watch('tag')) {
            trigger('tag');
        }
    }, [watch('mood'), watch('tag')]);

    const onSubmit = async (data) => {
        setLoading(true)
        let image
        let video
       
      
        if (data?.fileInput && data?.fileInput?.[0]) {
            const videoFormObj = new FormData()
            videoFormObj.append("data", data?.fileInput?.[0])

            video = await cs.post('upload', 'util', videoFormObj, headers)
       

        }

        if (data?.imageInput) {
            const imgFormObj = new FormData()
            imgFormObj.append("data", data?.imageInput?.[0])
            image = await cs.post('upload', 'util', imgFormObj, headers)
           
        }


        const track_data = {
            "track_image": image?.data?.data,
            "track_video" : video?.data?.data,
            "track_name" : data?.trackNameInput,
            "track_description" : data?.trackDescriptionInput,
            "track_genre": data?.genreSelect,
            "track_tags": data?.tag,
            "track_moods" :data?.mood,
            "status": "active",
            "duration" : trackDuration && trackDuration,
            "track_add": 1

        }
        
  
        const response = await cs.post('add', 'track', track_data, headers)
        setLoading(false)
        handleAddTrackClose()
        getMyTrack()
   }

 

    useEffect(()=>{
        if(inputValue.endsWith(" "))
        {
            if (inputValue?.trim() !== '') {
                const newTag = { label: inputValue.trim(), value: inputValue.trim() };
                const updatedTags = [...tag, newTag];
                setTag(updatedTags);
                setValue('tag', updatedTags.map(tag => tag.value));
                setInputValue('');
            }
        }
        if(moodValue.endsWith(" "))
    {
        if (moodValue?.trim() !== '') {
            const newTag = { label: moodValue.trim(), value: moodValue.trim() };
            const updatedTags = [...mood, newTag];
            setMood(updatedTags);
            setValue('mood', updatedTags?.map(mood => mood?.value));
            setMoodValue('');
        }
    }
      },[inputValue,moodValue])

    const getGenreList = async () => {
        const res = await cs.get('list', 'genre', null, headers)
        setGenreList(res?.data?.data)
    }


   

    useEffect(() => {
        getGenreList()
    }, [])

   const getTrackDuration = (file) => {
    const audio = new Audio(URL.createObjectURL(file));
    audio.addEventListener('loadedmetadata', () => {
        const durationInSeconds = audio.duration;
        const minutes = Math.floor(durationInSeconds / 60);
        const seconds = Math.floor(durationInSeconds % 60);
        const formattedDuration = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        setTrackDuration(formattedDuration);
    
        setTrackDuration(formattedDuration);
    });
  };

  const handleFileSelect = (e) => {
    setValue('fileInput', e.target.files);
    setSelectedFile(e.target.files[0]);
    clearErrors('fileInput');
    getTrackDuration(e.target.files[0]); // Get duration when file is selected
  };

  const handleImageSelect = (acceptedFiles) => {
    setValue('imageInput', acceptedFiles);
    setImage(URL.createObjectURL(acceptedFiles[0]));
    clearErrors('imageInput');
    trigger("imageInput");
  };

  const handleAudioSelect = (acceptedFiles) => {
    setValue('fileInput', acceptedFiles);
    setSelectedFile(acceptedFiles[0]);
    clearErrors('fileInput');
    getTrackDuration(acceptedFiles[0]);
    trigger("fileInput");
  };

  const { getRootProps: getRootPropsForImage, getInputProps: getInputPropsForImage } = useDropzone({
    accept: 'image/*',
    onDrop: handleImageSelect,
  });

  const { getRootProps: getRootPropsForAudio, getInputProps: getInputPropsForAudio } = useDropzone({
    accept: '.mp3,.mp4',
    onDrop: handleAudioSelect,
  });

    return (
        <Modal show={showAddTrack} onHide={handleAddTrackClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add A New Track</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {loading && ( // Show loader if loading state is true
                        <div className="loader-container">
                            <div className="loader">
                                <BallTriangle
                                    type="TailSpin"
                                    color="#00BFFF"
                                    height={50}
                                    width={50}
                                />
                            </div>
                        </div>
                    )}
                <div className="w-100">
                    <form className='battle-form' onSubmit={handleSubmit(onSubmit)}>

                        <div className="add-new-track">
                            <>
                                <Form.Group className='file-upload-image  form-row' >
                                    {!image &&
                                        <label htmlFor="imageInput" {...getRootPropsForImage()} className='file-upload'>
                                            <ImageIcon />
                                            {/* <input type="file"
                                                id="imageInput"
                                                accept="image/*"
                                                onChange={(e) => {
                                                    setValue('imageInput', e.target.files);
                                                    setImage(URL.createObjectURL(e.target.files[0]));
                                                    clearErrors('imageInput');
                                                }} /> */}
                                                <input {...getInputPropsForImage()} accept="image/*" />
                                            <div className='file-upload-text'>
                                                Choose image<br />
                                                or drop it here
                                            </div>
                                        </label>
                                    }
                                    {/*Image upload functionality done here */}

                                    {image &&
                                        <div className='image-upload-holder'>
                                            <img src={image} alt='user' />
                                            <div className='delete-img-wrap' onClick={() => setImage(null)}>
                                                <DeleteIcon />
                                            </div>
                                        </div>
                                    }

                                    {errors.imageInput && <div className="errorMsg">{errors.imageInput.message}</div>}
                                </Form.Group>

                                <Form.Group className='form-row file-upload-audio'>


                                    {!selectedFile &&
                                        <> <label htmlFor="fileInput" {...getRootPropsForAudio()} className='file-upload'>
                                            {/* <input type="file" id="fileInput" accept=".mp3,.mp4" onChange={handleFileSelect} /> */}
                                            <input {...getInputPropsForAudio()} />

                                            <div className='icon-wrap'><TrackIcon /></div>
                                            <div className='file-upload-text'>Choose track or <br />
                                                drop it here <br />
                                                (in .mp3 format)</div>
                                        </label>
                                        </>
                                    }
                                    {/*Remove Audion track name */}
                                    {selectedFile && <>
                                        <label >
                                            <div className='icon-wrap'><TrackIcon /></div>
                                            <div className='file-upload-text'>{selectedFile.name}
                                            </div>
                                            <div className='close-wrap' onClick={() => setSelectedFile(null)}>
                                                <CloseIcon />
                                            </div>
                                        </label>
                                    </>}



                                    {errors.fileInput && <div className="errorMsg">{errors.fileInput.message}</div>}
                                </Form.Group>

                                <Form.Group className='form-row' >
                                    <Form.Control type="text" id="trackNameInput" {...register('trackNameInput')} placeholder='Track Name' />
                                    {errors.trackNameInput && <div className="errorMsg">{errors.trackNameInput.message}</div>}
                                </Form.Group>
                                {/* 
                                <Form.Group className='form-row' >
                                    <Form.Control type="textarea" id="trackDescriptionInput" {...register('trackDescriptionInput')} placeholder='Track Description' />
                                    {errors.trackDescriptionInput && <div className="errorMsg">{errors.trackDescriptionInput.message}</div>}
                                </Form.Group> */}

                                <Form.Group className='form-row'>
                                    <Form.Control as="textarea" id="trackDescriptionInput" {...register('trackDescriptionInput')} placeholder='Track Description' />
                                    {errors.trackDescriptionInput && <div className="errorMsg">{errors.trackDescriptionInput.message}</div>}
                                </Form.Group>


                                <Form.Group className='form-row'>
                                    <Form.Select id="genreSelect" {...register('genreSelect')}>
                                        <option value="">Select Genre</option>
                                        {genreList?.map((genre) => (
                                            <option key={genre?._id} value={genre?._id}>
                                                {genre?.label}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    {errors.genreSelect && <div className="errorMsg">{errors.genreSelect.message}</div>}
                                </Form.Group>


                                <Form.Group className="form-row react-select">
                                    <CreatableSelect
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                        inputValue={moodValue}
                                        isClearable
                                        isMulti
                                        {...register('mood')}
                                        menuIsOpen={false}
                                        onChange={(selectedTags, actionMeta) => {
                                            const selectedTagValues = selectedTags.map(mood => mood.value);
                                            setMood(selectedTags);
                                            setValue('mood', selectedTagValues);
                                        }}
                                        onInputChange={(newValue) => setMoodValue(newValue)}
                                        // onKeyDown={moodHandleKeyDown}
                                        placeholder="Moods (3)"
                                        value={mood}
                                        ref = {moodRef}
                                    />
                                    {errors?.mood && <p className="errorMsg">{errors?.mood?.message}</p>}
                                </Form.Group>

                                <Form.Group className="form-row react-select">
                                    <CreatableSelect
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                        inputValue={inputValue}
                                        isClearable
                                        isMulti
                                        {...register('tag')}
                                        menuIsOpen={false}
                                        onChange={(selectedTags, actionMeta) => {
                                            const selectedTagValues = selectedTags.map(tag => tag.value);
                                            setTag(selectedTags);
                                            setValue('tag', selectedTagValues);
                                        }}
                                        onInputChange={(newValue) => setInputValue(newValue)}
                                        // onKeyDown={handleKeyDown}
                                        placeholder="Tags (3)"
                                        value={tag}
                                        ref={tagRef}
                                    />
                                    {errors?.tag && <p className="errorMsg">{errors?.tag?.message}</p>}
                                </Form.Group>



                            </>




                        </div>


                        <div className='d-flex justify-content-center'>
                            <Button variant="primary" type="submit" disabled={!isValid}>
                                Publish Track
                            </Button>
                        </div>
                    </form>
                </div>
            </Modal.Body>

        </Modal>
    )
}

export default AddNewTrackModal
