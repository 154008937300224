import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

import './BestSellers.scss'
import BestSellerImg from '../../../assets/images/best-seller.png'
import { CartIcon, ShoppingBagIcon } from '../../../Component/Icons';

function BestSellers() {

    return (
        <>
            <div className='bestseller-main-wrapper'>
                <div className='main-wrapper'>
                    <div className='bestseller-wrapper'>
                        <div className='bestseller-title-wrap'>
                            <div className='bestseller-title'>Bestsellers</div>

                        </div>
                        <div className='bestseller-slider'>
                            <Swiper
                                spaceBetween={22}
                                slidesPerView={4.4}
                                breakpoints={{
                                    1400:{
                                        slidesPerView: 4.4  
                                    },
                                   
                                    768:{
                                        slidesPerView: 3.4  
                                    },
                                   
                                    650:{
                                        slidesPerView: 2.4
                                    },
                                    300:{
                                        slidesPerView: 1.4
                                    }
                                }}

                            >
                                <SwiperSlide>
                                    <div className='bestseller-info-wrap'>
                                        <div className='bestseller-img-wrap'>
                                            <img src={BestSellerImg} alt='BestSeller' />

                                            <div className='bestseller-info'>
                                                <div className='bestseller-name'>Name</div>
                                                <div className='bestseller-price'>$ 1000</div>
                                            </div>
                                            <div className='shop-bag-wrap'>
                                                <CartIcon />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='bestseller-info-wrap'>
                                        <div className='bestseller-img-wrap'>
                                            <img src={BestSellerImg} alt='BestSeller' />
                                            <div className='bestseller-info'>
                                                <div className='bestseller-name'>Name</div>
                                                <div className='bestseller-price'>$ 1000</div>
                                            </div>
                                            <div className='shop-bag-wrap'>
                                                <CartIcon />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='bestseller-info-wrap'>
                                        <div className='bestseller-img-wrap'>
                                            <img src={BestSellerImg} alt='BestSeller' />
                                            <div className='bestseller-info'>
                                                <div className='bestseller-name'>Name</div>
                                                <div className='bestseller-price'>$ 1000</div>
                                            </div>
                                            <div className='shop-bag-wrap'>
                                                <CartIcon />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='bestseller-info-wrap'>
                                        <div className='bestseller-img-wrap'>
                                            <img src={BestSellerImg} alt='BestSeller' />
                                            <div className='bestseller-info'>
                                                <div className='bestseller-name'>Name</div>
                                                <div className='bestseller-price'>$ 1000</div>
                                            </div>
                                            <div className='shop-bag-wrap'>
                                                <CartIcon />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='bestseller-info-wrap'>
                                        <div className='bestseller-img-wrap'>
                                            <img src={BestSellerImg} alt='BestSeller' />
                                            <div className='bestseller-info'>
                                                <div className='bestseller-name'>Name</div>
                                                <div className='bestseller-price'>$ 1000</div>
                                            </div>
                                            <div className='shop-bag-wrap'>
                                                <CartIcon />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='bestseller-info-wrap'>
                                        <div className='bestseller-img-wrap'>
                                            <img src={BestSellerImg} alt='BestSeller' />
                                            <div className='bestseller-info'>
                                                <div className='bestseller-name'>Name</div>
                                                <div className='bestseller-price'>$ 1000</div>
                                            </div>
                                            <div className='shop-bag-wrap'>
                                                <CartIcon />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='bestseller-info-wrap'>
                                        <div className='bestseller-img-wrap'>
                                            <img src={BestSellerImg} alt='BestSeller' />
                                            <div className='bestseller-info'>
                                                <div className='bestseller-name'>Name</div>
                                                <div className='bestseller-price'>$ 1000</div>
                                            </div>
                                            <div className='shop-bag-wrap'>
                                                <CartIcon />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>

                            </Swiper>
                        </div>
                    </div>
                </div>

            </div>


        </>
    )
}

export default BestSellers