import React from 'react';
import HomeBannerImg from '../../../assets/images/home-banner.png'
import Logo from '../../../assets/images/logo.svg'
import './HomeBanner.scss'

const HomeBanner = () => {
    return (
        <>
            <div className='home-banner-wrapper'>
                <img src={HomeBannerImg} alt='Home Banner' />
                <div className='banner-wrapper'>
                    <div className='banner-content'>
                        <div className='banner-logo'><img src={Logo} alt='CNTNDR' className='' /></div>
                        <div className='tagline-wrapper'>
                            <div className='tagline'>CREATE.</div>
                            <div className='tagline'>COLLAB.</div>
                            <div className='tagline'>COMPETE.</div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default HomeBanner