import axios from "axios"

import { API_URL } from '../_Config';



export const post = async (url, type, data, headers) => {
    try {
        const res = await axios.post(`${API_URL}/${type}/${url}`, data, headers)
        return res
    } catch (error) {

    }
}

export const get = async (url, type, params, headers) => {
    try {
        const res = await axios.get(`${API_URL}/${type}/${url}`, {
            params: params,
            headers: headers?.headers
        });

        return res
    } catch (error) {

    }
}

export const patch = async (url, type, data, params, headers) => {

    try {
        const res = await axios.patch(`${API_URL}/${type}/${url}`, data, {
            params: params,
            headers: headers?.headers
        });

        return res
    } catch (error) {

    }
}

export const put = async (url, type, data, params, headers) => {

    try {
        const res = await axios.put(`${API_URL}/${type}/${url}`, data, {
            params: params,
            headers: headers?.headers
        });
        return res;
    } catch (error) {

    }
};