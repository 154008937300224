import React, { useEffect, useRef, useState } from "react";
import Header from "../../Component/Header/Header";
import Sidebar from "../../Component/Sidebar/Sidebar";
import "./TrackPage.scss";
import {
  ColoredStarIcon,
  PauseIcon,
  PlayIcon,
  ShareIcon,
  StarIcon,
  ViewAllIcon,
} from "../../Component/Icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button } from "react-bootstrap";
import BattleCard from "../../Component/BattelCard/BattleCard";
import Comment from "../../Pages/Comment/Comment";
import TrackPlayer from "../../Component/TrackPlayer/TrackPlayer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as cs from "../../Api/cs";
import useHeader from "../../Helper/useHeader";
import { updateBattleId } from "../../Redux/Slices/battle.slice";
import { useDispatch, useSelector } from "react-redux";
import {
  handleShowPlayer,
  updateArtistId,
  updateIsPlaying,
  updateTrack,
  updateTrackProgress,
} from "../../Redux/Slices/player.slice";
import SetUpAccount from "../../Modal/SetUpAccount/SetUpAccount";
import EmailLinkForPassword from "../EmailLinkForPassword/EmailLinkForPassword";
import OtpModal from "../../Modal/OtpModal";
import SignUp from "../SignUp/SignUp";
import Login from "../Login/Login";
import ReportModal from "../../Modal/ReportModal/ReportModal";
import { updateSearch, updateTag, updateTagSearch } from "../../Redux/Slices/search.slice";
import ThanksModal from "../../Modal/ThanksModal/ThanksModal";
import { ShareButtons } from "../../Helper/ShareButtons";


import { capitalizeFirstLetter, updateDocumentTitle } from '../../Utils';

const TrackPage = () => {
  const location = useLocation();
  const location_data = location;


  const url = `https://mean.stagingsdei.com:3544/#${location_data?.pathname}`;
  const title = "CNTNDR MUSIC ";
  // const { trackId } = location.state
  const { id } = useParams();
  const headers = useHeader();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [play, setPlay] = useState(false);
  const [currentTrack, setCurrentTrack] = useState({});
  // const [track, setTrack] = useState({})
  const [battleList, setBattleList] = useState([]);
  const [otherTrack, setOtherTrack] = useState([]);
  // const [showPlayer, setShowPlayer] = useState(false)
  const [artistId, setArtistId] = useState(null);
  // const [playerTrack, setPlayerTrack] = useState({})
  const [isFav, setIsFav] = useState(false);
  const [isPlayingSong, setIsPlayingSong] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [thanksModal, setThanksModal] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showModal, setShowModel] = useState(false);
  const [emailLinkModal, setEmailLinkModal] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showSetUp, setShowSetUp] = useState(false);
  const [user, setUser] = useState(null);
  const [_id, set_Id] = useState(null);
  const [token2, setToken2] = useState(null);
  const [share, setShare] = useState(false);
  // const audioRef = useRef(null);

  // const handleCloutShow = () => setShowCloutModal(true);
  // const handleCloutClose = () => setShowCloutModal(false);
  // const handleReportLoginClose = () => setReportLoginModal(false)
  const handleReportClose = () => setReportModal(false);
  const handleLoginClose = () => {
    setShowLogin(false);
  };
  const handleClose = () => {
    setShowModel(false);
  };
  const handleSignUpClose = () => {
    setShowSignUp(false);
  };

  const handleSetUpClose = () => {
    setShowSetUp(false);
  };
  const handleEmailLinkClose = () => {
    setEmailLinkModal(false);
  };
  const handleThanksClose = () => {
    setThanksModal(false);
  };

  const handleUpdatePageTitle = track => {
    if (track) {
      const trackName = capitalizeFirstLetter(track.track_name);
      const trackAuthor = capitalizeFirstLetter(track?.createdBy?.name);
      const title = `${trackName} by ${trackAuthor} | CNTNDR`
      updateDocumentTitle(title);
    }
  }


  const { showPlayer, isPlaying, track } = useSelector((state) => state.player);
  const { userId } = useSelector((state) => state.user);

  useEffect(() => {
    setIsPlayingSong(isPlaying);
  }, [isPlaying]);

  const handleTrack = () => {
    if (!showPlayer) {
      dispatch(handleShowPlayer(true));
    }

    if (!currentTrack || currentTrack?._id !== track?._id) {
      // If it's a new track, start playing from the beginning
      dispatch(updateTrack(currentTrack));
      dispatch(updateTrackProgress(0));
      dispatch(updateArtistId(currentTrack?.createdBy?._id));
      dispatch(updateIsPlaying(true));
    } else if (isPlaying) {
      // If it's the same track, toggle play/pause
      dispatch(updateIsPlaying(false));
      setIsPlayingSong(false);
    } else if (!isPlaying) {
      dispatch(updateIsPlaying(true));
      setIsPlayingSong(true);
    }
  };
  const { search } = useSelector((state) => state.search);

  useEffect(() => {
    if (search) {
      dispatch(updateSearch(""));
    }
    scrollToTop();
    getTrackDetail();
    getBattleList();
    favoriteCheck();

    return () => {
      updateDocumentTitle('');
    }
  }, []);



  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const getBattleList = async () => {
    const response = await cs.get("otherBattles/" + id, "battel", {}, headers);
    setBattleList(response?.data?.data);
  };

  const getTrackDetail = async () => {
    const response = await cs.get("" + id, "track", {}, headers);

    const track = response?.data?.data?.[0];
    console.log('responseeee track', track)
    handleUpdatePageTitle(track)
    setCurrentTrack(track);
    otherTrackByAuthor(track?.createdBy?._id);
    // audioRef.current.src = response?.data?.data?.[0]?.track_video
  };
  const handleBattleCard = (item) => {
    navigate(`/battle/${item}`, {
      state: { itemData: item, battles: battleList },
    });
    dispatch(updateBattleId(item));
  };

  const otherTrackByAuthor = async (id) => {
    const response = await cs.get("createdBy/" + id, "track", {}, headers);
    setOtherTrack(response?.data?.data);
  };

  const handleTrackPlayer = (item) => {
    dispatch(updateIsPlaying(true));
    dispatch(updateTrack(item));
  };

  const favoriteCheck = async () => {
    const response = await cs.get(
      "list/" + userId + "/" + id,
      "myFav",
      {},
      headers
    );
    if (response?.data?.value === true) {
      setIsFav(true);
    } else if (response?.data?.value === false) {
      setIsFav(false);
    }
  };

  const addToFavorite = async () => {
    const _data = {
      trackId: id,
      trackName: currentTrack?.track_name,
      userId: userId,
    };



    const response = await cs.post("add", "myFav", _data, null, headers);
    setIsFav(response?.data?.status);
  };

  const getPayPause = () => {
    if (isPlaying && isPlayingSong && currentTrack?._id === track?._id) {
      return <PauseIcon />

    }
    else {
      return <PlayIcon />
    }
  };

  const handleReportModal = async () => {
    if (userId === null) {
      setShowLogin(true);
    } else {
      dispatch(updateTrack(currentTrack));
      setReportModal(true);
    }
  };

  const handleTagSearch = (item) => {

    dispatch(updateTag(item))
    dispatch(updateTagSearch(true))
    navigate('/search')
  }
  return (
    <>
      <Header />
      <Sidebar />
      <div className="track-main-wrapper">
        <div className="main-wrapper">
          <div className="main-container">
            <div className="track-detail-wrapper">
              <div className="track-thumbnail-wrap">
                {/* <audio controls ref={audioRef} className="d-none" >
                  {audioRef?.current?.src && <source src={audioRef?.current?.src} type="audio/mp3" />}
                </audio> */}
                <div className="icon-wrap" onClick={handleTrack}>
                  {getPayPause()}
                </div>
                <img src={currentTrack?.track_image} alt="Track" />
              </div>
              <div className="track-detail-wrap">
                <div className="track-author-details-wrap">
                  <div className="track-detail-header-wrap">
                    <div className="track-detail-header">
                      {currentTrack?.track_name}
                    </div>
                    <div className="track-detail-header-right">
                      <div className="track-report" onClick={handleReportModal}>
                        Report
                      </div>
                      <div className="icon-container">
                        <div className="icon-wrap" onClick={addToFavorite}>
                          {isFav ? <ColoredStarIcon /> : <StarIcon />}
                        </div>


                        <div
                          className="icon-wrap" >
                          {share && (
                            <div className="share-buttons">
                              <ShareButtons url={url} title={title} />
                            </div>
                          )}
                          <div onClick={() => setShare(!share)}>
                            <ShareIcon />
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                  <div
                    className="track-detail-author-name cursor-pointer"
                    onClick={() => {
                      navigate(`/profile/${currentTrack?.createdBy?._id}`, {
                        state: {
                          type: "user",
                          id: currentTrack?.createdBy?._id,
                        },
                      });
                    }}
                  >
                    {currentTrack?.createdBy?.name
                      ? currentTrack?.createdBy?.name
                      : currentTrack?.createdBy?.userName}
                  </div>
                  <div className="track-detail-description-wrap">
                    {currentTrack?.track_description}
                  </div>
                </div>
                <div className="track-detail-tag-wrap">
                  <div className="track-detail-title">Genres</div>
                  <div className="tag-wrapper">
                    <div
                      className="tag cursor-pointer"
                      onClick={() =>
                        navigate(`/genre/${currentTrack?.track_genre?._id}`, {
                          state: { genre: currentTrack?.track_genre?._id },
                        })
                      }
                    >
                      {currentTrack?.track_genre?.label}
                    </div>
                  </div>
                </div>
                {currentTrack?.track_tags &&
                  currentTrack?.track_tags?.length > 0 && (
                    <div className="track-detail-tag-wrap">
                      <div className="track-detail-title">Tags</div>
                      <div className="tag-wrapper">
                        <div className="tag-wrapper">
                          {currentTrack?.track_tags?.map((item) => (
                            <div className="tag" onClick={() => { handleTagSearch(item) }}>{item}</div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>

          <div className="other-battle-wrapper desktop">
            {battleList?.length > 0 ? (
              <div className="other-battle-title">
                Battles for {currentTrack?.track_name}
              </div>
            ) : (
              <div className="other-battle-title">

              </div>
            )}

            <div className="other-battle-slider ">
              <Swiper
                spaceBetween={20}
                breakpoints={{
                  1500: {
                    slidesPerView: 3.9,
                  },
                  1250: {
                    slidesPerView: 2.9,
                  },
                  991: {
                    slidesPerView: 1.9,
                  },
                  300: {
                    enabled: false,
                  },
                }}
              >
                {battleList &&
                  battleList?.map((item) => (
                    <SwiperSlide
                      onClick={() => {
                        handleBattleCard(item?._id);
                      }}
                    >
                      {" "}
                      <BattleCard
                        battleId={item?._id}
                        type={item?.battleOpponentStatus ? null : "awaiting"}
                      />{" "}
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>

          <div className="main-container">
            <div className="track-comment-wrapper">
              <div className="track-comment-wrap">
                <Comment type="track" id={id} />
              </div>
              <div className="other-battle-wrapper mobile">
                {battleList?.length > 0 ? (
                  <div className="other-battle-title">
                    Battles for {currentTrack?.track_name}
                  </div>
                ) : (
                  <div className="other-battle-title">
                    {" "}
                  </div>
                )}
                <div className="other-battle-slider">
                  <Swiper
                    spaceBetween={20}
                    breakpoints={{
                      1500: {
                        slidesPerView: 3.9,
                      },
                      1250: {
                        slidesPerView: 2.9,
                      },
                      768: {
                        slidesPerView: 1.9,
                      },
                      300: {
                        enabled: false,
                      },
                    }}
                  >
                    {battleList?.map((item) => (
                      <SwiperSlide
                        onClick={() => {
                          handleBattleCard(item?._id);
                        }}
                      >
                        {" "}
                        <BattleCard
                          battleId={item?._id}
                          type={item?.battleOpponentStatus ? null : "awaiting"}
                        />{" "}
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div
                    className="viewall-btn-wrap"
                    onClick={() => {
                      navigate("/battle/all/Recommended", {
                        state: {
                          type: "battle",
                          title: `Battles for ${currentTrack?.track_name}`,
                          trackId: id,
                        },
                      });
                    }}
                  >
                    <Button variant="secondary">
                      <ViewAllIcon /> View all
                    </Button>
                  </div>
                </div>
              </div>

              <div className="other-track-wrap">
                <div className="other-track--title">
                  Other tracks by {currentTrack?.createdBy?.name}
                </div>
                <div className="other-track">
                  {otherTrack?.length > 0 &&
                    otherTrack?.map((item) => (
                      item?._id !== currentTrack?._id && (
                        <div
                          onClick={() => {
                            handleTrackPlayer(item);
                          }}
                        >
                          <TrackPlayer
                            trackToBePlayed={item}
                            isPlayingSong={
                              track?._id === item?._id && isPlaying ? true : false
                            }
                            list={otherTrack}
                          />
                        </div>
                      )))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {reportModal && (
        <ReportModal
          showModal={reportModal}
          handleClose={handleReportClose}
          setThanksModal={setThanksModal}
          type="track"
        />
      )}
      {thanksModal && (
        <ThanksModal showModal={thanksModal} handleClose={handleThanksClose} />
      )}
      {showLogin && (
        <Login
          showLogin={showLogin}
          handleLoginClose={handleLoginClose}
          setShowLogin={setShowLogin}
          setShowModel={setShowModel}
          setEmailLinkModal={setEmailLinkModal}
          setShowSignUp={setShowSignUp}
          setUser={setUser}
          setShowSetUp={setShowSetUp}
          setId={set_Id}
          setToken2={setToken2}
        />
      )}
      {showSignUp && (
        <SignUp
          showSignUp={showSignUp}
          handleSignUpClose={handleSignUpClose}
          setShowSignUp={setShowSignUp}
          setShowLogin={setShowLogin}
          setShowModel={setShowModel}
          setUser={setUser}
          user={user}
          setId={set_Id}
          setToken2={setToken2}
        />
      )}
      {showModal && (
        <OtpModal
          showModal={showModal}
          handleClose={handleClose}
          setShowModel={setShowModel}
          user={user}
          setShowLogin={setShowLogin}
          setShowSetUp={setShowSetUp}
          id={_id}
          token2={token2}
        />
      )}
      {emailLinkModal && (
        <EmailLinkForPassword
          emailLinkModal={emailLinkModal}
          handleEmailLinkClose={handleEmailLinkClose}
          setShowLogin={setShowLogin}
        />
      )}
      {showSetUp && (
        <SetUpAccount
          showSetUp={showSetUp}
          handleSetUpClose={handleSetUpClose}
        />
      )}
    </>
  );
};

export default TrackPage;
