import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import PlaceHolderImg from '../../assets/images/chat-placeholder.png';
import { Scrollbars } from 'react-custom-scrollbars-2';
import './FanModal.scss'
import { useSelector } from 'react-redux';
import useHeader from '../../Helper/useHeader';
import * as cs from '../../Api/cs'
import UserImg from '../../assets/images/default.png'
import { useNavigate } from 'react-router-dom';

const FanModal = ({ fanModal, setFanModal, artistId, getFollowingFollowers}) => {

  const headers = useHeader()
  const navigate = useNavigate()

  const { userId } = useSelector(state => state.user)

  const [fan, setFan] = useState([])

  useEffect(() => {
    getFanData()
  }, [])


  const getFanData = async () => {

    const response = await cs.get('getAllFollowers/' + artistId + '/' + 'active', 'follow', {}, headers)
    setFan(response?.data?.data)
  }

  const removeFan = async(id) =>{
 
    const response = await cs.put('removeFollower/'+id,'follow',null,{}, headers)
    getFanData()
    getFollowingFollowers()
  }
  return (
    <Modal size="md" show={fanModal} onHide={() => { setFanModal(false) }} className='fan-modal' aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title className='text-start'>Fans</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Scrollbars style={{ width: '100%', height: '320px' }} disableHorizontalScrolling>
          <div className='fan-wrapper'>
            {fan && fan?.length > 0 ?
              fan.map((item) => (
                <div className='fan-row'>
                  <div className='fan-img cursor-pointer' onClick={() => { navigate(`/profile/${item?._id}`, { state: { type: "user", id: item?._id } }); setFanModal(false)  }}><img src={item?.image ? item?.image : UserImg} alt='Avatar' /></div>
                  <div className='fan-name cursor-pointer' onClick={() => { navigate(`/profile/${item?._id}`, { state: { type: "user", id: item?._id } });  setFanModal(false)  }}>{item?.name ? item?.name : item?.userName}</div>
                  {artistId === userId && <div className='btn-wrap'><Button size='sm' variant='secondary' onClick={()=>{removeFan(item?._id)}}>Remove</Button></div>}
                </div>
              ))
              : <div style={{color :"white"}}>No Fans Yet!!!</div>}

          </div>
        </Scrollbars>

        {/* <div className='w-100 d-flex justify-content-center'><Button variant="primary" onClick={() => { setFanModal(false) }}>
            Ok
          </Button>
          </div> */}

      </Modal.Body>

    </Modal>
  )
}

export default FanModal
