/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2';
import UserImg from '../../assets/images/default.png'
import { Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import './Comment.scss'
import { ArrowDownIcon, CloseIcon, DisikeIcon, LikeIcon } from '../../Component/Icons';
import { SocketContext } from '../../Context/socket';
import * as cs from '../../Api/cs'
import useHeader from '../../Helper/useHeader';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { updateUserData } from '../../Redux/Slices/user.slice';
import Login from '../Login/Login';
import SignUp from '../SignUp/SignUp';
import OtpModal from '../../Modal/OtpModal';
import EmailLinkForPassword from '../EmailLinkForPassword/EmailLinkForPassword';
import SetUpAccount from '../../Modal/SetUpAccount/SetUpAccount';
import { useNavigate } from 'react-router-dom';

const Comment = ({ type, id, setModalTitle, setModalBody, handleCloutShow }) => {

  const [comment, setComment] = useState('')
  const { userId, userInfo } = useSelector(state => state.user)
  
  const navigate = useNavigate()
  
  const [battleData, setBattleData] = useState({})
  const [commentList, setCommentList] = useState()
  const socket = useContext(SocketContext)
  const headers = useHeader()
  const [commentType, setCommentType] = useState('add')
  const [commentId, setCommentId] = useState()
  const [showReply, setShowReply] = useState({});
  const [replyTo, setReplyTo] = useState({});
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isCollapsedMap, setIsCollapsedMap] = useState({});
  const [showLogin, setShowLogin] = useState(false);
  const [showOtpModal, setShowOtpModel] = useState(false)
  const [emailLinkModal, setEmailLinkModal] = useState(false)
  const [showSignUp, setShowSignUp] = useState(false)
  const [showSetUp, setShowSetUp] = useState(false)
  const [user, setUser] = useState(null)
  const [UserId, setUserId] = useState(null)
  const [token2, setToken2] = useState(null)
  const [like,setLike] = useState({})
  const [dislike,setDislike] = useState({})

  const handleLoginClose = () => {
    setShowLogin(false)
  }
  const handleOtpClose = () => {
    setShowOtpModel(false)
  }
  const handleSignUpClose = () => {
    setShowSignUp(false)
  }

  const handleSetUpClose = () => {
    setShowSetUp(false)
  }
  const handleEmailLinkClose = () => {
    setEmailLinkModal(false)
  }


  const commentInputRef = useRef(null);
  const scrollbarsRef = useRef(null);
  const dispatch = useDispatch()

  useEffect(() => {
    console.warn("comments")
    if (type === 'battle') {
      getBattleDetail()
      getCommentOfBattle()
    }
    else if (type === 'track') {
      getCommentOfTrack()
    }
    getUserData()
  }, [])

  useEffect(() => {
    if (type === 'battle') {
      getBattleDetail()
      getCommentOfBattle()
    }
    else if (type === 'track') {
      getCommentOfTrack()
    }
  }, [id])


  const getBattleDetail = async () => {
  
    const response = await cs.get('getById/' + id, 'battel', {}, headers)
    setBattleData(response?.data?.data?.[0])
  }

  const getCommentOfBattle = async () => {
    const response = await cs.get('list/' + id +'/'+userId, 'comment', {}, headers)
    setCommentList(response?.data?.data)
    updateLikesDislikes(response?.data?.data)
    if (!commentId) {
      scrollbarsRef?.current?.scrollToBottom();
    } else {
      setShowReply(prevState => ({
        ...prevState,
        [commentId]: true
      }));
      scrollToComment(commentId);
    }
  }


  const getCommentOfTrack = async () => {

    const response = await cs.get('listTrack/' + id +'/'+userId, 'comment', {}, headers)
    setCommentList(response?.data?.data)
    updateLikesDislikes(response?.data?.data)
    if (!commentId) {
      scrollbarsRef?.current?.scrollToBottom();
    } else {
      setShowReply(prevState => ({
        ...prevState,
        [commentId]: true
      }));
      scrollToComment(commentId);
    }

  }

  const updateLikesDislikes = (commentData) => {
    const likes = {};
    const dislikes = {};
  
    // Iterate over comments and update likes and dislikes state
    commentData?.forEach((comment) => {
      likes[comment?._id] = comment.likeStatus;
      dislikes[comment?._id] = comment.dislikeStatus;
  
      // Iterate over replies and update likes and dislikes state
      if (comment?.replies && comment?.replies?.length > 0) {
        comment?.replies.forEach((reply) => {
          likes[reply?._id] = reply.likeStatus;
          dislikes[reply?._id] = reply.dislikeStatus;
        });
      }
    });
  
    setLike(likes);
    setDislike(dislikes);
  };



  const handleComment = () => {

    if (userId === null) {
      setShowLogin(true)
      setComment('');
      commentInputRef.current.value = "";
    }

    else if (type === 'battle') {
      if (comment.trim() !== '') {
        const _data = {
          userId: userId,
          battleId: id,
          battleCreator: battleData?.battlecreatedBy,
          battleOpponent: battleData?.opponentcreatedBy,
          message: comment,
          type: commentType,
          commentId: commentId && commentId
        }
      

        setComment('');
        commentInputRef.current.value = "";

        socket.emit('create_comment', _data)
        handleCloseIcon()
        getCommentOfBattle(id)
        commentInputRef.current.focus();
        document && commentId && document?.getElementById(commentId)?.scrollIntoView();
      }

    }
    else if (type === 'track') {
      if (comment.trim() !== '') {
        const _data =
        {
          userId: userId,
          trackId: id,
          message: comment,
          type: commentType,
          commentId: commentId && commentId
        }
        setComment('');
        commentInputRef.current.value = "";

        socket.emit('track_comment', _data)
        handleCloseIcon()
        getCommentOfTrack(id)
        commentInputRef.current.focus();
        document && commentId && document?.getElementById(commentId)?.scrollIntoView();
      }
    }
  }


  const handleReply = (item) => {


    setReplyTo(item?.userId)
    setCommentType('replay')
    setCommentId(item?._id)
    // setShowReply(prevState => ({
    //   ...prevState,
    //   [item?._id]: true
    // }));
    commentInputRef.current.focus();

  }

  const scrollToComment = (id) => {
    const commentElement = document.getElementById(`comment_${id}`);
    if (commentElement) {
      commentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  

  const handleLikeDislikeButton = async (item,mode) => {
  
    if (userId == null) {
      setShowLogin(true)
    }
    else if (userId !== null) {
      const _data = {
        userId: userId,
        commentId: item,
        mode: mode
      }

      const response = await cs.post('addlikeDislike', 'likeDislike', _data, headers)
      setLike(prevState => ({
        ...prevState,
        [item]: response?.data?.data?.likeStatus
      }))
      setDislike(prevState => ({
        ...prevState,
        [item]: response?.data?.data?.dislikeStatus
      }))

      if (type === 'battle') {
        getBattleDetail()
        getCommentOfBattle()
      }
      else if (type === 'track') {
        getCommentOfTrack()
      }
    }

  }






  const handleCloseIcon = () => {
    setCommentId(null)
    setCommentType('add')
    commentInputRef.current.value = null
  }

  const toggleCollapse = (commentId) => {
    setIsCollapsedMap(prevState => ({
      ...prevState,
      [commentId]: !prevState[commentId]
    }));
  };

  function formatCommentTime(createdAt) {
    const now = moment();
    const createdDate = moment(createdAt);
    const diffInMinutes = now.diff(createdDate, 'minutes');
    const diffInHours = now.diff(createdDate, 'hours');
    const diffInDays = now.diff(createdDate, 'days');
    const diffInWeeks = now.diff(createdDate, 'weeks');

    if (diffInMinutes < 60) {
      return `${diffInMinutes} m`;
    } else if (diffInHours < 24) {
      return `${diffInHours} h`;
    } else if (diffInDays < 7) {
      return `${diffInDays} d`;
    } else {
      return `${diffInWeeks} w`;
    }
  }
  const getUserData = async () => {
    const response = await cs.get("getUserList/" + userId, 'users', {}, headers)
    dispatch(updateUserData(response?.data?.data))
  }


  return (
    <>
      <div className='comment-main-wrapper'>
        <div className='comment-title'>Comments</div>

        <div className='comment-list-wrapper'>
          <Scrollbars style={{ width: '100%', height: '400px' }} ref={scrollbarsRef}>
            <div className='comment-list-wrap'>


              {commentList && commentList.map((item) =>
              (
                <>
                  <div className='comment-list' id={item?._id}>
                    <div className='comment-list-img cursor-pointer' onClick={() => { navigate(`/profile/${item?.userId?._id}`)}}> <img src={item?.userId?.image ? item?.userId?.image : UserImg} alt='User' /></div>
                    <div className='comment-list-content'>
                      <div className='comment-user-name-wrap cursor-pointer'  onClick={() => { navigate(`/profile/${item?.userId?._id}`)}}>
                        {item?.userId?.name ? item?.userId?.name : item?.userId?.userName} <span>{formatCommentTime(item?.createdAt)}</span></div>

                      <div className='comment-wrap'>{item?.message}</div>
                      <div className='commnet-action-wrap'>
                        <div className='comment-action'>
                          <div className={`icon-wrap ${like[item?._id] ? 'active' : ''}`} onClick={() => { handleLikeDislikeButton(item?._id,"like") }} ><LikeIcon /><span> {item?.like}</span></div>
                          <div className={`icon-wrap ${dislike[item?._id] ? 'active' : ''}`} onClick={() => {  handleLikeDislikeButton(item?._id,"dislike") }}><DisikeIcon /><span>{item?.dislike}</span></div>
                        </div>
                        <div className='reply-wrap' onClick={() => { handleReply(item) }}>Reply</div>
                      </div>
                    </div>
                  </div>

                  {item?.replies?.length > 0 &&
                    <div className={`show-replies ${isCollapsedMap[item._id] ? 'active' : ''}`} onClick={() => { setShowReply(prevState => ({ ...prevState, [item._id]: !prevState[item._id] })), toggleCollapse(item._id) }}> <i><ArrowDownIcon /></i> Show reply</div>}
                  {showReply[item._id] && <div className='replies-wrapper'>
                    {showReply[item._id] && item?.replies && item?.replies?.map((cmmt) => (

                      <div className='comment-list' id={cmmt?._id}>
                        <div className='comment-list-img cursor-pointer'  onClick={() => { navigate(`/profile/${cmmt?.user?._id}`)}}> <img src={cmmt?.user?.image ? cmmt?.user?.image : UserImg} alt='User' /></div>
                        <div className='comment-list-content'>
                        <div className='comment-user-name-wrap cursor-pointer' onClick={() => { navigate(`/profile/${cmmt?.user?._id}`)}}>
                        {cmmt?.user?.name ? cmmt?.user?.name : cmmt?.user?.userName} <span>{formatCommentTime(cmmt?.createdAt)}</span></div>
                          <div className='comment-wrap'>{cmmt?.message}</div>
                          <div className='commnet-action-wrap'>
                            <div className='comment-action'>
                              <div className={`icon-wrap ${like[cmmt?._id] ? 'active' : ''}`} onClick={() => { handleLikeDislikeButton(cmmt?._id,"like") }}><LikeIcon />{cmmt?.like}</div>
                              <div className={`icon-wrap ${dislike[cmmt?._id] ? 'active' : ''}`} onClick={() => { handleLikeDislikeButton(cmmt?._id,"dislike") }}><DisikeIcon />{cmmt?.dislike}</div>
                            </div>
                          </div>
                        </div>
                      </div>

                    ))}
                  </div>
                  }
                </>

              ))}


            </div>
          </Scrollbars>
        </div>
        <div className='add-comment-wrapper'>
          {commentId &&
            <div className='w-100' style={{ color: "white" }}>Replying to {replyTo?.name? replyTo?.name : replyTo?.userName}

            </div>}
          <div className='add-comment-wrap-top'>
            <div className='add-comment-img-wrap'>
              <img src={userInfo?.image ? userInfo?.image : UserImg} alt='User' />
            </div>
            <div className='add-comment'>
              <Form.Control as="textarea" ref={commentInputRef} placeholder='Join the discussion' onChange={(e) => { setComment(e.target.value) }} />
            </div>
          </div>
          <div className='btn-wrap'>
            {commentId && <Button variant='secondary' onClick={handleCloseIcon}>Cancel</Button>}
            <Button variant='primary' onClick={handleComment}>Send</Button>
          </div>
        </div>
      </div >
      {showLogin && <Login showLogin={showLogin} handleLoginClose={handleLoginClose} setShowLogin={setShowLogin} setShowModel={setShowOtpModel} setEmailLinkModal={setEmailLinkModal} setShowSignUp={setShowSignUp} setUser={setUser} setShowSetUp={setShowSetUp} setId={setUserId} setToken2={setToken2} />}
      {showSignUp && <SignUp showSignUp={showSignUp} handleSignUpClose={handleSignUpClose} setShowSignUp={setShowSignUp} setShowLogin={setShowLogin} setShowModel={setShowOtpModel} setUser={setUser} user={user} setId={setUserId} setToken2={setToken2} />}
      {showOtpModal && <OtpModal showModal={showOtpModal} handleClose={handleOtpClose} setShowModel={setShowOtpModel} user={user} setShowLogin={setShowLogin} setShowSetUp={setShowSetUp} id={UserId} token2={token2} />}
      {emailLinkModal && <EmailLinkForPassword emailLinkModal={emailLinkModal} handleEmailLinkClose={handleEmailLinkClose} setShowLogin={setShowLogin} />}
      {showSetUp && <SetUpAccount showSetUp={showSetUp} handleSetUpClose={handleSetUpClose} />}
    </>
  )
}

export default Comment
