import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    walletShow : false,
    activityShow : false,
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
   updateWalletShow : (state, action) => {
  
    state.walletShow= action.payload;
   },
   updateActivityShow : (state, action) => {
    
    state.activityShow= action.payload;
   } 
  },
})

export const {updateWalletShow,updateActivityShow} = modalSlice.actions

export default modalSlice.reducer