import React, { useEffect, useState } from 'react'
import ActiveBattleBannerImg from '../../../assets/images/battle-banner.png';
import Button from 'react-bootstrap/Button';
import './ActiveBattleBanner.scss'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useHeader from '../../../Helper/useHeader';
import StartBattleModal from '../../../Modal/StartBattleModal/StartBattleModal';
import OpenBattleModal from '../../../Modal/OpenBattleModal/OpenBattleModal';
import * as cs from '../../../Api/cs'
import Login from '../../Login/Login';
import SignUp from '../../SignUp/SignUp';
import OtpModal from '../../../Modal/OtpModal';
import EmailLinkForPassword from '../../EmailLinkForPassword/EmailLinkForPassword';
import SetUpAccount from '../../../Modal/SetUpAccount/SetUpAccount';

const ActiveBattleBanner = () => {

    const { userId } = useSelector(state => state.user)

    const [showModal, setShowModal] = useState(false);
    const [showOpenBattleModal, setShowOpenBattleModal] = useState(false);
    const [trackInfo, setTrackInfo] = useState('');
    const [battleOption, setBattleOption] = useState('')
    const [battleList, setBattleList] = useState([])
    const [addToPlaylist, setAddToPlaylist] = useState(false);
    const [sort, setSort] = useState('dsc')
    const [filter, setFilter] = useState('abcd')
    const [showLogin, setShowLogin] = useState(false);
    const [showOtpModal, setShowOtpModel] = useState(false)
    const [emailLinkModal, setEmailLinkModal] = useState(false)
    const [showSignUp, setShowSignUp] = useState(false)
    const [showSetUp, setShowSetUp] = useState(false)
    const [user, setUser] = useState(null)
    const [UserId, setUserId] = useState(null)
    const [token2, setToken2] = useState(null)
    const headers = useHeader()
    // const socket = useContext(SocketContext)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleOpenBattleClose = () => {
        setShowOpenBattleModal(false);
    };

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const handleLoginClose = () => {
        setShowLogin(false)
    }
    const handleOtpClose = () => {
        setShowOtpModel(false)
    }
    const handleSignUpClose = () => {
        setShowSignUp(false)
    }
    const handleSetUpClose = () => {
        setShowSetUp(false)
    }
    const handleEmailLinkClose = () => {
        setEmailLinkModal(false)
    }

    const startBattle = () => {
        if (userId == null) {
            setShowLogin(true)
        }
        else {
            setShowModal(true)
        }
    }

    useEffect(() => {
        getBattleList()
    }, [])
    const getBattleList = async () => {
        const response = await cs.get('list/' + sort + "/" + filter, 'battel', {}, headers)
        setBattleList(response?.data?.data)

    }
    return (
        <>
            <div className='active-battle-banner-wrapper'>
                <img src={ActiveBattleBannerImg} alt='' />
                <div className='active-battle-banner'>
                    <div className='active-battle-banner-content'>
                        <div className='active-battle-banner-title'>Battles</div>
                        <div className='active-battle-banner-description'>Get in on the action by competing against music creators around the globe and showcasing your talent, or cast your vote and help crown a winner.</div>
                        <div className='active-battle-banner-btn-wrap'>
                            <Button variant='primary' type="button" onClick={startBattle}>Start Battle</Button>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && <StartBattleModal showModal={showModal} handleClose={handleClose} setShowModal={setShowModal} setShowOpenBattleModal={setShowOpenBattleModal} setTrackInfo={setTrackInfo} setBattleOption={setBattleOption} setAddToPlaylist={setAddToPlaylist} addToPlaylist={addToPlaylist} />}
            {showOpenBattleModal && <OpenBattleModal showOpenBattleModal={showOpenBattleModal} trackInfo={trackInfo} setShowModal={setShowModal} setShowOpenBattleModal={setShowOpenBattleModal} battleOption={battleOption} getBattleList={getBattleList} handleOpenBattleClose={handleOpenBattleClose} addToPlaylist={addToPlaylist} />}
            {showLogin && <Login showLogin={showLogin} handleLoginClose={handleLoginClose} setShowLogin={setShowLogin} setShowModel={setShowOtpModel} setEmailLinkModal={setEmailLinkModal} setShowSignUp={setShowSignUp} setUser={setUser} setShowSetUp={setShowSetUp} setId={setUserId} setToken2={setToken2} />}
            {showSignUp && <SignUp showSignUp={showSignUp} handleSignUpClose={handleSignUpClose} setShowSignUp={setShowSignUp} setShowLogin={setShowLogin} setShowModel={setShowOtpModel} setUser={setUser} user={user} setId={setUserId} setToken2={setToken2} />}
            {showOtpModal && <OtpModal showModal={showOtpModal} handleClose={handleOtpClose} setShowModel={setShowOtpModel} user={user} setShowLogin={setShowLogin} setShowSetUp={setShowSetUp} id={UserId} token2={token2} />}
            {emailLinkModal && <EmailLinkForPassword emailLinkModal={emailLinkModal} handleEmailLinkClose={handleEmailLinkClose} setShowLogin={setShowLogin} />}
            {showSetUp && <SetUpAccount showSetUp={showSetUp} handleSetUpClose={handleSetUpClose} />}
        </>
    )
}

export default ActiveBattleBanner