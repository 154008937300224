import React, { useEffect, useRef, useState } from 'react'
import { PreviousArrow } from '../Icons'
import Scrollbars from 'react-custom-scrollbars-2'
import './Activity.scss'
import * as cs from '../../Api/cs'
import { useDispatch, useSelector } from 'react-redux'
import useHeader from '../../Helper/useHeader'
import { useNavigate } from 'react-router-dom'
import { updateBattleId } from '../../Redux/Slices/battle.slice'
import { updateActivityShow,updateWalletShow } from '../../Redux/Slices/modal.slice'
import { updateBadgeId, updateIsBadgeInfo } from '../../Redux/Slices/badge.slice'


const moment = require('moment');

const Activity = ({ setActivityShow ,setBadgeShow, info}) => {
    console.log("setbadge",setBadgeShow,info)
    const [activity, setActivity] = useState([])
    const [isWindow, setIsWindow] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0); // Total number of records
    const [totalPages, setTotalPages] = useState(1);
    const { userId } = useSelector(state => state.user)


    const headers = useHeader()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const activityRef = useRef(null);


    useEffect(() => {

        fetchActivity(page);
    }, [])


    const fetchActivity = async (pageNumber) => {
        try {
            setLoading(true);
            const response = await cs.get(`getActivity/${userId}?page=${pageNumber}`, 'activity', {}, headers);
            if (response?.data?.code === 200) {
                const newData = response?.data?.data || [];
                setActivity(prevActivity => [...prevActivity, ...newData]);
                setPage(pageNumber);
                setHasMore(newData.length > 0);
                setTotalRecords(response?.data?.totalRecords);
                setTotalPages(response?.data?.totalPages);
               
            }
        } catch (error) {
            console.error("Error fetching activity:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.target;
        if (scrollHeight - scrollTop === clientHeight && hasMore && !loading && page < totalPages) {
            fetchActivity(page + 1); // Fetch next page
        }
    };

    useEffect(() => {
        if (isWindow) {
            document.body.classList.add('hidden');
        } else {
            document.body.classList.remove('hidden');
        }
    }, [isWindow]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1200) {
                setIsWindow(true);
            } else {
                setIsWindow(false);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


 
    const handleBattleCard = (item, activityType) => {
        if (activityType !== "Amount Refunded" && item?.isActive === true && item?.isDeleted === false) {
            dispatch(updateActivityShow(false));
            dispatch(updateBattleId(item?._id));
            navigate(`/battle/${item?._id}`, { state: { itemData: item?._id } });
            document.body.classList.remove('hidden');
        navigate(`/battle/${item?._id}`, { state: { itemData: item?._id } })
        }
    }

    const handleTrackPage = (item) => {
        dispatch(updateActivityShow(false));
        navigate(`/track/${item?._id}`, { state: { trackId: item?._id } });
        document.body.classList.remove('hidden');
    };

    const handlePreviousArrow = () => {
        dispatch(updateActivityShow(false));
        dispatch(updateWalletShow(true));
        document.body.classList.remove('hidden');
    }

    const handleClickOutside = () => {
        dispatch(updateActivityShow(false));
        document.body.classList.remove('hidden');
    };
    const handleBadgeUnlock = (badgeId) => {
        dispatch(updateIsBadgeInfo(true));
        dispatch(updateBadgeId(badgeId._id));
        document.body.classList.remove('hidden');
    };

    return (
        <>
            <div className='activity-overlay' onClick={handleClickOutside}>
                <div className='activity-wrapper' ref={activityRef} onClick={(e) => e.stopPropagation()}>
                    <div className='activity-header'>
                        <div className='back-btn' onClick={()=>{handlePreviousArrow()}}><PreviousArrow /></div>
                        <div className='activity-title'>Activity</div>
                    </div>
                    <div className='activity-content-wrapper'>
                        <Scrollbars className='activity-scroll-wrapper' style={{ width: '100%', height: '100%' }} onScroll={handleScroll}>
                            {activity && activity?.length > 0 ?
                                <div className='activity-list-wrapper cursor-pointer'>
                                    {activity.map((item) => (
                                        <div key={item?._id} className='activity box' onClick={() => {
                                            if (item?.activityDetails?.battleId) {
                                                handleBattleCard(item?.activityDetails?.battleId, item?.activityType);
                                            } else if (item?.activityDetails?.BadgeId && item?.activityType === "Badge Unlocked" || item?.activityDetails?.BadgeId && item?.activityType === "Level Unlocked") {
                                                handleBadgeUnlock(item?.activityDetails?.BadgeId);
                                            } else {
                                                handleTrackPage(item?.activityDetails?.trackId);
                                            }
                                        }}>
                                            <div className='activity-timing'>{moment(item?.updatedAt).format("MMMM DD, YY")}</div>
                                            <div className='activity-info-wrap'>
                                                <div className='activity-info'>{item?.activityType}</div>
                                            </div>
                                            <div className={`clout-activity ${item?.cloutChange >= 0 ? 'positive' : 'negative'}`}>{item?.cloutChange} Clout</div>
                                        </div>
                                    ))}
                                </div>
                                :
                                <div style={{ color: "white" }}></div>
                            }
                        </Scrollbars>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Activity;
