import React from 'react';

const PrivacyPolicy = () => {
    return <div className='legal-info-content'>
        <h5 className='legal-page-title'>PRIVACY POLICY</h5>
        <div className="legal-page-text">
            <div className='legal-info-title'>1. Introduction</div>
            <div className='privacy-policy-content'>
                <p>CNTNDR, Inc. (referred to hereinafter as "CNTNDR," "us," "we," or "our") is committed to protecting your privacy online. Throughout this Privacy Policy, the terms “you” or “your” shall also apply to you whether you are acting in your individual capacity or as the authorized representative for an artist, band, group, or corporation, in which case “you” or “your” shall refer to the artist, band, group, or corporation on whose behalf you are acting and authorized to act.</p>
                <p>We are also committed to providing you with the very best experience we can on our CNTNDR website, mobile and desktop apps, and any related players, widgets, tools, applications, data, software, and APIs (referred to as the “Platform”).</p>
                <p>This Privacy Policy explains the types of Personal Information we collect from website visitors and other people with whom we communicate and interact in the course of providing the Platform. This Privacy Policy also describes how we use your Personal Information, the purpose for sharing it, and the recipients of any information that relates to an identified or identifiable natural person (“Personal Information”) along with your rights and choices associated with that data.</p>
                <p>By using CNTNDR, you are consenting to the practices described in this Privacy Policy.</p>
            </div>
            <div className='legal-info-title'>2. Information We Collect </div>
            <div className='privacy-policy-content'>
                <strong>A. Information You Provide</strong>
                <p>When you register an account with CNTNDR, we may collect various types of information directly from you, such as : </p>
                <ul>
                    <li><strong>Registration Information:</strong> This includes your artist name, password (encrypted), email address, and social network ID if you choose to sign up using a social network account (e.g. Facebook or Google). Once your account has been registered, your name, username, and profile picture (if you have signed up using your social network ID) will automatically be displayed on your profile page and will be available to other users through the Platform. This allows other users to identify you, find you, and refer to you properly.</li>
                    <li><strong>Profile Information:</strong> You have the option to provide additional details on your profile, such as your profile picture, phone number, date of birth, gender, location, and interests.</li>
                    <li><strong>Content Uploads:</strong> Any content you upload to the Platform, such as photos, graphic art, audio recordings, video recordings, or text, may be collected.</li>
                    <li><strong>Communications:</strong> We collect information from any communications you have with us, including service updates, emails, and support tickets.</li>
                </ul>
                <strong>B. Information Provided by Others</strong>
                <p>Content uploaded by other users, such as photos, audio recordings, video recordings, or text that allows you to be identified, may also be collected.</p>
                <strong>C. Other Information</strong>
                <p>In addition to the information you provide directly, we may also collect the following : </p>
                <ul>
                    <li><strong>Analytics:</strong> We use third-party analytics tools to measure traffic and usage trends for the Platform.</li>
                    <li><strong>Cookies and Similar Technologies:</strong> Our Platform may use cookies, clear gifs, and other technologies to customize your experience and track usage.</li>
                    <li><strong>Log File Information:</strong> Information automatically reported by your browser, including your web request, IP address, browser type, and interactions with the Platform.</li>
                    <li><strong>Device Identifiers:</strong> Unique identifiers associated with your mobile device, used for analytics and personalized content. A device identifier may be data stored in connection with the device hardware, data stored in connection with the device’s operating system or other software, or data sent to the device by us. A device identifier may deliver information to us or to a third party partner about how you browse and use the Platform and may help us or others provide reports or personalized content and ads. Some features of the Platform may not function properly if use or availability of device identifiers is impaired or disabled.</li>
                </ul>


            </div>
            <div className='legal-info-title'>3. How We Use Your Information</div>
            <div className='privacy-policy-content'>
                <p>We use the information we collect for various purposes, including but not limited to : </p>
                <ul>
                    <li>Providing and improving the Platform</li>
                    <li>Communicating with you about your account, updates, and feedback</li>
                    <li>Personalizing content, advertisements, and offers</li>
                    <li>Test, improve, and monitor the Platform, including diagnosing and fixing technology problems</li>
                    <li>Allow other users to communicate with you</li>
                    <li>Monitoring analytics like site traffic, usage patterns, and demographics</li>
                    <li>Make music programming decisions and to comply with copyright reporting obligations</li>
                    <li>Process payments made by or to you; an</li>
                    <li>Comply with applicable rules, laws, regulations, codes of practice, or guidelines issued by any legal or regulatory bodies which are binding on us</li>
                </ul>

            </div>
            <div className='legal-info-title'>4. Sharing Your Information</div>
            <div className='privacy-policy-content'>
                <p>We may share your information with the following parties : </p>
                <ul>
                    <li><strong>Service Providers:</strong> Third-party companies who assist us in providing and managing aspects of the Platform.</li>
                    <li><strong>Third-Party Advertisers:</strong> Advertisers who may notify you of events or products of interest.</li>
                    <li><strong>Brands and Affiliates:</strong> Third-party brand and affiliate partners who offer their products on Platform.</li>
                    <li><strong>Analytics Services:</strong> Third-party providers who help us analyze information, including but not limited to, information from cookies, log files, device identifiers, location data, and usage data.</li>
                    <li><strong>Other Parties:</strong> We may share anonymized data with other parties, and may also combine your information with other information in a way that it is no longer associated with you and share that aggregated information.</li>
                    <li><strong>Other Users:</strong> Your information may be shared with other users as described in this Policy.</li>
                    <li><strong>Broadcast Audience:</strong> If your information is included in any of your content and this is then broadcasted to the public by us, such information will be shared with anyone who listens or views to the content throughout the world.</li>
                </ul>
                <p>We require that all parties with whom we share information to implement adequate levels of protection in order to protect such information.</p>
                <p>If you delete information or content which you have uploaded or posted on the Platform, copies may remain viewable in cached and archived pages of the Platform, unless you contact us to request permanent deletion as per Section 8 below. Unless your account is permanently deleted, any content or information associated with live or past battles will remain available for public viewing, even if the content or information has been deleted from your profile.</p>
                <p>We may also share your information with third parties in the following circumstances : </p>
                <p><strong>(a) Change of control : </strong> If the ownership of our business changes or we undertake a reorganization of our business, we may transfer your information and content to the new owners so they can continue to operate the Platform. The new owners may subsequently make changes to this Privacy Policy</p>
                <p><strong>(b) If permitted or required by law : </strong> We may disclose information if we believe that such disclosure is required or permitted by law (e.g. by responding to requests from government authorities, or disclosure pursuant to court orders or other legal demands); and</p>
                <p><strong>(c) To protect interests : </strong> We may disclose information if we determine it is necessary to enforce or protect our rights or the property or personal safety of the users of the Platform (e.g. to enforce this Privacy Policy or our Terms of Use, or to prevent or take action against illegal activities, suspected fraud, or potential threats or breaches).</p>
            </div>
            <div className='legal-info-title'>5. Children’s Privacy</div>
            <div className='privacy-policy-content'>
                <p>CNTNDR respects the privacy of children. The Platform is not intended or directed towards children or minors under the age of thirteen (13). Please do not use the Platform if you are under the age of thirteen (13). CNTNDR is intent on complying with applicable laws and regulations regarding minors, including the United States’ Children’s Online Privacy Protection Act (COPPA). </p>
                <p>Specifically, CNTNDR does not knowingly solicit or collect Personal Information from individuals under thirteen (13). If we learn that any Personal Information has been collected inadvertently from a child, we will delete the information as soon as possible. If you believe that we might have collected information from a child, please send an email to <a href='#'>privacy@cntndr.io</a> to have it removed.</p>
            </div>
            <div className='legal-info-title'>6. Data Protection</div>
            <div className='privacy-policy-content'>
                <p>This section applies to all users of any services owned or controlled by CNTNDR. Additionally, this section extends to service providers, contractors, affiliates, vendors, agents, and entities, along with their parent company and subsidiaries, or their respective employees, officers, directors, members, managers, shareholders, agents, vendors, licensors, licensees, contractors, customers, successors, and assigns (each referred to as “Parties” in the following paragraph). By entering into any agreement with CNTNDR that involves the use of Personal Information, Parties agree to adhere to the following terms. Violation of this section may result in termination of service or rights granted by CNTNDR. Moreover, if an agreement has been executed between CNTNDR and a Party, violation of this section is considered a material breach.</p>
                <p>Each party shall, at its own expense, ensure compliance with all legislation and regulatory requirements relating to the use of personal data, including but not limited to : </p>
                <p>(i) any data protection legislation in force in the UK including the Data Protection Act 1998 or 2018 and any successor legislation</p>
                <p>(ii) for as long as and to the extent that the law of the EU has legal effect in the UK, the General Data Protection Regulation ((EU) 2016/679) (“GDPR”) and any other directly applicable EU regulation relating to privacy</p>
                <p>(iii) any applicable U.S. Data Protection Laws, including but not limited to the California Consumer Privacy Act (CCPA).</p>
                <p>This clause is in addition to, and does not reduce, remove, or replace, a party's obligations arising from such requirements. Parties confirm that they will not rent or sell customer lists, contact details, or other data without the customers’ express prior approval.</p>
                <p>Furthermore, under Article 5 of GDPR, both parties will comply with the following principles to ensure any personal data will be : </p>
                <p>a) Processed for limited purposes and not in any way incompatible with those purposes</p>
                <p>b) Adequate, relevant, and not excessive</p>
                <p>c) Accurate and not kept for longer than necessary</p>
                <p>d) Processed in accordance with the individual rights of any data subject</p>
                <p>e) Secure and not transferred to countries or other parties without adequate data protection.</p>
                <p>CALIFORNIA RESIDENTS: YOUR CALIFORNIA PRIVACY RIGHTS</p>
                <p>Under the California Consumer Privacy Act of 2018 as amended by the California Privacy Rights Act of 2020 (“CCPA”), California residents have certain rights regarding CNTNDR's collection, use, and sharing of their Personal Information. CNTNDR does not sell your Personal Information and will not do so in the future without providing you with notice and an opportunity to opt-out of such sale as required by law. Similarly, we do not offer financial incentives associated with our collection, use, or disclosure of your Personal Information.</p>
                <p>As of January 1, 2023, you have the right to opt-out of sharing your Personal Information with third parties for the purposes of targeted behavioral advertising, which you can do in our cookie preferences center. CNTNDR collects various categories of Personal Information when you use the Service, including identifiers, commercial information, internet or other electronic network or device activity information, geolocation data, and professional information. A more detailed description of the information CNTNDR collects and how we use it is provided above in the sections entitled “Collection of Personal Information” and “Use of Personal Information”. The section entitled “Sharing Personal Information” categories of third parties with whom we share Personal Information, and what information may be shared under different circumstances.</p>
                <p>If you are a resident of California, you have the right to request to know what Personal Information has been collected about you, and to access that information. You also have the right to request deletion of your Personal Information, though exceptions under the CCPA may allow CNTNDR to retain and use certain Personal Information notwithstanding your deletion request. As of January 1, 2023, you have the right to request correction of inaccurate Personal Information and to request to limit disclosure or use of your sensitive Personal Information.</p>
                <p>Please note, however, that any right to request correction is limited by the legal rights of CNTNDR and its users relating to content on the CNTNDR platform, including without limitation legally protected rights related to freedom of speech, freedom of the press, or freedom of information. you may send your request by email to <a href='#'>privacy@cntndr.io</a> . Information on the number of requests CNTNDR has received in California is available upon request to <a href='#'>privacy@cntndr.io</a> . We will not discriminate against you for exercising your rights under the CCPA. An agent may submit a request on your behalf, but you must verify that your agent is authorized to do so. CNTNDR does not knowingly sell or share the Personal Information of consumers under 16 years of age.</p>
                <p>Separate from the CCPA, California’s Shine the Light law gives California residents the right to ask companies what Personal Information they share with third parties for those third parties’ direct marketing purposes. We do not disclose your Personal Information to third parties for the purpose of directly marketing their goods or services to you unless you agree to such disclosure. CNTNDR does not sell and has not, within at least the last 12 months, sold Personal Information, including Sensitive Personal Information that is subject to the CCPA’s sale limitation. If you have any questions regarding this policy, or would like to change your preferences, you may contact us at <a href='#'>privacy@cntndr.io</a> .</p>
            </div>
            <div className='legal-info-title'>7. Other Websites and Services</div>
            <div className='privacy-policy-content'>
                <p>This Privacy Policy does not apply to third-party websites or services that you may access through links on our platform. We encourage you to review the privacy policies of those websites and services before providing them with your information</p>
            </div>
            <div className='legal-info-title'>8. Contacting Us About Your Information</div>
            <div className='privacy-policy-content'>
                <p>If you have any questions, concerns, or requests regarding your information or this Privacy Policy, please contact our Data Protection Officer at <a href='#'>privacy@cntndr.io </a>.</p>
            </div>
            <div className='legal-info-title'>9. Changes to This Privacy Policy</div>
            <div className='privacy-policy-content'>
                <p>We reserve the right to change or update portions of this Privacy Policy at any time. We will notify you of any changes via email and recommend reviewing the Privacy Policy periodically. If you do not agree with the modified Privacy Policy, you should terminate your account.</p>
            </div>
        </div>
    </div>
};

export default PrivacyPolicy;