
import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import { NextArrow, PreviousArrow, ViewAllIcon } from '../../../Component/Icons';
import './BattleSlider.scss'
import BattleCard from '../../../Component/BattelCard/BattleCard';
import { Button } from 'react-bootstrap';
import useHeader from '../../../Helper/useHeader';
import * as cs from '../../../Api/cs'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateBattleId } from '../../../Redux/Slices/battle.slice';

const ActiveBattleSlider = () => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    const headers = useHeader()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [trendingBattle, setTrendingBattle] = useState([])
    const [trending, setTrending] = useState(false)

    useEffect(() => {
        getTrendingBattle()
    }, [])


    const getTrendingBattle = async () => {
        const response = await cs.get('trendingBattles', 'battel', null, headers)
        setTrendingBattle(response?.data?.data)
        setTrending(true)
    }

    const handleBattleCard = (item) => {
        navigate(`/battle/${item}`, { state: { itemData: item, battles: trendingBattle } })
        dispatch(updateBattleId(item))
    }

    return (

        <>
            <div className='battle-slider-wrapper'>
                <div className='main-wrapper'>
                    <div className='main-container'>
                        <div className='battle-slider-btn-wrap'>
                            <button className='btn' ref={prevRef}><PreviousArrow /></button>
                            <button className='btn' ref={nextRef}><NextArrow /></button>
                        </div>
                    </div>
                    <div className='battle-slider'>
                        <Swiper
                            spaceBetween={20}
                            modules={[Navigation]}
                            navigation={{
                                prevEl: prevRef.current,
                                nextEl: nextRef.current,
                            }}

                            breakpoints={{
                                1500: {
                                    slidesPerView: 3.9
                                },
                                1250: {
                                    slidesPerView: 2.9
                                },
                                768: {
                                    slidesPerView: 1.9,

                                },
                                300: {
                                    enabled: false
                                }
                            }}

                        >

                            {trendingBattle && trendingBattle?.length > 0 && trendingBattle.map((item) => (
                                <SwiperSlide onClick={() => { handleBattleCard(item?.battleData?._id) }}><BattleCard  battleId={item?.battleData?._id} trending={trending} type={item?.battleData?.battleOpponentStatus ? null : 'awaiting'}/></SwiperSlide>
                            ))}




                        </Swiper>

                        <div className='viewall-btn-wrap'
                         onClick={() => {
                            navigate("/battle/all/Recommended", {
                              state: {
                                type: "trendingBattle",
                                title: "Trending Battles",
                              },
                            });
                          }}
                        ><Button variant='secondary'><ViewAllIcon /> View all</Button></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ActiveBattleSlider