import React, { useEffect, useState } from 'react'
import './GenreLeaderboardCard.scss'
import Genre1 from '../../assets/images/genre-1.png'
import Genre2 from '../../assets/images/genre-2.png'
import Genre3 from '../../assets/images/genre-3.png'
import Genre from '../../assets/images/genre.png'
import * as cs from '../../Api/cs'
import useHeader from '../../Helper/useHeader'
import UserImg from '../../assets/images/default.png'
import { useNavigate } from 'react-router-dom'

const GenreLeaderboardCard = ({ active, genre }) => {

    const [leaderboard, setLeaderBoard] = useState([])
    const [remainingBoard, setRemainingBoard] = useState([])

    const headers = useHeader()
    const navigate = useNavigate()

    useEffect(() => {
        getLeaderBoard()
    }, [active, genre])

    const getLeaderBoard = async () => {
        const response = await cs.get('topWinner/' + genre + '/' + active, 'leaderboard', {}, headers)
        setLeaderBoard(response?.data?.data.slice(0, 3))
        setRemainingBoard(response?.data?.data.slice(3,6))
        // setLeader(response?.data?.data?.[0])
    }
    return (
        <>
            <div className='genre-leaderboard-card'>

                <div className='top-genre-wrap'>
                    <div className='top-genre'>
                        <div className='top-genre-img-wrap'>
                            <img src={leaderboard?.[1]?.userId?.image ? leaderboard?.[1]?.userId?.image : UserImg} alt='Genre' />
                        </div>
                        <div className='position-counter'>2</div>
                        <div className='top-genre-name cursor-pointer' onClick={() => { navigate(`/profile/${leaderboard?.[1]?.userId?._id}`, { state: { type: "user", id: leaderboard?.[1]?.userId?._id } }) }}>
                            {leaderboard?.[1]?.userId?.name ? leaderboard?.[1]?.userId?.name : leaderboard?.[1]?.userName}</div>
                        {active === 'weekly' && <div className='points'>{leaderboard?.[1]?.weeklyWinCount}</div>}
                        {active === 'monthly' && <div className='points'>{leaderboard?.[1]?.monthlyWinCount}</div>}
                        {active === 'allTime' && <div className='points'>{leaderboard?.[1]?.winCount}</div>}
                    </div>
                    <div className='top-genre first'>
                        <div className='top-genre-img-wrap'>
                            <img src={leaderboard?.[0]?.userId?.image ? leaderboard?.[0]?.userId?.image : UserImg} alt='Genre' />
                        </div>
                        <div className='position-counter'>1</div>
                        <div className='top-genre-name cursor-pointer' onClick={() => { navigate(`/profile/${leaderboard?.[0]?.userId?._id}`, { state: { type: "user", id: leaderboard?.[0]?.userId?._id } }) }}>{leaderboard?.[0]?.userId?.name ? leaderboard?.[0]?.userId?.name : leaderboard?.[0]?.userId?.userName}</div>
                        {active === 'weekly' && <div className='points'>{leaderboard?.[0]?.weeklyWinCount}</div>}
                        {active === 'monthly' && <div className='points'>{leaderboard?.[0]?.monthlyWinCount}</div>}
                        {active === 'allTime' && <div className='points'>{leaderboard?.[0]?.winCount}</div>}
                    </div>
                    <div className='top-genre'>
                        <div className='top-genre-img-wrap'>
                            <img src={leaderboard?.[2]?.userId?.image ? leaderboard?.[2]?.userId?.image : UserImg} alt='Genre' />
                        </div>
                        <div className='position-counter'>3</div>
                        <div className='top-genre-name cursor-pointer' onClick={() => { navigate(`/profile/${leaderboard?.[2]?.userId?._id}`, { state: { type: "user", id: leaderboard?.[2]?.userId?._id } }) }}>{leaderboard?.[2]?.userId?.name ? leaderboard?.[2]?.userId?.name : leaderboard?.[2]?.userId?.userName}</div>
                        {active === 'weekly' && <div className='points'>{leaderboard?.[2]?.weeklyWinCount}</div>}
                        {active === 'monthly' && <div className='points'>{leaderboard?.[2]?.monthlyWinCount}</div>}
                        {active === 'allTime' && <div className='points'>{leaderboard?.[2]?.winCount}</div>}
                    </div>
                </div>
                <div className='top-genre-list-wrap'>
                    {remainingBoard && remainingBoard?.length > 0 ?
                        remainingBoard.map((item, index) => (
                            <div className='top-genre-list-row'>
                                <div className='top-genre-count'>{index + 4}</div>
                                <div className='top-genre-img'><img src={item?.userId?.image ? item?.userId?.image : UserImg} alt='Genre' /></div>
                                <div className='top-genre-name'>{item?.userId?.name ? item?.userId.name : item?.userId?.userName}</div>
                                {active === 'weekly' && <div className='gl-win-counts'>{item?.weeklyWinCount} wins</div>}
                                {active === 'monthly' && <div className='gl-win-counts'>{item?.monthlyWinCount} wins</div>}
                                {active === 'allTime' && <div className='gl-win-counts'>{item?.winCount} wins</div>}
                            </div>
                        ))
                        : <div>No Records</div>}

                </div>
            </div>
        </>
    )
}

export default GenreLeaderboardCard