import React from 'react'
import Header from '../../Component/Header/Header'
import Sidebar from '../../Component/Sidebar/Sidebar'
import ActiveBattlePage from './ActiveBattlePage/ActiveBattlePage'
import ActiveBattleBannerImg from '../../assets/images/active-battle-banner.png'

const BattlePage = () => {
    return (
        <>
            <Header />
            <Sidebar />
            <div className='active-battle-banner-wrapper'>
                <img src={ActiveBattleBannerImg} alt='Banner' />
            </div>
            <ActiveBattlePage />
        </>
    )
}

export default BattlePage
