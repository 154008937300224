import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm, Controller } from 'react-hook-form';
import { forgetPasswordSchema, loginSchema } from '../../ValidationSchema/validationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import * as cs from '../../Api/cs'
import { toast, ToastContainer } from 'react-toastify';
import { EyeIcon, EyeIconOff, FacebookIcon, GoogleIcon } from '../../Component/Icons';
import { auth, provider } from '../../Helper/config';
import { signInWithPopup } from 'firebase/auth'
import { useDispatch } from 'react-redux';
import { updateLoginStatus, updateUserId } from '../../Redux/Slices/user.slice';
import ActiveBattleBannerImg from '../../assets/images/active-battle-banner.png'
import './Forgotpassword.scss'
import Header from '../../Component/Header/Header';
import Sidebar from '../../Component/Sidebar/Sidebar';


const ForgotPassword = () => {
    const navigate = useNavigate();

    const [passwordFieldType, setPasswordFieldType] = useState('password');
    const [repeatPasswordFieldType, setRepeatPasswordFieldType] = useState('password');

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        setError
    } = useForm({
        resolver: yupResolver(forgetPasswordSchema),
        mode: 'onChange',
    });;


    const togglePasswordVisibility = (fieldType, setFieldType) => {
        setFieldType((prevType) => (prevType === 'password' ? 'text' : 'password'));
    };

    const onSubmit = async (data) => {

        const _data = {
            email: data?.email,
            password: data?.password,
        }
        const response = await cs.post('forgotPassword', 'users', _data, null)
        if (response?.data?.code === 200) {

            toast.success("Password Reset Successfully!!!")
            navigate('/')
        }

    }


    return (
        <>
        <Header/>
        <Sidebar/>
        <div className='active-battle-banner-wrapper'>
                <img src={ActiveBattleBannerImg} alt='Banner' />
            </div>
        <div className='forgot-password-wrap'>
            <div className='main-wrapper'>
                <div className='main-container'>
                <div className='auth-form-wrapper-main'>
            <form className="auth-form-wrapper" onSubmit={handleSubmit(onSubmit)} >
                <div className='auth-form-header'>
                    <div className='auth-form-title'>Forgot Password </div>
                </div>
                <div className='auth-form'>
                    <div className="form-row">
                        <Form.Control type="text" name="email" placeholder='E-mail or Username'  {...register("email")}
                            {...register('email')}
                            onChange={(e) => {
                                setValue('email', e.target.value, { shouldValidate: true });
                            }}
                        />
                        {errors?.email && <div className="errorMsg">{errors?.email?.message}</div>}
                    </div>
                    <div className="form-row have-icon">
                        <Form.Control placeholder='Password' type={passwordFieldType} name="password" {...register("password")}

                            onChange={(e) => {
                                setValue('password', e?.target?.value, { shouldValidate: true });
                                if (errors?.repeatPassword) {
                                    setError('repeatPassword', { message: '' });
                                }
                            }}
                        />
                        <div className='icon-wrap'
                            type="button"
                            onClick={() => togglePasswordVisibility(passwordFieldType, setPasswordFieldType)}
                        >
                            {passwordFieldType === 'password' ? <EyeIcon /> : <EyeIconOff />}
                        </div>
                        {errors?.password && <div className="errorMsg">{errors?.password?.message}</div>}
                    </div>
                    <div className="form-row have-icon">
                        <Form.Control placeholder='Repeat password' type={repeatPasswordFieldType} name="repeatPassword"  {...register("repeatPassword")}
                            {...register('repeatPassword')}
                            onChange={(e) => {
                                setValue('repeatPassword', e?.target?.value, { shouldValidate: true, valueAsNumber: false });
                                if (errors?.password) {
                                    setError('password', { message: '' });
                                }
                            }}
                        />
                        <div className='icon-wrap'
                            type="button"
                            onClick={() => togglePasswordVisibility(repeatPasswordFieldType, setRepeatPasswordFieldType)}
                        >
                            {repeatPasswordFieldType === 'password' ? <EyeIcon /> : <EyeIconOff />}
                        </div>
                        {errors?.repeatPassword && <p className="errorMsg">{errors?.repeatPassword?.message}</p>}
                    </div>
                    <div className="form-action-row">
                        <div className='back' onClick={() => navigate('/')}>Back to Login</div>
                        <div className='flex-shrink-0 btn-wrap'> <Button variant='primary' type="submit">Submit</Button></div>
                    </div>

                </div>
            </form>
        </div>
                </div>
            </div>
       
        </div>
        </>
    )
}

export default ForgotPassword
