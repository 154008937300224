import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showChatMessage: false,
  userReceiverId : null,
  unreadMessage : false,
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
   updateShowChat: (state, action) => {

    state.showChatMessage = action.payload;
   },
   updateChatUserId : (state, action) => {
    
    state.userReceiverId= action.payload;
   },
   updateUnreadMessage : (state,action) => {
   
    state.unreadMessage = action.payload
   }
  },
})

export const { updateShowChat , updateChatUserId,updateUnreadMessage} = chatSlice.actions

export default chatSlice.reducer