import React from 'react'
import ArtistImg from '../../assets/images/artist.png';
import './Artist.scss'
import UserImg from '../../assets/images/default.png'
const Artist = ({artist}) => {
    return (
        <>
            <div className='artist-info-wrap cursor-pointer'>
                <div className='artist-img-wrap'><img src={artist?.image ? artist?.image : UserImg} alt='Artist' /></div>
                <div className='artist-name-wrap'>{artist?.name ? artist?.name : artist?.userName}</div>
            </div>
        </>
    )
}

export default Artist