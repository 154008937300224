import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import './BottomBar.scss'
import { AddIcon, BattleIcon, ChatIcon, LibraryIcon, UserIcon } from '../Icons'
import AvatarImg from '../../assets/images/artist.png'
import UserImg from '../../assets/images/default.png';
import StartBattleModal from '../../Modal/StartBattleModal/StartBattleModal'
import OpenBattleModal from '../../Modal/OpenBattleModal/OpenBattleModal'
import useHeader from '../../Helper/useHeader'
import * as cs from '../../Api/cs'
import { useSelector, useDispatch } from 'react-redux'
import Chat from '../Chat/Chat'
import Dropdown from 'react-bootstrap/Dropdown';
import CustomTextModal from '../../Modal/CustomTextModal';
import Activity from '../Activity/Activity';
import Login from '../../Pages/Login/Login';
import SignUp from '../../Pages/SignUp/SignUp';
import OtpModal from '../../Modal/OtpModal';
import EmailLinkForPassword from '../../Pages/EmailLinkForPassword/EmailLinkForPassword';
import SetUpAccount from '../../Modal/SetUpAccount/SetUpAccount';
import { updateActivityShow } from '../../Redux/Slices/modal.slice';
import { updateShowChat, updateUnreadMessage } from '../../Redux/Slices/chat.slice';
import { SocketContext } from '../../Context/socket';
const BottomBar = () => {
  const [showModal, setShowModal] = useState(false);
  const [showOpenBattleModal, setShowOpenBattleModal] = useState(false);
  const [trackInfo, setTrackInfo] = useState('');
  const [battleOption, setBattleOption] = useState('')
  const [battleList, setBattleList] = useState([])
  const [addToPlaylist, setAddToPlaylist] = useState(false);
  const [sort, setSort] = useState('dsc')
  const [filter, setFilter] = useState('abcd')
  const [showChat, setShowChat] = useState(false)
  const [userData, setUserData] = useState({})
  const [userDetails, setUserDetails] = useState()
  const [customModal, setCustomModal] = useState(false)
  const [modalTitle, setModalTitle] = useState()
  const [modalBody, setModalBody] = useState()
  // const [activityShow, setActivityShow] = useState(false)
  const [showLogin, setShowLogin] = useState(false);
  const [showOtpModal, setShowOtpModel] = useState(false)
  const [emailLinkModal, setEmailLinkModal] = useState(false)
  const [showSignUp, setShowSignUp] = useState(false)
  const [showSetUp, setShowSetUp] = useState(false)
  const [user, setUser] = useState(null)
  const [UserId, setUserId] = useState(null)
  const [token2, setToken2] = useState(null)
  const [activeBottomBar, setActiveBottomBar] = useState(false)
  const [dropDown, setDropDown] = useState(false)

  const { userInfo } = useSelector(state => state.user)

  const handleLoginClose = () => {
    setShowLogin(false)
  }
  const handleOtpClose = () => {
    setShowOtpModel(false)
  }
  const handleSignUpClose = () => {
    setShowSignUp(false)
  }

  const handleSetUpClose = () => {
    setShowSetUp(false)
  }
  const handleEmailLinkClose = () => {
    setEmailLinkModal(false)
  }



  const dispatch = useDispatch();

  const headers = useHeader()
  const socket = useContext(SocketContext)

  const navigate = useNavigate();
  const { isLoggedIn, userId } = useSelector(state => state.user);
  const { activityShow } = useSelector(state => state.modal)
  const { unreadMessage } = useSelector(state => state.chat)

  const handleClose = () => setShowModal(false);
  const handleOpenBattleClose = () => {
    setShowOpenBattleModal(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("token")
    dispatch({ type: 'LOGOUT' });;
    navigate('/')
  }

  useEffect(() => {
    getUsersList()
    getBattleList()
    fetchRooms()
  }, [])

  useEffect(() => {
    fetchRooms()
  }, [userId])

  useEffect(() => {
    fetchRooms()
  }, [socket])

  const getBattleList = async () => {
    const response = await cs.get('list/' + sort + "/" + filter, 'battel', {}, headers)
    setBattleList(response?.data?.data)
  }


  const fetchRooms = async () => {
    try {
      const response = await cs.get(`roomList/${userId}`, 'chatMessage', {}, headers);
      if (response?.data?.code == 200) {

        let readAll = 0
        for (const item of response?.data?.data || []) {
          if (item?.unreadMessageCount > 0 && userId !== item?.lastMessageBy) {
            dispatch(updateUnreadMessage(true));
            readAll = 1
            break;

          }
        }
        if (readAll == 0) {

          dispatch(updateUnreadMessage(false));
        }
      }
    }
    catch (error) {

    }
  }

  const getUsersList = async () => {

    try {
      const response = await cs.get("getUserList/" + userId, 'users', {}, headers)
      if (response?.data?.code === 200) {
        setUserData(response?.data?.data)
      }
    }
    catch (error) {

    }

  }

  const handleAddIcon = () => {
    document.body.classList.remove('hidden');
    if (userId == null || userId == undefined) {
      setShowLogin(true)
    }
    else if (userId != null) {

      setShowModal(true)
    }

  }

  const handleCustomClose = () => {
    setCustomModal(false)
  }
  const handleActivity = () => {
    setShowChat(false)
    dispatch(updateActivityShow(true))
  }

  const handleChat = () => {
    dispatch(updateActivityShow(false))
    document.body.classList.remove('hidden');
    if (userId == null) {
      setShowLogin(true)
    }
    else if (userId != null) {
      setShowChat(true)
      dispatch(updateShowChat(true))
    }

  }


  const handleLibrary = () => {
    if (userId == null) {
      setShowLogin(true)
    }
    else if (userId != null) {
      navigate(`/private/profile/${userId}`, { state: { type: "user", id: userId, heading: "Library", activeTab: 'My Track' } })
    }
    document.body.classList.remove('hidden');
  }

  const handleFaqSupport = () => {
    navigate(`/faq`)
  };

  return (
    <>
      <div className='bottom-bar-wrapper'>
        <div className='icon-wrap ' onClick={() => {
          document.body.classList.remove('hidden');
          navigate('/active-battle')
        }}><BattleIcon /></div>
        <div className='icon-wrap' onClick={handleLibrary}><LibraryIcon /></div>
        <div className='icon-wrap'>
          <div className='add-icon' onClick={handleAddIcon}><AddIcon /></div>
        </div>
        <div className='icon-wrap' onClick={handleChat}>
          <div className={`${unreadMessage ? 'notification-count-wrap' : ''}`}> </div>
          <ChatIcon />
        </div>
        <div className='icon-wrap'>
          {isLoggedIn ? <>
            <Dropdown onToggle={(isOpen) => { if (isOpen) document.body.classList.remove('hidden'); }}>
              <Dropdown.Toggle>
                <img src={userInfo?.image ? userInfo?.image : UserImg} alt="User" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div className='dropdown-item'>
                  <div className='dropdown-title' onClick={() => {
                    navigate(`/private/profile/${userId}`, { state: { type: "user", id: userId } })
                  }}>My Profile</div>
                </div>
                <div className='dropdown-item' onClick={() => { navigate('/private/edit-profile') }}>
                  <div className='dropdown-title'>Settings</div>
                </div>
                <div className='dropdown-item' onClick={handleActivity}>
                  <div className='dropdown-title'>Activity</div>
                </div>
                <div className='dropdown-item' onClick={handleFaqSupport}>
                  <div className='dropdown-title'>Support</div>
                </div>
                <div className='dropdown-item'>
                  <div className='dropdown-title' onClick={handleLogout}>Log Out</div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </>
            :
            <div onClick={() => { setShowLogin(true) }}>
              <UserIcon />
            </div>
          }
        </div>
      </div>
      {showModal && <StartBattleModal showModal={showModal} handleClose={handleClose} setShowModal={setShowModal} setShowOpenBattleModal={setShowOpenBattleModal} setTrackInfo={setTrackInfo} setBattleOption={setBattleOption} setAddToPlaylist={setAddToPlaylist} addToPlaylist={addToPlaylist} />}
      {showOpenBattleModal && <OpenBattleModal showOpenBattleModal={showOpenBattleModal} trackInfo={trackInfo} setShowModal={setShowModal} setShowOpenBattleModal={setShowOpenBattleModal} battleOption={battleOption} getBattleList={getBattleList} handleOpenBattleClose={handleOpenBattleClose} addToPlaylist={addToPlaylist} />}
      {customModal && <CustomTextModal showModal={customModal} handleClose={handleCustomClose} title={modalTitle} body={modalBody} />}

      {showChat && userId &&
        <Chat
          userData={userInfo}
          userId={userId}
          userDetails={userDetails}
          showChat={showChat}
          setShowChat={setShowChat}
        />}

      {activityShow && <Activity />}

      {showLogin && <Login showLogin={showLogin} handleLoginClose={handleLoginClose} setShowLogin={setShowLogin} setShowModel={setShowOtpModel} setEmailLinkModal={setEmailLinkModal} setShowSignUp={setShowSignUp} setUser={setUser} setShowSetUp={setShowSetUp} setId={setUserId} setToken2={setToken2} />}
      {showSignUp && <SignUp showSignUp={showSignUp} handleSignUpClose={handleSignUpClose} setShowSignUp={setShowSignUp} setShowLogin={setShowLogin} setShowModel={setShowOtpModel} setUser={setUser} user={user} setId={setUserId} setToken2={setToken2} />}
      {showOtpModal && <OtpModal showModal={showOtpModal} handleClose={handleOtpClose} setShowModel={setShowOtpModel} user={user} setShowLogin={setShowLogin} setShowSetUp={setShowSetUp} id={UserId} token2={token2} />}
      {emailLinkModal && <EmailLinkForPassword emailLinkModal={emailLinkModal} handleEmailLinkClose={handleEmailLinkClose} setShowLogin={setShowLogin} />}
      {showSetUp && <SetUpAccount showSetUp={showSetUp} handleSetUpClose={handleSetUpClose} />}
    </>
  )
}

export default BottomBar