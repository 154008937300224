import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link, } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { EyeIcon, EyeIconOff, FacebookIcon, GoogleIcon } from '../../Component/Icons';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { emailLinkSchema } from '../../ValidationSchema/validationSchema';
import * as cs from '../../Api/cs'
import Modal from 'react-bootstrap/Modal';

const EmailLinkForPassword = ({ emailLinkModal, handleEmailLinkClose, setShowLogin }) => {
    const navigate = useNavigate()
    const [emailSent, setEmailSent] = useState(false)
    const [content, setContent] = useState('')

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        clearErrors,
    } = useForm({
        resolver: yupResolver(emailLinkSchema),
        mode: 'onChange',
    });;

    const onSubmit = async (data) => {
     

        const _data = {
            email: data?.email,
        }
        const response = await cs.post('emailSendLink', 'users', _data, null)
        setEmailSent(true)
        setContent(response?.data?.message)

        setTimeout(() => {
            handleEmailLinkClose()
        }, 5000);


    

    }

    const backToLogin = () => {
        handleEmailLinkClose()
        setShowLogin(true)
    }

    return (
        <Modal show={emailLinkModal} onHide={handleEmailLinkClose} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
                <div className='auth-form-wrapper-main'>
                    <form className="auth-form-wrapper" onSubmit={handleSubmit(onSubmit)} >
                        <div className='auth-form-header'>
                            <div className='auth-form-title'>Forgot Password ? </div>
                        </div>
                        <div className='auth-form'>
                            <div className="form-row">
                                <Form.Control type="text" name="email" placeholder='E-mail or Username'  {...register("email")}
                                    {...register('email')}
                                    onChange={(e) => {
                                        setValue('email', e.target.value, { shouldValidate: true });
                                    }}
                                />
                                {errors.email && <div className="errorMsg">{errors.email.message}</div>}
                            </div>
                            <div className="form-action-row">
                                <div className='back' onClick={backToLogin}>Back to Login</div>
                                <div className='flex-shrink-0 btn-wrap'> <Button variant='primary' type="submit">Submit</Button></div>
                            </div>

                            {emailSent &&
                                <div className="form-action-row">
                                    <div className='auth-form-title' style={{ "color": "red" }}> {content}
                                    </div>
                                </div>}
                        </div>
                    </form>
                </div>
            </Modal.Body>

        </Modal>

    )
}

export default EmailLinkForPassword
