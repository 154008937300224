import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Navigate, useNavigate } from 'react-router-dom'
import * as cs from '../../Api/cs'
import useHeader from '../../Helper/useHeader'
import './ConfirmationModal.scss'

const ConfirmationModal = ({ showModal, handleClose, id, type, action = null }) => {
    const headers = useHeader()
    const navigate = useNavigate()
    const [text, setText] = useState('')

    useEffect(() => {
        if (type == 'battle') {
            setText('Are you sure you want to withdraw from this battle? This action cannot be undone.')
        }
        else if (type == 'track') {
            setText('Are you sure?\nDeleting this track will remove it from your library but will not remove it from any active or past battles.')
        }
    }, [])


    const handleConfirmation = async () => {

        if (type == "battle") {
            const response = await cs.put('withdrawBattle/' + id, 'battel', null, {}, headers)

            if (response?.data?.code == 200) {
                navigate('/')
            }

        }
        else if (type == 'track') {
            const response = await cs.put('deleteTrack/' + id, 'track', null, {}, headers)
            if (response?.data?.code == 200) {
                if (action !== null) {
                    action()
                }

            }
        }
    }
    return (
        <div>
            <Modal show={showModal} onHide={handleClose} className='report-a-modal' size="sm" aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    {/* <Modal.Title >Are you sure ?</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <div className='thanks-wrapper'>
                        <div className='thanks-message'>{text.split('\n').map((line, index) => (
                            <div key={index}>{line}</div>
                        ))}</div>
                        <div className='btn-wrap'><Button variant='primary' onClick={handleConfirmation}>Yes</Button>
                            <Button variant='secondary' onClick={handleClose}>No</Button></div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ConfirmationModal
