import React from 'react';
export const QuestionRow1 = [
    {
        id: 1,
        question: 'What is CNTNDR?',
        answer: `CNTNDR is a music competition platform where music creators can upload their tracks and battle head-to-head against other Contenders. The CNTNDR community votes on who wins each battle, making every user a part of the experience. Creators compete for a virtual currency called Clout, which fuels the competition.`
    },
    {
        id: 2,
        question: 'What is Clout, and how do i earn it?',
        answer: <div>
            <div> Clout is the virtual currency on CNTNDR. You can earn Clout by:</div>
            <ul>
                <li>Winning battles as a Contender.</li>
                <li>Voting in battles, where voters are rewarded for their participation.</li>
                <li>Unlocking badges</li>
                <li>Completing challenges and engaging in other CNTNDR activities.</li>
            </ul>
        </div>
    },
    {
        id: 3,
        question: 'How do i become a Contender?',
        answer: `Becoming a Contender is simple and free! All you need to do is sign up, upload your original track, and use your Clout to join a battle. Whether you're a beginner or a pro, there's always someone to challenge, and the community will determine who wins.`
    },
    {
        id: 4,
        question: 'How does the battle system work?',
        answer: `To enter a battle, creators must spend a set amount of Clout as an entry fee. Two Contenders in the same musical genre are paired up, and their music is showcased for the community to vote on. The winner of the battle earns Clout from the entry fees, while the loser forfeits their Clout. It’s a head-to-head showdown where your talent speaks for itself!`
    },
    {
        id: 5,
        question: 'How do I vote on battles?',
        answer: `As a member of the CNTNDR community, you can vote in any ongoing battles except for your own. Simply listen to both tracks and cast your vote for the one you think deserves to win. Your vote will help decide the outcome, and you'll earn Clout as a reward for participating.`
    },
    {
        id: 6,
        question: 'How is Clout distributed when I win a battle?',
        answer: `The winner of a battle receives the combined Clout from both participants' entry fees. The more Clout at stake, the higher the reward for victory.`
    },
    {
        id: 7,
        question: 'Can I challenge a specific Contender?',
        answer: `Yes, when you start a battle you choose whether you want to make the battle open for any Contender to join, or send a challenge to a friend or other fellow Contender. Whether it is an open battle or a challenge battle, the battle will remain available to join for 24 hours from the moment the battle is created.`
    },
    {
        id: 8,
        question: 'What are badges, and how do I earn them on CNTNDR?',
        answer: `Badges are special achievements that you can unlock by completing specific actions on the CNTNDR platform. They represent your progress, skills, and engagement. For example, you might earn badges for winning a certain number of battles, voting in multiple contests, or reaching a Clout milestone.`
    },

];


export const QuestionRow2 = [


    {
        id: 9,
        question: 'Do badges have any impact on my Clout or battles?',
        answer: `Badges are primarily a way to showcase your achievements and build your reputation within the CNTNDR community. While they don’t directly affect your battle outcomes, you do earn Clout with every badge you unlock and every level within badges that have multiple levels.`
    },
    {
        id: 10,
        question: 'How can I view the badges I’ve earned?',
        answer: `You can view your earned badges by navigating to your Wallet. There, you'll find a section displaying all the badges you've unlocked, as well as any upcoming badges in gray that you can still achieve. You can also see how close you are to earning new badges based on your current progress.`
    },
    {
        id: 11,
        question: 'Can I use the same track in more than one battle?',
        answer: `Yes, you can use the same track in as many battles as you’d like. Reusing a track gives you the opportunity to see how it performs against different Contenders and in different matchups. Just be mindful that overuse might make it predictable to the audience!`
    },

    {
        id: 12,
        question: 'Does uploading a track affect my ownership of it at all?',
        answer: `No, uploading a track to CNTNDR does not affect your ownership or rights to the music. You retain full ownership and copyright of your work. By uploading, you’re simply granting CNTNDR the right to showcase your music for the purpose of battles and community voting.`
    },
    {
        id: 13,
        question: `Can I upload someone else's track for a battle?`,
        answer: `No, only original tracks that you have created or hold the rights to can be uploaded for battles. Uploading someone else's work without permission is a violation of our Terms & Conditions and could result in penalties, including removal from CNTNDR.`
    },
    {
        id: 14,
        question: `What can I do if my opponent's track is in the wrong genre, or not their original work?`,
        answer: `If you believe that your opponent’s track is not their original work or doesn’t match the genre of the battle, you can report the track using the “Report” button. Our team will review the submission, and if necessary, take action to maintain fairness within the competition.`
    },
    {
        id: 15,
        question: 'My battle disappeared. Where did it go?',
        answer: `Both open battles and challenge battles have a set time limit for someone to join. If your battle has disappeared, its likely that joining period has expired. In these cases, you will have their Clout refunded, and the battle won’t count toward your win/loss record.`
    },
    {
        id: 16,
        question: `Why don't you have X genre?`,
        answer: `CNTNDR is always expanding its genre offerings based on user feedback. If your preferred genre isn’t available yet, let us know by submitting a request through our feedback section. As we grow, we’re continuously adding new genres to accommodate a wider range of music styles and tastes.`
    }
];