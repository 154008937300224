import React, { useEffect, useState } from 'react'
import TrackThumbnailImg from '../../assets/images/track-thumnail.png'
import './TrackPlayer.scss';
import { ColoredStarIcon, PauseIcon, PlayIcon, PlayMenuIcon, StarIcon } from '../Icons';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { handleShowPlayer, updateArtistId, updateIsPlaying, updateTrack, updateTrackList, updateTrackProgress } from '../../Redux/Slices/player.slice';
import useHeader from '../../Helper/useHeader';
import * as cs from "../../Api/cs"
import ConfirmationModal from '../../Modal/ConfirmationModal/ConfirmationModal';
import EditTracKModal from '../../Modal/EditTrackModal/EditTracKModal';
import Login from '../../Pages/Login/Login';
import SignUp from '../../Pages/SignUp/SignUp';
import OtpModal from '../../Modal/OtpModal';
import EmailLinkForPassword from '../../Pages/EmailLinkForPassword/EmailLinkForPassword';
import SetUpAccount from '../../Modal/SetUpAccount/SetUpAccount';


const TrackPlayer = ({ trackToBePlayed, isPlayingSong, action = null,list }) => {

 

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { showPlayer, track, artistId, isPlaying, trackList } = useSelector(state => state?.player)

    const [currentTrack, setCurrentTrack] = useState(null);
    const [isFav, setIsFav] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [editTrack, setEditTrack] = useState(false)
    const [showLogin, setShowLogin] = useState(false);
    const [showModal, setShowModel] = useState(false)
    const [emailLinkModal, setEmailLinkModal] = useState(false)
    const [showSignUp, setShowSignUp] = useState(false)
    const [showSetUp, setShowSetUp] = useState(false)
    const [user, setUser] = useState(null)
    const [id, setId] = useState(null)
    const [token2, setToken2] = useState(null)
    const handleLoginClose = () => {
      setShowLogin(false)
    }
    const handleClose = () => {
      setShowModel(false)
    }
    const handleSignUpClose = () => {
      setShowSignUp(false)
    }
  
    const handleSetUpClose = () => {
      setShowSetUp(false)
    }
    const handleEmailLinkClose = () => {
      setEmailLinkModal(false)
    }


    const { userId } = useSelector(state => state.user)
    const headers = useHeader()

    const handleConfirmClose = () => {
        setConfirm(false)
    }
    const handleEditTrackClose = () => {
        setEditTrack(false)
    }
    const handlePlayer = () => {
     
        if (!showPlayer) {
            dispatch(handleShowPlayer(true))
        }



        dispatch(updateTrack(trackToBePlayed))
        dispatch(updateTrackProgress(0))
        dispatch(updateArtistId(trackToBePlayed?.createdBy?._id))
        dispatch(updateIsPlaying(true))
        dispatch(updateTrackList(list))


        if (isPlaying && track?._id === trackToBePlayed?._id) {
            dispatch(updateIsPlaying(false))
        }

        // navigate('/track',{state : {trackId : track?._id}})


        // if (!currentTrack || currentTrack._id !== trackToBePlayed._id) {
        //     // If it's a new track, start playing from the beginning
        //     setCurrentTrack(trackToBePlayed);
        //     dispatch(updateTrack(trackToBePlayed));
        //     dispatch(updateTrackProgress(0));
        //     dispatch(updateArtistId(trackToBePlayed?.createdBy?._id));
        //     dispatch(updateIsPlaying(true));
        // } else if (isPlaying) {

        //     dispatch(updateIsPlaying(false));
        // } else {
        //     dispatch(updateIsPlaying(true));
        // }
    }

    // const handlePlayer = () => {
    //     if (!showPlayer) {
    //        dispatch(handleShowPlayer());
    //     }

    //     if (!currentTrack || currentTrack._id !== trackToBePlayed._id) {
    //         // If it's a new track, start playing from the beginning
    //         setCurrentTrack(trackToBePlayed);
    //         dispatch(updateTrack(trackToBePlayed));
    //         dispatch(updateTrackProgress(0));
    //         dispatch(updateArtistId(trackToBePlayed?.createdBy?._id));
    //         dispatch(updateIsPlaying(true));
    //     } else if(isPlaying) {
    //         // If it's the same track, toggle play/pause
    //         dispatch(updateIsPlaying(false));
    //     }
    //     else if(!isPlaying)
    //     {
    //         dispatch(updateIsPlaying(true));
    //     }
    // }

    const handleTrackPage = () => {
        // dispatch(updateTrack(trackToBePlayed))
        navigate(`/track/${trackToBePlayed?._id}`, { state: { trackId: trackToBePlayed?._id } })
    }

    const renderPlayPauseIcon = () => {
        if (isPlaying && currentTrack && currentTrack._id === trackToBePlayed._id) {
            return <PauseIcon />;
        } else {
            return <PlayIcon />;
        }
    }

    useEffect(() => {
        if(userId != null)
        {
            favoriteCheck()
        }
        
    }, [])

    const favoriteCheck = async () => {

        const response = await cs.get('list/' + userId + '/' + trackToBePlayed?._id, 'myFav', {}, headers)

        if (response?.data?.value === true) {
            setIsFav(true)
        }
        else if (response?.data?.value === false) {
            setIsFav(false)
        }

    }


    const addToFavorite = async () => {

        if(userId == null)
        {
         setShowLogin(true)
        }
        else if(userId != null)
        {
            const _data = {
                "trackId": trackToBePlayed?._id,
                "trackName": trackToBePlayed?.track_name,
                "userId": userId
            }
    
    
            const response = await cs.post('add', 'myFav', _data, null, headers)
           
            setIsFav(response?.data?.status)
        }
        
    }


    const getPayPause = () => {

        if (isPlaying && isPlayingSong) {
            return <PauseIcon />

        }
        else {
            return <PlayIcon />
        }
    }

    const deleteTrack = async () => {
        setConfirm(true)

    }

    return (
        <>
            <div className='trackplayer-wrapper' >
                <><div className='track-thmbnail'>
                    <img src={trackToBePlayed?.track_image} alt="Track" />
                    <div className='track-control' onClick={handlePlayer}>
                        {getPayPause()}
                    </div>

                </div>
                    <div className='track-detail-wrap' >
                        <div className='track-info-wrap' onClick={handleTrackPage}>
                            <div className='track-author-detail'>
                                <div className='track-author-name'>{trackToBePlayed?.createdBy?.name ? trackToBePlayed?.createdBy?.name : trackToBePlayed?.createdBy?.userName}</div>
                                <div className='track-name'>{trackToBePlayed?.track_name}</div>


                            </div>
                            <div className='track-time'>{trackToBePlayed?.duration}</div>
                        </div>
                        <div className='track-detail-right'>
                            <div className='track-play-time' onClick={handleTrackPage}>
                                {/* plays is hiding because of justin  */}
                                {/* <div className='total-play-time'>{trackToBePlayed?.plays_inDay}  plays</div>  */}
                                <div className='total-play-time'>{trackToBePlayed?.track_genre?.label}</div> 

                                {/* <div className='track-play-divider'>
                                    <span></span>
                                </div> */}
                                {/* <div className='total-save'>50 saves</div> */}
                            </div>
                            <div className='tracking-rating' onClick={addToFavorite} >{isFav ? <ColoredStarIcon /> : <StarIcon />}</div>

                            <div className='track-play-menu'>
                                {userId && userId === trackToBePlayed?.createdBy?._id && <Dropdown >
                                    <Dropdown.Toggle variant="" id="dropdown-basic" >
                                        <PlayMenuIcon />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className='dropdown-item' onClick={() => { setEditTrack(true) }}>
                                            <div className='dropdown-title'>Edit</div>
                                        </div>
                                        <div className='dropdown-item' onClick={deleteTrack}>
                                            <div className='dropdown-title'>Delete</div>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                                }

                            </div>

                        </div>
                    </div></>

            </div>
            {confirm && <ConfirmationModal showModal={confirm} handleClose={handleConfirmClose} id={trackToBePlayed?._id} type="track" action={action} />}
            {editTrack && <EditTracKModal editTrack={editTrack} handleClose={handleEditTrackClose} data={trackToBePlayed} action={action} />}
            {showLogin && <Login showLogin={showLogin} handleLoginClose={handleLoginClose} setShowLogin={setShowLogin} setShowModel={setShowModel} setEmailLinkModal={setEmailLinkModal} setShowSignUp={setShowSignUp} setUser={setUser} setShowSetUp={setShowSetUp} setId={setId} setToken2={setToken2} />}
            {showSignUp && <SignUp showSignUp={showSignUp} handleSignUpClose={handleSignUpClose} setShowSignUp={setShowSignUp} setShowLogin={setShowLogin} setShowModel={setShowModel} setUser={setUser} user={user} setId={setId} setToken2={setToken2} />}
            {showModal && <OtpModal showModal={showModal} handleClose={handleClose} setShowModel={setShowModel} user={user} setShowLogin={setShowLogin} setShowSetUp={setShowSetUp} id={id} token2={token2} />}
            {emailLinkModal && <EmailLinkForPassword emailLinkModal={emailLinkModal} handleEmailLinkClose={handleEmailLinkClose} setShowLogin={setShowLogin} />}
            {showSetUp && <SetUpAccount showSetUp={showSetUp} handleSetUpClose={handleSetUpClose} />}
        </>
    )
}

export default TrackPlayer