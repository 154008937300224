import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoggedIn: false,
  userId : null,
  userInfo : null
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
   updateLoginStatus: (state, action) => {
   
    state.isLoggedIn = action.payload;
   },
   updateUserId : (state, action) => {
  
    state.userId= action.payload;
   },
   updateUserData: (state, action) => {
    state.userInfo = action.payload;
  },
  },
})

export const { updateLoginStatus , updateUserId,updateUserData} = userSlice.actions

export default userSlice.reducer