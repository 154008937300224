import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  searchBy : 'all',
  search :'',
  searchStatus :"false",
  blinkSearch : false,
  tag :'',
  tagSearch : false,
  
}

export const searchSlice = createSlice({
  name: 'battle',
  initialState,
  reducers: {
   updateSearchBy : (state, action) => {
    state.searchBy = action.payload;
   },
   updateSearch : (state,action) =>
   {
    state.search = action.payload
    state.searchStatus = "false"
   },
   updateSearchStatus : (state,action) =>
   {
    state.searchStatus = action.payload;
   },
   updateBlinkSearch : (state, action) =>{
    state.blinkSearch = action.payload
   },
   updateTag : (state, action) => {
    state.tag = action.payload
   },
   updateTagSearch : (state,action) => {
    state.tagSearch = action.payload
   }
  },
})

export const {updateSearchBy,updateSearch,updateSearchStatus,updateBlinkSearch
,updateTag,updateTagSearch} = searchSlice.actions

export default searchSlice.reducer