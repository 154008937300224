import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import * as cs from '../../../Api/cs'
import useHeader from '../../../Helper/useHeader';
import BattleCard from '../../../Component/BattelCard/BattleCard';
import './ActiveBattlePage.scss'
import { useDispatch } from 'react-redux';
import { updateBattleId } from '../../../Redux/Slices/battle.slice';
import ReactPaginate from 'react-paginate';
import StartBattleModal from '../../../Modal/StartBattleModal/StartBattleModal';
import OpenBattleModal from '../../../Modal/OpenBattleModal/OpenBattleModal';
import { SocketContext } from '../../../Context/socket';
import { Scrollbars } from 'react-custom-scrollbars-2';


const ActiveBattlePage = () => {
    const [showModal, setShowModal] = useState(false);
    const [showOpenBattleModal, setShowOpenBattleModal] = useState(false);
    const [trackInfo, setTrackInfo] = useState('');
    const [battleOption, setBattleOption] = useState('')
    const [battleList, setBattleList] = useState([])
    const [addToPlaylist, setAddToPlaylist] = useState(false);
    const [sort, setSort] = useState('dsc')
    const [filter, setFilter] = useState('abcd')
    const headers = useHeader()
    const socket = useContext(SocketContext)

    const dispatch = useDispatch()

    const handleOpenBattleClose = () => {
        setShowOpenBattleModal(false);
    };

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const navigate = useNavigate()


    useEffect(() => {
        getBattleList()
    }, [])


    useEffect(() => {
     
    }, [showModal])

    const getBattleList = async () => {
        const response = await cs.get('list/' + sort + "/" + filter, 'battel', {}, headers)
     
        setBattleList(response?.data?.data)

    }

    const handleBattleCard = (item) => {
     
        dispatch(updateBattleId(item))
        navigate(`/battle/${item}`, { state: { itemData: item, battles: battleList } })
    }

  

    return (
        <>

            <div className='battle-active-wrapper'>
                <div className='main-wrapper'>
                    <div className='main-container'>
                        <div className='active-battle-content-wrapper'>
                            <div className='btn-wrapper'>
                                <Button variant='primary'>Active Battle</Button>
                                {/* <Button variant=''>Battle History</Button> */}
                                <Button variant='' type='button' onClick={handleShow}>Create Battle</Button>
                            </div>
                            <Scrollbars style={{ width: '100%', height: '600px' }}>
                            <div className='active-battleList-wrapper'>

                                {battleList?.map((item) => (

                                    <div className="battleCardContainer" onClick={() => { handleBattleCard(item?._id) }}>
                                        <BattleCard battleId={item?._id}  type={item?.battleOpponentStatus ? null : 'awaiting'} />
                                    </div>
                                ))}
                            </div>
                            </Scrollbars>
                        </div>

                        {/* {
                        showModal && <Modal show={showModal} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Start a Battle</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Battle setShowModal={setShowModal} setShowOpenBattleModal={setShowOpenBattleModal} setTrackInfo={setTrackInfo} setBattleOption={setBattleOption} />
                            </Modal.Body>
                        </Modal>
                        } */}

                        {showModal && <StartBattleModal showModal={showModal} handleClose={handleClose} setShowModal={setShowModal} setShowOpenBattleModal={setShowOpenBattleModal} setTrackInfo={setTrackInfo} setBattleOption={setBattleOption} setAddToPlaylist={setAddToPlaylist} addToPlaylist={addToPlaylist}/>}
                        {showOpenBattleModal && <OpenBattleModal showOpenBattleModal={showOpenBattleModal} trackInfo={trackInfo} setShowModal={setShowModal} setShowOpenBattleModal={setShowOpenBattleModal} battleOption={battleOption} getBattleList={getBattleList} handleOpenBattleClose={handleOpenBattleClose} addToPlaylist={addToPlaylist}/>}
                        {/* {
                            showOpenBattleModal &&

                            <Modal show={showOpenBattleModal} onHide={handleOpenBattleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Open Battle</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <OpenBattle trackInfo={trackInfo} setShowModal={setShowModal} setShowOpenBattleModal={setShowOpenBattleModal} battleOption={battleOption} getBattleList={getBattleList} />
                                </Modal.Body>
                            </Modal>
                        } */}


                    </div>

                </div>
            </div>
        </>
    )
}

export default ActiveBattlePage
