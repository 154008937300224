import React from 'react'
import PhoneImg from '../../assets/images/phone.png'
import './OpenChat.scss'
import { Button } from 'react-bootstrap'
import { DiscordIcon } from '../Icons'
const Chat = () => {
  return (
    <>
    <div className='chat-main-wrapper'>
        <div className='main-wrapper'>
            <div className='main-container chat-wrap'>
                <div className='chat-container'>
                    <div className='phone-wrap'>
                        <img src={PhoneImg} alt=''/>
                    </div>
                    <div className='chat-content-wrap'>
                        <div className='chat-title'>Text about chat</div>
                        <div className='chat-description'>
                            <p>Lorem ipsum dolor sit amet consectetur. Mattis morbi eget tristique feugiat risus tempus maecenas. Tempor euismod eget etiam a tincidunt nec sem</p>
                            <p>Lorem ipsum dolor sit amet consectetur. Mattis morbi eget tristique feugiat risus tempus maecenas. </p>
                        </div>
                        <div className='chat-btn-wrap'>
                            <Button variant='primary'><DiscordIcon/> Open Chat</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Chat