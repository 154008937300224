import React, { useEffect, useState } from 'react'
import LeaderImg from '../../../assets/images/leader.png'
import { CloutIcon } from '../../../Component/Icons'
import { Button } from 'react-bootstrap'

import './GenreLeaderBoard.scss'
import GenreLeaderboardCard from '../../../Component/GenreLeaderboardCard/GenreLeaderboardCard'
import useHeader from '../../../Helper/useHeader'
import * as cs from '../../../Api/cs'
import UserImg from '../../../assets/images/default.png'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Login from '../../Login/Login'
import SignUp from '../../SignUp/SignUp'
import OtpModal from '../../../Modal/OtpModal'
import EmailLinkForPassword from '../../EmailLinkForPassword/EmailLinkForPassword'
import SetUpAccount from '../../../Modal/SetUpAccount/SetUpAccount'

const GenreLeaderBoard = ({ genre }) => {
    const [active, setActive] = useState('weekly')
    const [leaderboard, setLeaderBoard] = useState([])
    const [leader, setLeader] = useState({})
    const [rank, setRank] = useState(1)
    const [fanContent, setFanContent] = useState('')
    const [showLogin, setShowLogin] = useState(false);
    const [showModal, setShowModel] = useState(false)
    const [emailLinkModal, setEmailLinkModal] = useState(false)
    const [showSignUp, setShowSignUp] = useState(false)
    const [showSetUp, setShowSetUp] = useState(false)
    const [user, setUser] = useState(null)
    const [id, setId] = useState(null)
    const [token2, setToken2] = useState(null)
    const [selectedLeaderIndex, setSelectedLeaderIndex] = useState(1);
    const handleLoginClose = () => {
        setShowLogin(false)
    }
    const handleClose = () => {
        setShowModel(false)
    }
    const handleSignUpClose = () => {
        setShowSignUp(false)
    }

    const handleSetUpClose = () => {
        setShowSetUp(false)
    }
    const handleEmailLinkClose = () => {
        setEmailLinkModal(false)
    }

    const { userId } = useSelector(state => state.user)
    const headers = useHeader()
    const navigate = useNavigate()

    useEffect(() => {
        getLeaderBoard(active)

    }, [])


    const handleActive = async (category) => {
        setSelectedLeaderIndex(1)
        setActive(category)
        getLeaderBoard(category)
        setRank(1)
    }

    const getLeaderBoard = async (category) => {
        const response = await cs.get('topWinner/' + genre + '/' + category, 'leaderboard', {}, headers);
        const leaderboardData = response?.data?.data || [];

        // Sort and rank based on the selected category
        const sortByCategory = {
            weekly: 'weeklyWinCount',
            monthly: 'monthlyWinCount',
            allTime: 'winCount'
        };

        const sortedLeaderboard = leaderboardData.sort((a, b) => b[sortByCategory[category]] - a[sortByCategory[category]]);
        let currentRank = 1;
        let lastScore = null;

        const leaderboardWithRank = sortedLeaderboard.map((item, index) => {
            const score = item[sortByCategory[category]];
            if (score !== lastScore) {
                lastScore = score;
                currentRank = index + 1; // New rank for current score
            }
            return { ...item, rank: currentRank };
        });

        setLeaderBoard(leaderboardWithRank.slice(0, 10));
        setLeader(leaderboardWithRank[0]);
        getFollowingFollowers(leaderboardWithRank[0]?._id);
    };





    const handleLeader = (index, item) => {
        setSelectedLeaderIndex(index);
        setLeader(item)
        setRank(index)
        getFollowingFollowers(item?.userId?._id)
    }

    const addFollower = async (id) => {
        if (userId == null) {
            setShowLogin(true)
        }
        else if (userId !== null) {
            const _data = {
                "followUser": id
            }
            const response = await cs.post('add', 'follow', _data, headers)


            getFollowingFollowers(id)
        }


    }

    const getFollowingFollowers = async (id) => {
        const response = await cs.get('getfollowers/' + id + "/" + userId, 'follow', {}, headers)

        if (response?.data?.code === 200) {
            // setFollowingData(response?.data?.data)
            if (response?.data?.data?.fanStatus == 1) {
                setFanContent("Following")
            }
            else {
                setFanContent("I'm a fan")
            }

        }

    }
    const getClassName = (index) => {
        switch (index) {
            case 0:
                return 'gold';
            case 1:
                return 'silver';
            case 2:
                return 'platinum';
            default:
                return '';
        }
    };
    const getClassNameFor = (rank) => {
        switch (rank) {
            case 1:
                return 'gold';
            case 2:
                return 'silver';
            case 3:
                return 'platinum';
            default:
                return '';
        }
    };
    const getClassNameForwrap = (rank) => {
        switch (rank) {
            case 1:
                return 'gold';
            case 2:
                return 'silver';
            case 3:
                return 'platinum';
            default:
                return '';
        }
    };
    const renderListItems = () => {
        return Array.from({ length: 10 }, (_, index) => (
            <div key={index} className="list-item">
                <span>{index + 1}</span>
                <img src={UserImg} alt="User" /> -
                <span>- wins</span>
            </div>
        ));
    };
    return (
        <>
            <div className='genre-leaderboard-wrapper'>
                <div className='main-wrapper'>
                    <div className='main-container'>
                        <div className='genre-leaderboard'>
                            <div className='genre-leaderboard-title'>Leaderboard</div>
                            <div className='genre-leaderboard-content-wrap'>
                                <div className='genre-leaderboard-tab'>
                                    <div className={`gl-tab ${active === 'weekly' ? 'active' : ''}`} onClick={() => { handleActive("weekly") }}>WEEKLY</div>
                                    <div className={`gl-tab ${active === 'monthly' ? 'active' : ''}`} onClick={() => { handleActive("monthly") }}>MONTHLY</div>
                                    <div className={`gl-tab ${active === 'allTime' ? 'active' : ''}`} onClick={() => { handleActive("allTime") }}>ALL TIME</div>
                                </div>

                                {leaderboard && leaderboard?.length > 0 && leader ?
                                    <div className='genre-leaderboard-tab-content'>
                                        <div className='genre-leader-wrap'>
                                            <div className='genre-leader-left'>
                                                <div className='genre-leader-img-wrap'>
                                                    <div className={`genre-leader-img cursor-pointer ${getClassNameFor(rank)}`} onClick={() => { navigate(`/profile/${leader?.userId?._id}`, { state: { type: "user", id: leader?.userId?._id } }) }}>
                                                        <img src={leader?.userId?.image ? leader?.userId?.image : UserImg} alt='Leader' />
                                                    </div>
                                                    {rank <= 3 && (
                                                        <div className={`counter-wrap ${getClassNameForwrap(rank)}`}>{rank}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='genre-leader-info'>
                                                <div className='genre-placement-wrap'>
                                                    {rank === 1 && <> <div className='genre-placement-title gold'>TOP CONTENDER</div>
                                                        <div className='genre-placement'>{rank}st Place</div>
                                                    </>}
                                                    {rank === 2 && <> <div className='genre-placement-title silver '>RUNNER-UP</div>
                                                        <div className='genre-placement'>{rank}nd Place</div> </>}
                                                    {rank === 3 && <> <div className='genre-placement-title platinum'>ON THE HUNT</div>
                                                        <div className='genre-placement'>{rank}rd Place</div></>}
                                                    {rank >= 4 && <> <div className='genre-placement-title pink'>ON THE MOVE</div>
                                                        <div className='genre-placement'>{rank}th Place</div></>}
                                                </div>
                                                <div className='genre-name-wrap'>
                                                    <div className='genre-name cursor-pointer' onClick={() => { navigate(`/profile/${leader?.userId?._id}`, { state: { type: "user", id: leader?.userId?._id } }) }}><span>{leader?.userId?.name ? leader?.userId?.name : leader?.userId?.userName}</span></div>
                                                    <div className='genre-hastag cursor-pointer' onClick={() => { navigate(`/profile/${leader?.userId?._id}`, { state: { type: "user", id: leader?.userId?._id } }) }}><span>@{leader?.userId?.userName}</span></div>
                                                </div>
                                                <div className='genre-info-wrap'>
                                                    {active === 'weekly' && <div className='genre-battle-info'>Battles won: {leader?.weeklyWinCount}</div>}
                                                    {active === 'monthly' && <div className='genre-battle-info'>Battles won: {leader?.monthlyWinCount}</div>}
                                                    {active === 'allTime' && <div className='genre-battle-info'>Battles won: {leader?.winCount}</div>}
                                                    <div className='genre-wallet-info'>
                                                        <span>Clout:</span><i><CloutIcon /></i> <span>{leader?.userId?.clout}</span>
                                                    </div>
                                                </div>
                                                {userId !== leader?.userId?._id && <div className='fan-btn-wrap'>
                                                    <Button variant='primary' onClick={() => { addFollower(leader?.userId?._id) }}>{userId === null ? "I'm a fan" : fanContent}</Button>
                                                </div>
                                                }
                                            </div>
                                        </div>


                                        <div className='gl-listing-wrap'>
                                            {leaderboard && leaderboard.length > 0 ? (
                                                leaderboard?.map((item, index) => (
                                                    <div className={`gl-listing cursor-pointer ${selectedLeaderIndex === item?.rank ? 'active' : ''}`} key={item._id} onClick={() => handleLeader(item?.rank, item)}>
                                                        <div className='gl-placement'>{item?.rank}</div>
                                                        <div className='gl-img-wrap'>
                                                            <div className={`gl-img ${getClassName(index)}`}>
                                                                <img src={item.userId?.image || UserImg} alt='Leader' />
                                                            </div>
                                                        </div>
                                                        <div className='gl-name'>{item.userId?.name || item.userId?.userName}</div>
                                                        {active === 'weekly' && <div className='gl-win-counts'>{item?.weeklyWinCount} wins</div>}
                                                        {active === 'monthly' && <div className='gl-win-counts'>{item?.monthlyWinCount} wins</div>}
                                                        {active === 'allTime' && <div className='gl-win-counts'>{item?.winCount} wins</div>}
                                                    </div>
                                                ))
                                            ) : (
                                                <div>No Record Available</div>
                                            )}
                                        </div>


                                    </div>
                                    // Apply this condition when data is not available 
                                    :
                                    <div className='genre-leaderboard-tab-content'>
                                        <div className='genre-leader-wrap'>
                                            <div className='genre-leader-left'>
                                                <div className='genre-leader-img-wrap'>
                                                    <div className={`genre-leader-img cursor-pointer ${getClassNameFor(rank)}`} onClick={() => { navigate(`/profile/${leader?.userId?._id}`, { state: { type: "user", id: leader?.userId?._id } }) }}>
                                                        <img src={leader?.userId?.image ? leader?.userId?.image : UserImg} alt='Leader' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='genre-leader-info'>
                                                <div className='genre-placement-wrap'>
                                                    {rank === 1 && <> <div className='genre-placement-title gold'>TOP CONTENDER</div>
                                                        <div className='genre-placement'>1st Place</div>
                                                    </>}
                                                </div>
                                                <div className='genre-name-wrap'>
                                                    <p style={{ color: 'white' }}>-</p>
                                                </div>
                                                <div className='genre-info-wrap'>
                                                    {active === 'weekly' && <div className='genre-battle-info'>Battles won: -</div>}
                                                    {active === 'monthly' && <div className='genre-battle-info'>Battles won: -</div>}
                                                    {active === 'allTime' && <div className='genre-battle-info'>Battles won: -</div>}
                                                    <div className='genre-wallet-info'>
                                                        <span>Clout:</span><i><CloutIcon /></i> <span>-</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='gl-listing-wrap'>
                                            {leaderboard && leaderboard?.length > 0 ?
                                                leaderboard.map((item, index) => (
                                                    <div className={`gl-listing cursor-pointer ${selectedLeaderIndex === index + 1 ? 'active' : ''}`} key={item?._id} onClick={() => { handleLeader(index + 1, item) }}>
                                                        <div className='gl-placement'>{index + 1}</div>
                                                        <div className='gl-img-wrap'>
                                                            <div className={`gl-img ${getClassName(index)}`}>
                                                                <img src={item?.userId?.image ? item?.userId?.image : UserImg} alt='Leader' />
                                                            </div>
                                                        </div>
                                                        <div className='gl-name'>{item?.userId?.name ? item?.userId?.name : item?.userId?.userName}</div>
                                                        {active === 'weekly' && <div className='gl-win-counts'>{item?.weeklyWinCount} wins</div>}
                                                        {active === 'monthly' && <div className='gl-win-counts'>{item?.monthlyWinCount} wins</div>}
                                                        {active === 'allTime' && <div className='gl-win-counts'>{item?.winCount} wins</div>}

                                                    </div>
                                                ))
                                                : <div className="no-records">
                                                    {renderListItems()}
                                                </div>
                                            }

                                        </div>
                                    </div>


                                }
                                <div className='genre-card-wrap'>
                                    <GenreLeaderboardCard genre={genre} active={active} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showLogin && <Login showLogin={showLogin} handleLoginClose={handleLoginClose} setShowLogin={setShowLogin} setShowModel={setShowModel} setEmailLinkModal={setEmailLinkModal} setShowSignUp={setShowSignUp} setUser={setUser} setShowSetUp={setShowSetUp} setId={setId} setToken2={setToken2} />}
            {showSignUp && <SignUp showSignUp={showSignUp} handleSignUpClose={handleSignUpClose} setShowSignUp={setShowSignUp} setShowLogin={setShowLogin} setShowModel={setShowModel} setUser={setUser} user={user} setId={setId} setToken2={setToken2} />}
            {showModal && <OtpModal showModal={showModal} handleClose={handleClose} setShowModel={setShowModel} user={user} setShowLogin={setShowLogin} setShowSetUp={setShowSetUp} id={id} token2={token2} />}
            {emailLinkModal && <EmailLinkForPassword emailLinkModal={emailLinkModal} handleEmailLinkClose={handleEmailLinkClose} setShowLogin={setShowLogin} />}
            {showSetUp && <SetUpAccount showSetUp={showSetUp} handleSetUpClose={handleSetUpClose} />}

        </>
    )
}

export default GenreLeaderBoard