import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

function PublicLayout() {

    const { isLoggedIn } = useSelector(state => state?.user);


    if (isLoggedIn) {
        return <Navigate to="/" />
    }

    return <Outlet />
}

export default PublicLayout