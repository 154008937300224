import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as cs from '../../../Api/cs'

import { contactUsSchema } from '../../../ValidationSchema/validationSchema';

import './index.css';

const ContactUs = props => {


    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState('');
    const { handleSubmit, formState: { errors }, register, reset } = useForm({
        resolver: yupResolver(contactUsSchema),
        mode: 'onChange',
    });

    const onSubmit = async data => {
        try {
            setIsLoading(true);
            const res = await cs.post('send', 'support', data, null)
            if (res?.data?.code === 200) {
                reset();
                setStatus(res?.data?.message);
            }
        } catch (err) {
            setStatus(err?.data?.message);
        }
        finally {
            setIsLoading(false);

            setTimeout(() => {
                setStatus('');
            }, 5000);
        }
    }
    return <div className="contact-us-container">
        <div className="contact-us-header">
            <h2>Contact Us</h2>
            <p>Still having trouble? We're here to help.</p>
        </div>

        <div>
            <form className='contact-us-form' onSubmit={handleSubmit(onSubmit)}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flex: 1 }}>
                    <div style={{ flex: 1, padding: 12 }}>
                        <Form.Group controlId="name">
                            <Form.Label>Your Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your name"
                                name="name"
                                {...register('name', { required: 'Name is required' })}
                            />
                            {errors.name && <div className="errorMsg">{errors.name.message}</div>}
                        </Form.Group>

                    </div>
                    <div style={{ flex: 1, padding: 12 }}>
                        <Form.Group controlId="name">
                            <Form.Label>Your Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter your email"
                                name="email"
                                {...register('email', { required: 'Email is required' })}
                            />
                            {errors.email && <div className="errorMsg">{errors.email.message}</div>}
                        </Form.Group>

                    </div>


                </div>

                <div style={{ padding: 12 }}>
                    <Form.Group controlId="bio">
                        <Form.Label>Message</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder="Enter your message"
                            name="message"
                            {...register('message', { maxLength: 255 })}

                        />
                        {errors.message && <div className="errorMsg">{errors.message.message}</div>}
                    </Form.Group>
                </div>

                <div style={{ padding: 12 }}>
                    {status && <div className="contact-status-message">{status}</div>}
                    <Button disabled={isLoading} variant="primary" type="submit">{isLoading ? 'Sending...' : 'Send'}</Button>
                </div>
            </form>
        </div >
    </div >
};

export default ContactUs;