import axios from 'axios';
import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import './ReportModal.scss';
import * as cs from '../../Api/cs'
import useHeader from '../../Helper/useHeader';

const ReportModal = ({ showModal, handleClose, setThanksModal, type }) => {

    const report = ['Copyright claim', 'Violent or lewd content', 'Unwanted content', 'Spam', 'Hate speech or violence', 'Accidental vote submitted', 'Other']
    const [selectedReason, setSelectedReason] = useState('');
    const [description, setDescription] = useState('');
    const [step, setStep] = useState(1);


    const headers = useHeader()

    const { battleId } = useSelector(state => state.battle)
    const { track } = useSelector(state => state.player)
    const handleNext = () => {
        if (step === 1 && !selectedReason) {
            alert("Please select a reason before proceeding.");
            return;
        }
        setStep(step + 1);
    };

    const handleBack = () => {
        setStep(step - 1);
    };

    const handleReasonChange = (event) => {
        setSelectedReason(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };


    const handleReport = async () => {

        const _data = {
            ...(type === "battle" && { battle_id: battleId }),
            ...(type === "track" && { track_id: track?._id }),
            reason: selectedReason,
            description: description
        }

        const response = await cs.post('addReport', 'report', _data, headers)
  
        if (response?.data?.code === 200) {
            handleClose()
            setThanksModal(true)
        }
    }
    return (
        <>
            <Modal show={showModal} onHide={handleClose} className='report-a-modal' size="sm" aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    {/* Condition needs to hide on next button */}
                    {step == 1 && <div className='reason-container'>
                        <div className='report-title'>
                            {`Why do you want`}
                            <br />
                            {`to report this ${type}?`}
                        </div>

                        <div className='reasons-wrap'>
                            {report.map(reason => (
                                <div className='reason-row' key={reason}>
                                    <Form.Check
                                        type='radio'
                                        id={reason}
                                        name='reason'
                                        label={reason}
                                        value={reason}
                                        onChange={handleReasonChange}
                                        checked={selectedReason === String(reason)}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className='btn-wrap'>
                            <Button variant='primary' onClick={handleNext}>Next</Button>
                        </div>
                    </div>
                    }
                    {/* Condition needs to hide on back button */}
                    {step == 2 && <div className='reason-container describe'>
                        <div className='report-title'>
                            Why do you want  <br />
                            to report this {type}?
                        </div>
                        <div className='one-reason'>{selectedReason}</div>
                        <Form className='form-row'>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder={`Describe why you’re reporting this ${type} (optional).`}
                                onChange={(e) => { setDescription(e.target.value) }}
                            />

                        </Form>

                        <div className='btn-wrap report'>
                            <Button variant='secondary' onClick={handleBack}>Back</Button>
                            <Button variant='primary' onClick={handleReport}>Report</Button>
                        </div>
                    </div>
                    }
                </Modal.Body>

            </Modal >
        </>
    )
}

export default ReportModal