import React, { useEffect } from 'react'
import Header from '../../Component/Header/Header'
import Sidebar from '../../Component/Sidebar/Sidebar'
import './Home.scss'
import HomeBanner from './HomeBanner/HomeBanner'
import HomeBattleSlider from './HomeBattleSlider/HomeBattleSlider'
import LeaderBoards from './LeaderBoards/LeaderBoards'
import HomeRegistration from './HomeRegistration/HomeRegistration'
import PopularGenres from './PopularGenres/PopularGenres'
import ActiveBattle from './HomeActiveBattle/HomeActiveBattle'
import PopularArtist from '../../Component/PopularArtist/PopularArtist'
import TrendingBeats from '../../Component/TrendingBeats/TrendingBeats'
import Shop from './Shop/Shop'
import BestSellers from './BestSellers/BestSellers'
import Chat from '../../Component/OpenChat/OpenChat'
import RegistrationBottom from '../../Component/RegistrationBottom/RegistrationBottom'
import { useDispatch, useSelector } from 'react-redux'
import { socket } from '../../Context/socket'
import BottomBar from '../../Component/BottomBar/BottomBar'
import Player from '../../Component/Player/Player'
import { updateSearch } from '../../Redux/Slices/search.slice'
import NewBattles from '../../Component/Battles/NewBattles'
import EndingBattles from '../../Component/Battles/EndingBattles'
import JoinBattles from '../../Component/Battles/JoinBattles'
import Announcement from './Announcement/Announcement'
import RegistrationBottomUser from '../../Component/RegistrationBottomUser/RegisterBottomUser'


const Home = () => {
  const { userId } = useSelector(state => state.user);

  const { search } = useSelector(state => state.search)
  const dispatch = useDispatch()

  useEffect(() => {
    if (search) {
      dispatch(updateSearch(''))
    }
    scrollToTop()
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    socket.on("create_socket", (data) => {
      if (data) {
        console.log('data.socketId',data.socketId)
        localStorage.setItem("socketId", data.socketId)
        if (userId && (userId !== undefined || userId !== "")) {
          const socketData = {
            userId: userId,
            socketId: localStorage.getItem("socketId")
          }
          socket.emit("updateSocketId", socketData);
        }
      }
    })
    if (userId && (userId !== undefined || userId !== "")) {
      const socketData = {
        userId: userId,
        socketId: localStorage.getItem("socketId")
      }
      socket.emit("updateSocketId", socketData);
    }
  }, [socket, userId])


  const { showPlayer } = useSelector(state => state.player)



  return (
    <>
      <Sidebar />
      <Header />
      <div className="main-content" >
        {/* Need Condition when Loggedin */}
        {userId &&
          <Announcement />
        }

        {!userId && <HomeBanner />}
        <HomeBattleSlider />
        <LeaderBoards />
        {!userId && <HomeRegistration />}
        {/* <PopularGenres /> */}
        {/* <ActiveBattle /> */}
        <div className='home-battles-wrapper'>
          <div className='main-wrapper'>
            <div className='main-container'>
              <EndingBattles />
              <JoinBattles />
            </div>
          </div>
        </div>
        <PopularArtist />
        <TrendingBeats />
        {/* <Shop /> */}
        {/* <BestSellers /> */}
        <Chat />
        {!userId ? <RegistrationBottom /> : <RegistrationBottomUser />}
        <BottomBar />
      </div>

    </>
  )
}

export default Home
