// import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';
import { browserPopupRedirectResolver } from "firebase/auth";
import { firebaseConfig } from './config';
import * as cs from '../Api/cs'
import { useDispatch } from 'react-redux';

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const googleprovider = new GoogleAuthProvider();
googleprovider.addScope('email'); // Add email scope for Google login

const facebookprovider = new FacebookAuthProvider();
facebookprovider.setCustomParameters({
  'display': 'popup',
  'scope': 'email'
}); // Add email scope for Facebook login

export const googleLogin = async() => {
  return new Promise((resolve, reject) => {
    signInWithPopup(auth, googleprovider,browserPopupRedirectResolver)
      .then(async(result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        const _data = {
          name : user?.providerData?.[0]?.displayName,
          email: user?.email,
          contactNumber: user?.providerData?.[0]?.phoneNumber,
          image:user?.providerData?.[0]?.photoURL,
          roleType: 2,
          provider: user?.providerData?.[0]?.providerId,
          providerId : user?.uid
        };

        const response = await socialLogin(_data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const facebookLogin = async() => {
  return new Promise((resolve, reject) => {
    signInWithPopup(auth, facebookprovider ,browserPopupRedirectResolver )
      .then(async(result) => {
        // The signed-in user info.
        const user = result.user;
        const _data = {
          name : user?.providerData?.[0]?.displayName,
          email: user?.providerData?.[0]?.email,
          contactNumber: user?.providerData?.[0]?.phoneNumber,
          image:user?.providerData?.[0]?.photoURL,
          roleType: 2,
          provider: user?.providerData?.[0]?.providerId,
          providerId : user?.uid
        };

        const response = await socialLogin(_data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const socialLogin = async(_data) => {
  const response = await cs.post('socialLogin', 'users',_data, null);
  if(response?.data?.code === 200) {
    return response?.data?.data;
  }
};
