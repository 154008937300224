import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Header from "../../Component/Header/Header";
import Sidebar from "../../Component/Sidebar/Sidebar";


import Terms from './Contents/Terms';
import PrivacyPolicy from './Contents/PrivacyPolicy';
import CommunityGuidelines from "./Contents/CommunityGuidelines";

import './index.css'

const LegalPage = props => {

    const { search } = useLocation();

    const query = new URLSearchParams(search);
    const menu = query.get('menu');

    const [activeLegalSection, setActiveLegalSection] = useState('terms_conditions');

    useEffect(() => {
        if (menu) {
            setActiveLegalSection(menu)
        }
    }, []);

    const handleLegalSection = (section) => {
        setActiveLegalSection(section);
    };

    return <>
        <Header />
        <Sidebar />

        <div className='legal-wrapper-main'>
            <div className='legal-banner-wrapper'>
                {/* <img src={genreData?.bannerImage ? genreData?.bannerImage : GenreBannerImg} alt='' /> */}
                <div className='legal-banner'>
                    <div className='legal-banner-content'>
                        <div className='legal-banner-title'>Legal</div>

                    </div>
                </div>
            </div>
            <div className="legal-page-container">
                <div className="legal-page-text-container">

                    <div className="legal-page-links">

                        <div
                            className='legal-info-title-clickable'
                            onClick={() => handleLegalSection('terms_conditions')}
                        >
                            Terms & Conditions
                        </div>
                        <div
                            className='legal-info-title-clickable'
                            onClick={() => handleLegalSection('privacy_policy')}
                        >
                            Privacy Policy
                        </div>
                        <div
                            className='legal-info-title-clickable'
                            onClick={() => handleLegalSection('community_guidelines')}
                        >
                            Community Guidelines
                        </div>
                    </div>
                    {activeLegalSection === 'terms_conditions' && <>
                        <Terms />
                    </>}

                    {activeLegalSection === 'privacy_policy' && (
                        <PrivacyPolicy />
                    )}



                    {activeLegalSection === 'community_guidelines' && (
                        <CommunityGuidelines />
                    )}
                </div>
            </div>
        </div>

    </>

};


export default LegalPage;