import React, { useEffect, useState } from 'react'
import './RegistrationBottom.scss'
import { Button } from 'react-bootstrap'
import Login from '../../Pages/Login/Login';
import SignUp from '../../Pages/SignUp/SignUp';
import OtpModal from '../../Modal/OtpModal';
import EmailLinkForPassword from '../../Pages/EmailLinkForPassword/EmailLinkForPassword';
import SetUpAccount from '../../Modal/SetUpAccount/SetUpAccount';

const RegistrationBottom = () => {
  const [showLogin, setShowLogin] = useState(false);
  const [showModal, setShowModel] = useState(false)
  const [emailLinkModal, setEmailLinkModal] = useState(false)
  const [showSignUp, setShowSignUp] = useState(false)
  const [showSetUp, setShowSetUp] = useState(false)
  const [user, setUser] = useState(null)
  const [id, setId] = useState(null)
  const [token2, setToken2] = useState(null)
  const handleLoginClose = () => {
    setShowLogin(false)
  }
  const handleClose = () => {
    setShowModel(false)
  }
  const handleSignUpClose = () => {
    setShowSignUp(false)
  }

  const handleSetUpClose = () => {
    setShowSetUp(false)
  }
  const handleEmailLinkClose = () => {
    setEmailLinkModal(false)
  }
  return (
    <>
      <div className='register-bottom-section-wrap'>
        <div className='main-wrapper'>
          <div className='main-container'>
            <div className='register-bottom-content-wrap'>
              <div className='register-bottom-title'>DISCOVER AMAZING TALENT</div>
              <div className='register-bottom-description'>
                <p>Whether you’re a music producer, artist, musician, or fan, be at the forefront of innovation and gain access to a world of emerging talent.</p>
              </div>
              <div className='register-bottom-btn-wrap'>
                <Button variant='light' onClick={() =>{setShowLogin(true)}}>Login</Button>
                <Button variant='primary' onClick={() =>{setShowSignUp(true)}}>Get Started</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showLogin && <Login showLogin={showLogin} handleLoginClose={handleLoginClose} setShowLogin={setShowLogin} setShowModel={setShowModel} setEmailLinkModal={setEmailLinkModal} setShowSignUp={setShowSignUp} setUser={setUser} setShowSetUp={setShowSetUp} setId={setId} setToken2={setToken2} />}
      {showSignUp && <SignUp showSignUp={showSignUp} handleSignUpClose={handleSignUpClose} setShowSignUp={setShowSignUp} setShowLogin={setShowLogin} setShowModel={setShowModel} setUser={setUser} user={user} setId={setId} setToken2={setToken2} />}
      {showModal && <OtpModal showModal={showModal} handleClose={handleClose} setShowModel={setShowModel} user={user} setShowLogin={setShowLogin} setShowSetUp={setShowSetUp} id={id} token2={token2} />}
      {emailLinkModal && <EmailLinkForPassword emailLinkModal={emailLinkModal} handleEmailLinkClose={handleEmailLinkClose} setShowLogin={setShowLogin} />}
      {showSetUp && <SetUpAccount showSetUp={showSetUp} handleSetUpClose={handleSetUpClose} />}
    </>
  )
}

export default RegistrationBottom