import React from 'react';
import GenreImg from '../../assets/images/genre-thumbnail.png'
import Genre1 from '../../assets/images/genre-1.png'
import Genre2 from '../../assets/images/genre-2.png'
import Genre3 from '../../assets/images/genre-3.png'
import Genre from '../../assets/images/genre.png'
import './GenreCard.scss'
import UserImg from '../../assets/images/default.png'
import { useNavigate } from 'react-router-dom';

const GenreCard = ({item}) => {
    const navigate = useNavigate()
    const remaining = item?.topWinners.slice(3)
    return (
        <>
            <div className='genre-card'>
                <div className='genre-card-img-wrap cursor-pointer'>
                    <img src={item?.topWinners?.[0]?.genreId?.image ? item?.topWinners?.[0]?.genreId?.image : GenreImg} alt='Genre' onClick={() => navigate(`/genre/${item?.topWinners?.[0]?.genreId?._id}`)} />
                    <div className='genre-title'>{item?.topWinners?.[0]?.genreId?.label}</div>
                </div>
                <div className='top-genre-wrap'>
                    <div className='top-genre'>
                        <div className='top-genre-img-wrap'>
                            <img src={item?.topWinners?.[1]?.userId?.image ? item?.topWinners?.[1]?.userId?.image : UserImg} alt='Genre' />
                        </div>
                        <div className='position-counter'>2</div>
                        <div className='top-genre-name'>{item?.topWinners?.[1]?.userId?.name ? item?.topWinners?.[1]?.userId?.name :
                        item?.topWinners?.[1]?.userId?.userName }</div>
                        <div className='points'>{item?.topWinners?.[1]?.winCount}</div>
                    </div>
                    <div className='top-genre first'>
                        <div className='top-genre-img-wrap'>
                            <img src={item?.topWinners?.[0]?.userId?.image ? item?.topWinners?.[0]?.userId?.image : UserImg} alt='Genre' />
                        </div>
                        <div className='position-counter'>1</div>
                        <div className='top-genre-name'>{item?.topWinners?.[0]?.userId?.name ? item?.topWinners?.[0]?.userId?.name :
                        item?.topWinners?.[0]?.userId?.userName }</div>
                        <div className='points'>{item?.topWinners?.[0]?.winCount}</div>
                    </div>
                    <div className='top-genre'>
                        <div className='top-genre-img-wrap'>
                            <img src={item?.topWinners?.[2]?.userId?.image ? item?.topWinners?.[2]?.userId?.image : UserImg} alt='Genre' />
                        </div>
                        <div className='position-counter'>3</div>
                        <div className='top-genre-name'>{item?.topWinners?.[2]?.userId?.name ? item?.topWinners?.[2]?.userId?.name :
                        item?.topWinners?.[1]?.userId?.userName }</div>
                        <div className='points'>{item?.topWinners?.[2]?.winCount}</div>
                    </div>
                </div>
                <div className='top-genre-list-wrap'>
                    {remaining && remaining?.length > 0 && 
                    remaining.map((lead, index) => (
                        <div className='top-genre-list-row' key={lead?.userId?._id}>
                        <div className='top-genre-count'>{index+4}</div>
                        <div className='top-genre-img'><img src={lead?.userId?.image ? lead?.userId?.image : UserImg} alt='Genre' /></div>
                        <div className='top-genre-name'>{lead?.userId?.name ? lead?.userId?.name : lead?.userId?.userName}</div>
                    </div>
                    ))}
                  
                  
                </div>
            </div>
        </>
    )
}

export default GenreCard