import React from 'react'
import { Modal, Button } from 'react-bootstrap';


const CustomTextModal = ({ showModal, handleClose, title, body }) => {
  return (
    <Modal show={showModal} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className='modal-message-wrapper'>
      <div className='modal-message'>{body}</div>
      <div className='w-100 d-flex justify-content-center'><Button variant="primary" onClick={handleClose}>
        Ok
      </Button></div>
      </div>
    </Modal.Body>

  </Modal>
  )
}

export default CustomTextModal
