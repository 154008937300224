import React, { useEffect } from 'react'
import Sidebar from '../../Component/Sidebar/Sidebar'
import Header from '../../Component/Header/Header'

import GenreBanner from './GenreBanner/GenreBanner'
import GenreBattleSlider from './GenreBattleSlider/GenreBattleSlider'
import NewBattles from '../../Component/Battles/NewBattles'
import EndingBattles from '../../Component/Battles/EndingBattles'
import JoinBattles from '../../Component/Battles/JoinBattles'
import PopularArtist from '../../Component/PopularArtist/PopularArtist'
import TrendingBeats from '../../Component/TrendingBeats/TrendingBeats'
import RegistrationBottom from '../../Component/RegistrationBottom/RegistrationBottom'
import './Genre.scss'
import GenreLeaderBoard from './GenreLeaderBoard/GenreLeaderBoard'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { updateSearch } from '../../Redux/Slices/search.slice'
const Genre = () => {
    // const location = useLocation();
    const { genre } = useParams()
    const { search } = useSelector(state => state.search)
    const dispatch = useDispatch()

    useEffect(() => {
        if (search) {
            dispatch(updateSearch(''))
        }
        scrollToTop()
    }, [])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    return (
        <>
            <Sidebar />
            <Header />
            <div className='genre-wrapper-main'>
                <GenreBanner genre={genre} />
                <GenreBattleSlider genre={genre} />
                <GenreLeaderBoard genre={genre} />
                <div className='genre-content-wrapper'>
                    <div className='main-wrapper'>
                        <div className='main-container'>
                            <div className='genre-wrapper'>
                                <NewBattles genre={genre} />
                                <EndingBattles genre={genre} />
                                <JoinBattles genre={genre} />
                            </div>
                        </div>
                    </div>
                </div>
                <PopularArtist genre={genre} />
                <TrendingBeats genre={genre} />
                <RegistrationBottom />
            </div>
        </>
    )
}

export default Genre