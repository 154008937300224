let rootApiUrl = '';

console.log('process.env.REACT_APP_ENV', process.env.REACT_APP_ENV)
if (process.env.REACT_APP_ENV === 'local') {
    rootApiUrl = 'http://localhost:3001';
}
else {
    rootApiUrl = 'https://beta.cntndr.io';
}

export const API_ROOT_URL = rootApiUrl;
export const API_URL = `${rootApiUrl}/api/v1`;
