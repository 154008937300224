import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, set } from 'react-hook-form';
import { updateProfileSchema } from '../../ValidationSchema/validationSchema';
import ActiveBattleBannerImg from '../../assets/images/active-battle-banner.png'
import './UpdateProfile.scss'
import * as cs from '../../Api/cs'
import useHeader from '../../Helper/useHeader';
import Header from '../../Component/Header/Header';
import Sidebar from '../../Component/Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { bucketUrl } from '../../Helper/Constant';
import UserImg from '../../assets/images/default.png'
import Player from '../../Component/Player/Player';
import { DeleteIcon } from '../../Component/Icons';

const UpdateProfile = () => {

    const headers = useHeader()
    const navigate = useNavigate()
    const { userId } = useSelector(state => state.user)
    const [userData, setUserData] = useState({})
    const [previewImg, setPreviewImg] = useState(false)
    const [image, setImage] = useState('')
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        clearErrors,
    } = useForm({
        resolver: yupResolver(updateProfileSchema),
        mode: 'onChange',
    });


    const onSubmit = async (data) => {

  

        const formObj = new FormData();

      let uploadedImage
        if(data?.profilePicture && data?.profilePicture?.[0])
        {
            const imgFormObj = new FormData()
            imgFormObj.append("data", data?.profilePicture?.[0])
    
            uploadedImage = await cs.post('upload', 'util', imgFormObj, headers)
           
        }

    
    
        formObj.append('name', data?.name);
        formObj.append('contactNumber', data?.contactNumber);
        formObj.append('bio', data?.bio)
        if (data.profilePicture && data.profilePicture[0]) {
            formObj.append('image', uploadedImage?.data?.data);
        }
        else {
            formObj.append('image', image);
        }
        const response = await cs.post('updateProfile', 'users', formObj, headers)   
        if (response?.data?.code === 200) {
            navigate(`/private/profile/${userId}`, { state: { type: "user", id: userId } })
        }

    }


    useEffect(() => {
        getUserData()
    }, [])

    const getUserData = async () => {
        const response = await cs.get('getUserList/' + userId, 'users', {}, headers);
        setPreviewImg(true)
        if (response?.data?.code === 200) {
            setUserData(response?.data?.data)
            if (response?.data?.data) {
                setValue('username', response.data.data.userName);
                setValue('name',response.data.data.name);
                setValue('bio', response.data.data.bio);
                setValue('contactNumber', response.data.data.contactNumber);
                setImage(response?.data?.data?.image)
            }


        }
    }

    const deleteProfile = () => {
        setImage('')
        setValue('profilePicture', '')
    }
    return (
        <>
            <Header />
            <Sidebar />
            <div className='main-container'>
                <div className='auth-form-wrapper-main'>

                    <form className='profile-page-wrapper' onSubmit={handleSubmit(onSubmit)}>
                        <div >
                            <div className='profile-page-banner-wrapper'>
                                <img src={ActiveBattleBannerImg} alt='Banner' />
                            </div>
                            <div className="profile-page-container">
                                <div className='main-wrapper'>
                                    <div className='profile-wrapper'>
                                        <div className='profile-left'>


                                            {/* Here is the code for update profile image*/}
                                            <div className="profile_img">
                                                {!image ?
                                                    <label className="profile-pic" htmlFor="profile-pic">
                                                        <input type='file' id='profile-pic' onChange={(e) => {
                                                            setValue('profilePicture', e.target.files)
                                                            setImage(URL.createObjectURL(e.target.files[0]))
                                                        }} />
                                                        <img src={UserImg} alt='' />
                                                    </label>
                                                    :
                                                    <div className="profile-pic">
                                                        <img src={image} alt='' />
                                                        <div className='delete-img-wrap' onClick={deleteProfile}><DeleteIcon /></div>
                                                    </div>
                                                }


                                                

                                            </div>
                                        </div>
                                    </div>


                                    <div className='auth-form'>


                                        <div className="form-row">
                                            <Form.Group controlId="username">
                                                <Form.Label>Username</Form.Label>
                                                <Form.Control
                                                    type="text"

                                                    placeholder="Enter your username"
                                                    {...register('username', { required: 'Username is required' })}
                                                    disabled
                                                    className="disabled-input redText"
                                                />
                                                {errors.username && <div className="errorMsg">{errors.username.message}</div>}
                                            </Form.Group>
                                        </div>



                                        <div className="form-row">
                                            <Form.Group controlId="name">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter your name"
                                                    {...register('name', { required: 'Name is required' })}
                                                />
                                                {errors.name && <div className="errorMsg">{errors.name.message}</div>}
                                            </Form.Group>
                                        </div>

                                        <div className="form-row">
                                            <Form.Group controlId="bio">
                                                <Form.Label>Bio</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    placeholder="Enter your bio"
                                                    {...register('bio', { maxLength: 255 })}
                                                />
                                                {errors.bio && <div className="errorMsg">{errors.bio.message}</div>}
                                            </Form.Group>
                                        </div>
                                        <div className="form-row">
                                            <Form.Group controlId="contactNumber">
                                                <Form.Label>Contact Number</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter your contact number"
                                                    {...register('contactNumber', { required: 'Contact number is required' })}
                                                />
                                                {errors.contactNumber && <div className="errorMsg">{errors.contactNumber.message}</div>}
                                            </Form.Group>
                                        </div>





                                        <div className="form-action-row mt-2 ">
                                            <Button variant="primary" type="button" onClick={() => {
                                                navigate(`/private/profile/${userId}`, { state: { type: "user", id: userId } })
                                            }}>
                                                Back to Profile
                                            </Button>

                                            <Button variant="primary " className='save-profile-btn'  type="submit">
                                                Save Profile
                                            </Button>
                                        </div>



                                    </div>
                                </div>
                            </div>



                        </div>
                    </form>
                </div>
            </div>


        </>



    )
}

export default UpdateProfile
