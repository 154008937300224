import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import HeadePhoneImg from '../assets/images/headphone.png';
import * as cs from '../Api/cs'
import { toast } from 'react-toastify';
import OtpInput from 'react-otp-input';
import './Otp.scss'
import { useDispatch } from 'react-redux';
import { updateLoginStatus, updateUserId } from '../Redux/Slices/user.slice';
import { BallTriangle } from 'react-loader-spinner';
import useHeader from '../Helper/useHeader';

const OtpModal = ({ showModal, handleClose, setShowModel, user, setShowSetUp, id, token2 }) => {

    const [otp, setOtp] = useState();
    const [errorMsg, setErrorMsg] = useState(false)
    const [loading, setLoading] = useState(false);
    const [resendStatus, setResendStatus] = useState(false)
    const headers = useHeader()

    const navigate = useNavigate()
    const dispatch = useDispatch()
  
    const handleOtpSubmit = async () => {
        
        setLoading(true)
        const _data = {
            "email": user?.email,
            "otp": otp
        }
        const res = await cs.post('otp', 'users', _data, null)
        if (res?.data?.code == 200) {
            setLoading(false)
            setShowModel(false)
            localStorage.setItem("token", token2)
            dispatch(updateLoginStatus(true))
            dispatch(updateUserId(id))
            setShowSetUp(true)
            navigate('/')

        }
        else {
            setLoading(false)
            setErrorMsg(true)
        }

    }

    const handleInputChange = (e) => {
        setOtp(e.target.value)
    }

    const resendCode = async() =>
    {
        setResendStatus(true)
        const _data ={
            emailOrUsername :  user?.email
        }
        const response = await cs.post('resend-otp','users',_data ,headers)
   
    }

    return (
        <Modal show={showModal} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>

                <div className='otp-header-wrap'>
                    {errorMsg && <div style={{ color: "red", textAlign: "center" }}>Invalid Code</div>}
                    <div className='otp-img-wrap'><img src={HeadePhoneImg} alt='' /></div>
                    <Modal.Title>Enter Confirmation<br /> Code</Modal.Title>
                    <div className='otp-header-subtitle'>Enter the code sent to your email</div>
                </div>

            </Modal.Header>
            <Modal.Body>



                {loading && ( // Show loader if loading state is true
                    <div className="loader-container">
                        <div className="loader">
                            <BallTriangle
                                type="TailSpin"
                                color="#00BFFF"
                                height={50}
                                width={50}
                            />
                        </div>
                    </div>
                )}
                <div className='otp-container-wrap'>
                    <OtpInput
                        containerStyle="otp-container"
                        value={otp}
                        onChange={(otp) => setOtp(otp)}
                        numInputs={4}
                        inputStyle="form-control"
                        renderInput={(props) => <input  {...props} />}
                    />
                    {resendStatus &&<div className='resend-otp-wrap'>Code send to your email address</div> }
                    <div className='resend-otp-wrap'>Didn’t receive the code? <span onClick={resendCode}>Resend code</span></div>
                    <div className='otp-button-wrap'>
                        <Button variant="primary" onClick={handleOtpSubmit}>
                            Submit
                        </Button>
                    </div>
                </div>

            </Modal.Body>
            {/* <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleOtpSubmit}>
                    Submit OTP
                </Button>
            </Modal.Footer> */}
        </Modal>
    )
}

export default OtpModal
