import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activeMenu : "home",
  activeSideBarMenu : ""
}

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
   updateActiveMenu : (state, action) => {
    state.activeMenu = action.payload;
   },
   updateactiveSideBarMenu : (state, action) => {
    state.activeSideBarMenu = action.payload;
   }
  },
})

export const {updateActiveMenu, updateactiveSideBarMenu} = sidebarSlice.actions

export default sidebarSlice.reducer