import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import 'swiper/css';
import './LeaderBoards.scss'
import LeaderBoardCard from '../../../Component/LeaderBoardCard/LeaderBoardCard';
import { NextArrow, PreviousArrow } from '../../../Component/Icons';
import GenreCard from '../../../Component/GenreCard/GenreCard';
import useHeader from '../../../Helper/useHeader';
import * as cs from '../../../Api/cs'
import { useDispatch } from 'react-redux';
import { updateShowChat } from '../../../Redux/Slices/chat.slice';
import { updateWalletShow } from '../../../Redux/Slices/modal.slice';
import { updateIsBadgeInfo, updateOpenDirectly } from '../../../Redux/Slices/badge.slice';

const LeaderBoards = () => {
    const [leaderboard, setLeaderBoard] = useState([])
    const [leaderSlider, setLeaderSlider] = useState(false)
    const headers = useHeader()
    const dispatch = useDispatch()

    useEffect(() => {
        getLeaderBoard()
    }, [])


    const getLeaderBoard = async () => {
        try {
            const response = await cs.get('allTopWinner', 'leaderboard', null, headers);
            console.log('leaderboard response', response)
            const leaderboardData = response?.data?.data;
            if (leaderboardData && Array.isArray(leaderboardData)) {
                setLeaderBoard(leaderboardData.slice(0, 8));
            } else {
                setLeaderBoard([]);
            }
        } catch (error) {
            console.error("Error fetching leaderboard data:", error);
            setLeaderBoard([]);
        }
    }


    const prevRef = useRef(null);
    const nextRef = useRef(null);
    return (
        <>
            {leaderboard && leaderboard.length > 0 &&
                <div className='leaderboard-wrapper'>
                    <div className='main-wrapper'>
                        <div className='main-container'>
                            <div className='lb-title-wrap'>
                                <div className='lb-title'>Popular Genres</div>
                            </div>
                            <div className='lb-slider-btn-wrap'>
                                <button className='btn' ref={prevRef}><PreviousArrow /></button>
                                <button className='btn' ref={nextRef}><NextArrow /></button>
                            </div>

                            <div className='lb-slider-wrapper'>
                                <Swiper
                                    spaceBetween={20}
                                    slidesPerView={4}
                                    modules={[Navigation]}
                                    navigation={{
                                        prevEl: prevRef.current,
                                        nextEl: nextRef.current,
                                    }}
                                    breakpoints={{
                                        1400: {
                                            slidesPerView: 4
                                        },
                                        991: {
                                            slidesPerView: 3
                                        },
                                        700: {
                                            slidesPerView: 2
                                        },
                                        300: {
                                            slidesPerView: 1.2
                                        }
                                    }}
                                    onInit={() => setLeaderSlider(true)}
                                >
                                    {leaderboard && leaderboard?.length > 0 ?
                                        leaderboard.map((item) => (

                                            <SwiperSlide key={item?.topWinners?.[0]?.genreId?._id} onClick={() => { dispatch(updateShowChat(false)); dispatch(updateWalletShow(false)); dispatch(updateIsBadgeInfo(false)); dispatch(updateOpenDirectly(false)) }} ><GenreCard item={item} /></SwiperSlide>
                                        ))
                                        : <div>No Record Available!!</div>}

                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default LeaderBoards;