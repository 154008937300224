import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import Header from '../../Component/Header/Header'
import Sidebar from '../../Component/Sidebar/Sidebar'
import TrackPlayer from '../../Component/TrackPlayer/TrackPlayer'
import ThumbnailImg from '../../assets/images/thumbnail.png'
import * as cs from '../../Api/cs'
import useHeader from '../../Helper/useHeader'
import HomeBattleSlider from '../Home/HomeBattleSlider/HomeBattleSlider'
import { useDispatch, useSelector } from 'react-redux'
import BattleCard from '../../Component/BattelCard/BattleCard'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './Search.scss'
import Artist from '../../Component/Artist/Artist'
import { updateSearch, updateSearchBy, updateSearchStatus, updateTag, updateTagSearch } from '../../Redux/Slices/search.slice'
import { useNavigate } from 'react-router-dom'
import { updateBattleId } from '../../Redux/Slices/battle.slice'
import Player from '../../Component/Player/Player'
import { updateIsPlaying, updateTrack, updateTrackList } from '../../Redux/Slices/player.slice'
import { BallTriangle } from "react-loader-spinner";

const Search = () => {

    const { showPlayer, track, artistId, isPlaying } = useSelector(state => state?.player)



    const { userId } = useSelector(state => state.user);


    const dispatch = useDispatch()
    const navigate = useNavigate()
    const headers = useHeader()
    // const { userId } = useSelector(state => state?.user)
    const [searchByType, setSearchByType] = useState('all')
    const [trackList, setTrackList] = useState([])
    const [artistList, setArtistList] = useState([])
    const [battleList, setBattleList] = useState([])
    const [sort, setSort] = useState('dsc')
    const [filter, setFilter] = useState('abcd')
    const [activeButton, setActiveButton] = useState('all');
    const [topResult, setTopResult] = useState({})
    const [topResultType, setTopResultType] = useState('')
    // const [showPlayer, setShowPlayer] = useState(false)
    // const [artistId, setArtistId] = useState()
    // const [track, setTrack] = useState()
    const [popularArtist, setPopularArtist] = useState({})
    const [loading, setLoading] = useState(false);



    const handleSearchBy = async (data) => {
        setSearchByType(data)
        dispatch(updateSearchBy(data))
        setActiveButton(data);
    }

    // const { search } = useSelector(state => state?.search)

    const { searchStatus, searchBy, search, tag, tagSearch } = useSelector(state => state?.search)



    useEffect(() => {
        setActiveButton(searchBy)
    }, [])

    useEffect(() => {
        if (searchStatus == "true") {

            searchData()
        }

    }, [searchStatus, activeButton])

    useEffect(() => {
        if (search == '' && tag == '') {

            getArtistList()
            getBattleList()
            getTrackList()
        }
    }, [search])

    useEffect(() => {
        if (tagSearch === true && tag.trim != '') {
            tagSearchData()
        }


    }, [tag, tagSearch, activeButton])


    const searchData = async () => {
        setLoading(true);
        try {
            const response = await cs.get('search/' + searchBy + "/" + search, 'battel', {}, headers)

            setTopResultType(response?.data?.data?.topResultType)
            setTopResult(response?.data?.data?.topResultData)

            switch (searchBy) {
                case 'all':
                    setArtistList(response?.data?.data?.result?.artists)
                    setBattleList(response?.data?.data?.result?.battles)
                    setTrackList(response?.data?.data?.result?.tracks)
                    // dispatch(updateTrackList(response?.data?.data?.result?.tracks))
                    break;
                case 'artist':
                    setArtistList(response?.data?.data?.result)
                    break;
                case 'battle':
                    setBattleList(response?.data?.data?.result)
                    break;
                case 'track':
                    setTrackList(response?.data?.data?.result)
                    // dispatch(updateTrackList(response?.data?.data?.result))
                    break;
            }
        } finally {
            setLoading(false);
        }
    }

    const tagSearchData = async () => {
        setLoading(true);
        try {
            const response = await cs.get('searchFortag/' + tag, 'battel', {}, headers)
            setTopResultType(response?.data?.data?.topResultType)
            setTopResult(response?.data?.data?.topResultData)

            switch (searchBy) {
                case 'all':
                    setArtistList([])
                    setBattleList(response?.data?.data?.result?.battles)
                    setTrackList(response?.data?.data?.result?.tracks)
                    // dispatch(updateTrackList(response?.data?.data?.result?.tracks))
                    break;
                case 'artist':
                    setArtistList([])
                    setTopResultType('')
                    setTopResult({})
                    break;
                case 'battle':
                    setBattleList(response?.data?.data?.result?.battles)
                    break;
                case 'track':
                    setTrackList(response?.data?.data?.result?.tracks)
                    // dispatch(updateTrackList(response?.data?.data?.result))
                    break;
            }
            dispatch(updateTag(''))
            dispatch(updateTagSearch(false))
        } finally {
            setLoading(false);
        }
    }


    const getTrackList = async () => {
        setLoading(true);
        try {
            const response = await cs.get('list/' + 1, 'track', {}, headers)
            if (response?.data?.code === 200) {
                setPopularArtist(response?.data?.data?.[0]?.createdBy)
                setTrackList(response?.data?.data)
            }
        } finally {
            setLoading(false);
        }
    }
    const getBattleList = async () => {
        setLoading(true);
        try {
            const response = await cs.get('list/' + sort + "/" + filter, 'battel', {}, headers)
            if (response?.data?.code === 200) {
                setBattleList(response?.data?.data)
            }
        } finally {
            setLoading(false);
        }
    }


    const getArtistList = async () => {
        setLoading(true);
        try {
            const response = await cs.get('listing', 'users', null, headers)
            if (response?.data?.code === 200) {
                setArtistList(response?.data?.data)
            }
        } finally {
            setLoading(false);
        }
    }

    const handleBattleCard = (item) => {
        navigate(`/battle/${item}`, { state: { itemData: item, battles: battleList } })
        dispatch(updateBattleId(item))
    }




    const handleTrackPlayer = (item) => {
        dispatch(updateIsPlaying(true))
        dispatch(updateTrack(item))

    }


    return (
        <>

            <Header />
            <Sidebar />

            <div className='serach-main-wrapper'>

                <div className='main-wrapper'>
                    <div className='search-wrapper'>

                        <div className='main-container'>
                            <div className='search-top-wrap'>
                                <div className='search-btn-wrapper'>

                                    <Button variant={activeButton === 'all' ? 'primary' : ''} onClick={() => { handleSearchBy('all') }}>All</Button>
                                    <Button variant={activeButton === 'artist' ? 'primary' : ''} onClick={() => { handleSearchBy('artist') }}>Artists</Button>
                                    <Button variant={activeButton === 'battle' ? 'primary' : ''} onClick={() => { handleSearchBy('battle') }}>Battles</Button>
                                    <Button variant={activeButton === 'track' ? 'primary' : ''} onClick={() => { handleSearchBy('track') }}>Tracks</Button>
                                </div>
                                <div className="top-track-wrapper">
                                    {topResult && topResultType &&
                                        <div className="track-result-wrapper">
                                            <div className="track-result-title">Top Result</div>
                                            <div className='track-result-wrap'>
                                                <div className='top-result'>
                                                    {topResultType === 'track' && topResult && (searchBy === 'all' || searchBy === 'track') &&
                                                        <TrackPlayer trackToBePlayed={topResult} isPlayingSong={track?._id === trackList?.[0]?._id && isPlaying ? true : false} />}
                                                    {topResultType === 'battle' && topResult && (searchBy === 'all' || searchBy === 'battle') &&
                                                        <div onClick={() => { handleBattleCard(topResult?._id) }}><BattleCard battleId={topResult?._id} /></div>}
                                                    {topResultType === 'artist' && topResult && (searchBy === 'all' || searchBy === 'artist') &&
                                                        <div onClick={() => { navigate(`/profile/${topResult?._id}`, { state: { type: "user", id: topResult?._id } }) }}> <Artist artist={topResult} /></div>}

                                                </div>

                                            </div>
                                        </div>
                                    }
                                    {(searchBy === 'all' || searchBy === 'track') && (
                                        <div className="track-list-wrapper">
                                            <div className='track-list-title'>Tracks</div>
                                            <div className='track-list'>
                                                {!loading && (trackList && trackList.length > 0 ? (
                                                    trackList.map((item) => (
                                                        <TrackPlayer key={item?._id} trackToBePlayed={item} isPlayingSong={track?._id === item?._id && isPlaying ? true : false} list={trackList} />
                                                    ))
                                                ) : (
                                                    <div style={{ color: "white" }}>No Tracks Available</div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {(searchBy === 'all' || searchBy === 'battle') && (
                            <div className='top-battle-wrapper'>
                                <div className='top-battle-title'>Battles</div>
                                <div className='top-battle-slider'>
                                    {!loading && (battleList && battleList.length > 0 ? (
                                        <Swiper
                                            spaceBetween={20}
                                            breakpoints={{
                                                1500: { slidesPerView: 3.9 },
                                                1250: { slidesPerView: 2.9 },
                                                768: { slidesPerView: 1.9 },
                                                300: { enabled: false }
                                            }}
                                        >
                                            {battleList.map((item) => (
                                                <SwiperSlide key={item?._id} onClick={() => { handleBattleCard(item?._id) }}>
                                                    <BattleCard battleId={item?._id} type={item?.battleOpponentStatus ? null : 'awaiting'} />
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    ) : (
                                        <div style={{ color: "white" }}>No Tracks Available</div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {(searchBy === 'all' || searchBy === 'artist') && (
                            <div className='main-container'>
                                <div className='top-artist-wrapper'>
                                    <div className='top-artist-title'>Artists</div>
                                    <div className='artists-slider-wrap'>
                                        {!loading && (artistList && artistList.length > 0 ? (
                                            <Swiper
                                                spaceBetween={24}
                                                slidesPerView={4}
                                                breakpoints={{
                                                    1600: { slidesPerView: 5, spaceBetween: 24 },
                                                    1400: { slidesPerView: 4, spaceBetween: 24 },
                                                    768: { slidesPerView: 3, spaceBetween: 24 },
                                                    300: { enabled: false, spaceBetween: 0 }
                                                }}
                                            >
                                                {artistList.map((item) => (
                                                    userId !== item._id && (
                                                        <SwiperSlide key={item?._id} onClick={() => { navigate(`/profile/${item?._id}`, { state: { type: "user", id: item?._id } }) }}>
                                                            <Artist artist={item} />
                                                        </SwiperSlide>
                                                    )
                                                ))}
                                            </Swiper>
                                        ) : (
                                            <div style={{ color: "white" }}>No Tracks Available</div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {loading && (
                <div className="loader-container">
                    <div className="loader">
                        <BallTriangle color="#00BFFF" height={50} width={50} />
                    </div>
                </div>
            )}

            {/* {showPlayer && <Player />} */}
        </>
    )
}

export default Search
