import React, { useEffect, useState } from 'react'
import './battles.scss'
import { Button } from 'react-bootstrap'
import BattleCard from '../BattelCard/BattleCard'
import { ViewAllIcon } from '../Icons'
import useHeader from '../../Helper/useHeader'
import * as cs from "../../Api/cs"
import { useDispatch } from 'react-redux'
import { updateBattleId } from '../../Redux/Slices/battle.slice'
import { useLocation, useNavigate } from 'react-router-dom'

const NewBattles = ({ genre = null }) => {
    const [newBattle, setNewBattle] = useState([])
    const headers = useHeader()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (genre == null) {
            getNewBattle()

        }
        else if (genre !== null) {
            getNewBattleOfGenre()
        }
    }, [])

    const getNewBattle = async () => {
        const response = await cs.get('all/' + 'new', 'battel', {}, headers)
        setNewBattle(response?.data?.data.slice(0, 10))
    }

    const getNewBattleOfGenre = async () => {
        const response = await cs.get('getNewOrEndingBattleByGenre/' + genre + '/' + "new", "battel", {}, headers)
        setNewBattle(response?.data?.data.slice(0, 10))
    }

    const handleBattleCard = (item) => {
        dispatch(updateBattleId(item))
        navigate(`/battle/${item}`, { state: { itemData: item, battles: newBattle } })
    }
    return (
        <>
            {newBattle && newBattle.length > 0 &&
                <div className='battles-container '>
                    <div className='battles-title-wrapper'>
                        <div className='battles-title'>New Battles</div>
                        <div className='btn-wrap' onClick={() => {
                            navigate(`/genre/${genre ? genre : 'all'}/new-battle`, {
                                state: {
                                    type: "battle",
                                    title: "New Battles",
                                    ...(genre !== null && { genre })
                                }
                            });
                        }}><Button variant='secondary'>View all</Button></div>
                    </div>
                    <div className='battles-content-wrap'>
                        {newBattle && newBattle?.length > 0 ?
                            newBattle.map((item) => (
                                <div key={item?._id} onClick={() => { handleBattleCard(item?._id) }}>
                                    <BattleCard battleId={item?._id} type={item?.battleOpponentStatus ? null : 'awaiting'} />
                                </div>
                            ))
                            :
                            <div style={{ color: "white" }}>No New Battle</div>
                        }

                        {/* <BattleCard />
                    <BattleCard />
                    <BattleCard />
                    <BattleCard />
                    <BattleCard /> */}
                    </div>
                    <div className='viewall-btn-wrap' onClick={() => {
                        navigate(`/genre/${genre ? genre : 'all'}/new-battle`, {
                            state: {
                                type: "battle",
                                title: "New Battles",
                                ...(genre !== null && { genre })
                            }
                        });
                    }}><Button variant='secondary'><ViewAllIcon /> View all</Button></div>
                </div>
            }
        </>
    )
}

export default NewBattles