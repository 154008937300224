import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  battleId : null,
  newBattle : 0
}

export const battleSlice = createSlice({
  name: 'battle',
  initialState,
  reducers: {
   updateBattleId : (state, action) => {
    state.battleId= action.payload;
   },
   updateNewBattle : (state,action) =>
   {
     state.newBattle = action.payload
   }
  },
})

export const {updateBattleId,updateNewBattle} = battleSlice.actions

export default battleSlice.reducer