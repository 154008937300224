import React, { useEffect } from 'react'
import Header from "../../Component/Header/Header";
import Sidebar from "../../Component/Sidebar/Sidebar";

import './PageNotFound.css';

import { updateDocumentTitle } from '../../Utils';

const PageNotFound = () => {

  useEffect(() => {
    updateDocumentTitle('Page Not Found | CNTNDR');
    return () => {
      updateDocumentTitle('');
    }
  })
  return (<>
    <Header />
    <Sidebar />
    <div className="page-not-found">
      <h1>404</h1>
      <p>Page Not Found</p>
      <a href="/" className="btn btn-primary">Go Home</a>
    </div>
  </>
  )
}

export default PageNotFound
