import React, {useState } from 'react'
import './RegisterBottomUser.scss'
import { Button } from 'react-bootstrap'
import {useNavigate } from 'react-router-dom';
import StartBattleModal from '../../Modal/StartBattleModal/StartBattleModal'
import {useSelector } from 'react-redux';



const RegistrationBottomUser = () => {

  const [showLogin, setShowLogin] = useState(false);
  const [showModal, setShowModal] = useState(false)
  const [showOpenBattleModal, setShowOpenBattleModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const [trackInfo, setTrackInfo] = useState('');
  const [battleOption, setBattleOption] = useState('')
  const [addToPlaylist, setAddToPlaylist] = useState(false);

  const navigate = useNavigate();
  const { userId } = useSelector(state => state.user)


  const handleStartBattle = () => {
    if (userId == null || userId == undefined) {
      // setModalTitle("Can't Create Battle!!!")
      // setModalBody(`Please Login First`)
      // setCustomModal(true)
      setShowLogin(true)
    }
    else if (userId != null) {

      setShowModal(true)
    }

  }
 
  return (
    <>
      <div className='register-bottom-section-wrap'>
        <div className='main-wrapper'>
          <div className='main-container'>
            <div className='register-bottom-content-wrap'>
              <div className='register-bottom-title'>WAYS TO EARN MORE CLOUT</div>
              <div className='register-bottom-description'>
                <p>As a Contender, you can earn Clout in a number of ways! Get rewarded with Clout every time you vote on a battle. You can also add Clout to your wallet by competing and winning battles!</p>
              </div>
              <div className='register-bottom-btn-wrap'>
                <Button variant='light' onClick={()=>{navigate('/active-battle')}}>Go Vote</Button>
                <Button variant='primary' onClick={handleStartBattle}>Start Battle</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && <StartBattleModal showModal={showModal} handleClose={handleClose} setShowModal={setShowModal} setShowOpenBattleModal={setShowOpenBattleModal} setTrackInfo={setTrackInfo} setBattleOption={setBattleOption} setAddToPlaylist={setAddToPlaylist} addToPlaylist={addToPlaylist} />}

    </>
  )
}

export default RegistrationBottomUser