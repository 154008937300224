import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import Player from '../../Component/Player/Player'

const WrapLayout = () => {
    const { showPlayer } = useSelector(state => state.player)
    // const { userId } = useSelector(state => state.user)



    return (
        <>
            <Outlet />
            {showPlayer && <Player />}

        </>
    )

}

export default WrapLayout


