import React from 'react';

const CommunityGuidelines = () => {
    return <div className='legal-info-content'>
        <h5 className='legal-page-title'>COMMUNITY GUIDELINES</h5>
        <div className="legal-page-text">
            <p>Thanks for being a member of the CNTNDR community! Our goal is to foster a positive, inclusive, and safe environment for all members. By participating in our community, you agree to adhere to these guidelines.</p>
            <ol>
                <li><strong>Respect and Kindness</strong>
                    <ol>
                        <li>Treat Everyone with Respect: Be kind and considerate. Harassment, bullying, and hate speech are not tolerated. Celebrate diversity and be mindful of different perspectives.</li>
                        <li>No Discrimination: Discrimination based on race, ethnicity, nationality, gender, sexual orientation, age, disability, or any other characteristic is strictly prohibited.</li>
                    </ol>
                </li>
                <li><strong>Integrity and Authenticity</strong>
                    <ol>
                        <li>Be Honest: Misleading or deceptive behavior, including impersonation and spreading false information, is not allowed. Present yourself truthfully.</li>
                        <li>Respect Intellectual Property: Only share content you have created or have permission to use. Plagiarism and unauthorized use of copyrighted materials are forbidden.</li>
                    </ol>
                </li>
                <li><strong>Privacy and Safety</strong>
                    <ol>
                        <li>Protect Personal Information: Do not share personal information about yourself or others without consent. This includes addresses, phone numbers, and other sensitive details.</li>
                        <li>Safe Environment: Do not post content that promotes violence, self-harm, or illegal activities. Encourage a safe space for everyone.</li>
                    </ol>
                </li>
                <li><strong>Positive Engagement</strong>
                    <ol>
                        <li>Constructive Feedback: Offer feedback that is constructive and aimed at helping others improve. Avoid negative or harmful criticism.</li>
                        <li>Support Each Other: Encourage and support fellow community members. Celebrate their successes and offer help when needed.</li>
                    </ol>
                </li>
                <li><strong>Compliance and Reporting</strong>
                    <ol>
                        <li>Follow the Law: Adhere to all applicable laws and regulations. Illegal content or activities are not permitted.</li>
                        <li>Report Violations: If you see something that violates these guidelines, report it to our moderation team. We are committed to addressing all concerns promptly.</li>
                    </ol>
                </li>
                <li><strong>Enforcement and Consequences</strong>
                    <ol>
                        <li>Accountability: Violations of these guidelines may result in warnings, suspension, or permanent removal from the community, depending on the severity of the offense.</li>
                        <li>Community Moderation: Our moderators work to maintain a safe and positive environment. Please cooperate with them and respect their decisions.</li>
                    </ol>
                </li>
            </ol>
            <p>By following these guidelines, we can ensure CNTNDR remains a welcoming and enjoyable community for everyone. Thank you for being a part of the journey!</p>

        </div>
    </div>;
};

export default CommunityGuidelines;