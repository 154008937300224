import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "./GenreBattleSlider.scss";
import {
  NextArrow,
  PreviousArrow,
  ViewAllIcon,
} from "../../../Component/Icons";
import BattleCard from "../../../Component/BattelCard/BattleCard";
import { Button } from "react-bootstrap";
import * as cs from "../../../Api/cs";
import useHeader from "../../../Helper/useHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateBattleId } from "../../../Redux/Slices/battle.slice";
const GenreBattleSlider = ({ genre }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const headers = useHeader();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [battleList, setBattleList] = useState([]);

  useEffect(() => {
    getTrendingBattlesOfGenre();
  }, []);

  const getTrendingBattlesOfGenre = async () => {
    const response = await cs.get(
      "trendingBattles/" + genre,
      "genre",
      {},
      headers
    );

    setBattleList(response?.data?.data);
  };

  const handleBattleCard = (item) => {
    navigate(`/battle/${item}`, {
      state: { itemData: item, battles: battleList },
    });
    dispatch(updateBattleId(item));
  };
  return (
    <>
      {battleList && battleList.length > 0 && <div className="genre-slider-wrapper">
        <div className="main-wrapper">
          <div className="main-container">
            <div className="genre-slider-btn-wrap">
              <button className="btn" ref={prevRef}>
                <PreviousArrow />
              </button>
              <button className="btn" ref={nextRef}>
                <NextArrow />
              </button>
            </div>
          </div>
          <div className="genre-slider">
            <Swiper
              spaceBetween={20}
              modules={[Navigation]}
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
              }}
              breakpoints={{
                1500: {
                  slidesPerView: 3.9,
                },
                1250: {
                  slidesPerView: 2.9,
                },
                768: {
                  slidesPerView: 1.9,
                },
                300: {
                  enabled: false,
                },
              }}
            >
              {battleList &&
                battleList?.length > 0 &&
                battleList.map((item) => (
                  <SwiperSlide
                    onClick={() => {
                      handleBattleCard(item?.battleData?._id);
                    }}
                  >
                    <BattleCard
                      battleId={item?.battleData?._id}
                      type={
                        item?.battleData?.battleOpponentStatus
                          ? null
                          : "awaiting"
                      }
                    />
                  </SwiperSlide>
                ))}
            </Swiper>

            <div
              className="viewall-btn-wrap"
              onClick={() => {
                navigate("/battle/all/Recommended", {
                  state: {
                    type: "trendingBattle",
                    title: "Trending Battle",
                    ...(genre !== null && { genre })
                  },
                });
              }}
            >
              <Button variant="secondary">
                <ViewAllIcon /> View all
              </Button>
            </div>
          </div>
        </div>
      </div>
      }
    </>
  );
};

export default GenreBattleSlider;
