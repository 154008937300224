import React from 'react';


const Terms = () => {
    return <>
        <h5 className="legal-page-title">TERMS & CONDITIONS</h5>
        <div className="legal-page-text">
            <p>Welcome to CNTNDR! The Terms and Conditions (“Terms”) govern the CNTNDR website, mobile and desktop apps, and any related players, widgets, tools, applications, data, software, APIs and platforms (referred to as the “Platform”), which are made available by CNTNDR, Inc. (“CNTNDR”, “we”, “our” or “us”). By using the Platform, you acknowledge and accept the Platform’s Terms and Privacy Policy and consent to the collection and use of your data in accordance with our Privacy Policy</p>
            <p>We hope you’ll enjoy being a part of our community by participating in online music battles, challenges, competitions, and tournaments and other services (collectively, “Battle” or “Battles”) we make available through our Platform. Please read these Terms & Conditions carefully.</p>
            <div className='legal-info-title'>1. Content, Ownership, and Limited License</div>
            <div className='legal-info-content'>

                <p>Content. The Platform contains a variety of: <br></br>(i) materials and other items relating to CNTNDR, and its products and services, and similar items from our licensors and other third parties, including all layout, information, articles, posts, text, data, files, images, scripts, designs, graphics, button icons, instructions, illustrations, photographs, audio clips, music, sounds, pictures, videos, advertising copy, URLs, technology, software, interactive features, the “look and feel” of the Service, and the compilation, assembly, and arrangement of the materials of the Service and any and all copyrightable material (including source and object code); <br></br>(ii) trademarks, trade dress, logos, trade names, service marks, and/or trade identities of various parties, including those of CNTNDR (collectively, “Trademarks”); <br></br> (iii) other forms of intellectual property (all of the foregoing but excluding User Content (defined below), collectively, “Content”). The Content of the Platform is protected by copyright, trademark, and other intellectual property laws of the United States. </p>
                <p>CNTNDR shall own trademarks, registered and unregistered. The CNTNDR logos and variations thereof found on the Platform are trademarks owned by CNTNDR or its related entities and all use of these marks inures to the benefit of CNTNDR.</p>
                <p>Other marks on the Platform not owned by CNTNDR may be under license from the trademark owner thereof, in which case such license is for the exclusive benefit and use of CNTNDR unless otherwise stated, or may be the property of their respective owners. You may not use CNTNDR's name, logos, trademarks or brands, or trademarks or brands of others on the Platform without CNTNDR's express permission.</p>
                <p>User Content. The Platform provides registered users with opportunities to upload files, images, photographs, graphical designs, video, sound recordings, musical works, original music, images, videos, graphics, as well as post reviews, opinions, advice, ratings, discussions, comments, messages, survey responses, and other communications, and any other content or material submitted or posted to the Platform (collectively, “User Content”).</p>
                <p>If you contribute any User Content, you will not upload, post, or otherwise make available on the Platform any material protected by copyright, trademark, or other proprietary right without the express permission of the owner of the copyright, trademark, or other proprietary right. CNTNDR does not have any express burden or responsibility to provide you with indications, markings, or anything else that may aid you in determining whether the material in question is copyrighted or trademarked. You will be solely liable for any damage resulting from any infringement of copyrights, trademarks, proprietary rights, or any other harm resulting from such a submission. In addition, if you contribute any User Content, you represent and warrant that: (a) you are the creator of the User Content; or (b) if you are acting on behalf of the creator, that you have (i) express, advance authority from the creator to submit or post the User Content, and that they have waived any moral rights in such User Content, and (ii) all rights necessary to grant the licenses and grants in these Terms.</p>
                <p>You further represent and warrant (or, if you are acting on behalf of the creator of the User Content, you have ensured that the creator represents and warrants) that the use and sharing of the User Content for the purposes you have selected will not violate or infringe any copyrights, trademarks, or any other intellectual property rights or rights of third parties, including, without limitation, the rights of publicity or privacy. You represent and warrant that you will not upload, post, transmit, or otherwise make available User Content that is unlawful, harmful, tortious, threatening, abusive, harassing, hateful, racist, infringing, pornographic, obscene, violent, misleading, defamatory or libelous, invasive of the privacy of another person, or violative of any third-party rights; and that you will not upload, post, transmit, or otherwise make available User Content that contains any material that harbors viruses or any other computer codes, files, or programs designed to intercept, misappropriate, interrupt, destroy or limit the functionality of any software or computer equipment.</p>
                <p>CNTNDR shall have the sole and absolute right, but not the obligation, to review, edit, post, refuse to post, remove, monitor the User Content, and disclose the User Content and the circumstances surrounding its transmission to any third-party, at any time, for any reason, including, without limitation, to determine compliance with these Terms and any operating rules established by CNTNDR, as well as to satisfy any applicable law, regulation, or authorized government request. Without limiting the foregoing, CNTNDR shall have the right to remove any material from the Platform, or any other CNTNDR website, in its sole discretion. CNTNDR assumes no liability for any User Content or other information that appears or is removed from the Platform or elsewhere. CNTNDR has no obligation to use User Content and may not use it at all.</p>
                <p>In some instances and from time to time, it may be possible to modify or remove the User Content submitted or posted through your account. CNTNDR makes no representations or warranties that the User Content you modify or remove will be modified or removed from the Platform or elsewhere, or that the User Content will cease to appear on the internet, in search engines, social media websites, or in any other form, media or technology.</p>
                <p>Public Nature of Your User Content. You understand and agree that User Content is public. Any person (whether or not a user of CNTNDR’s Platform) may view your User Content without your knowledge. Please do not include any personally identifiable information in your User Content unless you wish for it to be publicly disclosed. CNTNDR is not responsible for the use or disclosure of any personally identifiable information that you disclose in connection with User Content.</p>
                <p>Any User Content of any kind made by you or any third-party is made by the respective author(s) or distributor(s) and not by CNTNDR. Other users may post User Content that is inaccurate, misleading, or deceptive. CNTNDR does not endorse and is not responsible for any User Content, and will not be liable for any loss or damage caused by your reliance on such User Content. User Content reflects the opinions of the person submitting it and may not reflect the opinion of CNTNDR. CNTNDR does not control or endorse any User Content, and specifically disclaims any liability concerning or relating to your contribution of, use of, or reliance on any User Content and any actions resulting from your participation in any part of the Platform, including, without limitation, any objectionable User Content.</p>
                <p>License Grants. Some User Content you submit to CNTNDR may be displayed or may give you the option to display in connection with your personally identifiable information, or a portion of your personally identifiable information, including, without limitation, your name, artist name, initials, username, social networking website user account name, image, likeness, preferences, voice, and location. You grant CNTNDR a royalty-free, perpetual, irrevocable, sublicensable, fully paid-up, non-exclusive, transferrable, worldwide license and right to use, commercial use, display and distribute any personally identifiable information in connection with your User Content in accordance with these Terms, including, without limitation, a right to offer for sale and to sell such rights in personally identifiable information to the extent permitted under our Privacy Policy, whether the User Content appears alone or as part of other works, and in any form, media or technology, whether now known or hereinafter developed, and to sublicense such rights through multiple tiers of sublicensees, all without compensation to you. However, CNTNDR shall have no obligation to use your personally identifiable information in connection with any User Content.</p>
                <p>As between you and CNTNDR, you shall retain all ownership rights in and to the User Content you submit or post. However, by contributing User Content or other information on or through the Platform, you grant CNTNDR a license, for so long as your choose to host the applicable User Content, to:</p>
                <p><strong>a) </strong> Host, reproduce, distribute, make available for download and/or perform (publicly or otherwise) all sound recordings, musical works, and original music (“Music Content”) on the Platform, including full-length versions of sound recordings (“Clips”) that can be used for promotional purposes as authorized herein and, if necessary, reproducing User Content in new physical product;</p><br></br>
                <p><strong>b) </strong>Publicly perform, publicly display, communicate to the public, and otherwise make available Music Content, and Clips, by means of digital audio transmissions (on an interactive or non-interactive basis) through the Platform;<br></br></p>
                <p><strong>c) </strong>Place or embed User Content in magazines, websites, advertisements, and any and all other media, whether now known or hereafter developed, but specifically excluding television and movies;<br></br></p>
                <p><strong>d) </strong>Use User Content and metadata as may be reasonably necessary or desirable for us to exercise our rights under this Agreement;<br></br></p>
                <p><strong>e) </strong>Reproduce, distribute, and publicly perform and communicate to the public User Content (including Clips) as part of a downloaded program that may include multiple sound recordings and other content, commonly known as a “podcast;”<br></br></p>
                <p><strong>f) </strong>The non-exclusive right, privilege, and license to download, copy, reproduce, sample and otherwise edit, alter, couple, synchronize and/or otherwise embody the Music Content into Derivative Masters and Derivative Compositions, subject to and in accordance with these Terms;<br></br></p>
                <p><strong>g) </strong>Broadcast, stream and/or perform (publicly or otherwise) the User Content and/or Music Content in any and all media in connection with the advertisement and promotion of the Platform;<br></br></p>
                <p><strong>h) </strong>Use, publish and display your name, approved image, approved likeness, and approved biographical materials (provided that any materials or information provided by you are deemed approved) in any and all media in connection with the advertisement and promotion of the Platform;<br></br></p>
                <p><strong>i) </strong>License any of the rights granted to CNTNDR hereunder to third parties as reasonably necessary to perform its obligations hereunder or to otherwise effectuate the purpose of these Terms.<br></br></p>
                <p>The rights granted pursuant to this section shall be non-exclusive to CNTNDR.</p>
                <p>You hereby represent and warrant:<br></br> (1) there are and there shall be no liens, encumbrances or other charges against any and all User Content; <br></br> (2) no selections, materials, ideas, or other properties furnished by you and embodied or contained in the User Content, nor the exercise by CNTNDR of any of its rights hereunder, will violate or infringe upon any law or statutory right of any third party;<br></br> (3) you have the full right, power and authority to agree to these Terms, grant the rights conveyed to CNTNDR hereunder, and perform your material terms and obligations hereunder; <br></br> (4) you have not entered into and shall not enter into to any agreement with any third-party record company, distributor, music publisher, or any other person or entity that would conflict, inhibit, restrict or impair the rights granted to CNTNDR hereunder or the performance of your obligations under these Terms.</p>
                <p>You hereby represent and warrants that the use or other exploitation of your User Content, including, but not limited to, any musical works embodied in your sound recordings, by us as contemplated by this Agreement will not infringe or violate the rights of any third party, including, without limitation, any privacy rights, publicity rights, copyrights, contract rights, or any other intellectual property or proprietary rights.</p>
                <p>Infringement Reporting Procedures and Digital Millennium Copyright Act (DMCA) Procedures.</p>
                <ul>
                    <li><strong>Infringement Reporting Procedures:</strong></li>
                    <ul>
                        <li><p>If you own copyright or other intellectual property rights (“IP Rights Owner”), or if you are an agent authorized to act on the IP Rights Owner’s behalf (“Authorized Agent”), and you have a good faith belief that material or products on the Platform infringe the IP Rights Owner’s copyright, trademark, or other intellectual property right, and you would like to bring it to CNTNDR's attention, you can report your concern(s) by emailing us at <a href="mailto:legal@cntndr.io">legal@cntndr.io.</a></p></li>
                    </ul>
                    <li><strong>DMCA Procedures:</strong></li>
                    <ul>
                        <li>CNTNDR reserves the right to terminate your, or any third-party’s, right to use the Platform if such use infringes the copyrights of another.</li>
                        <li>CNTNDR may, under appropriate circumstances and at its sole discretion, terminate your, or any third-party’s, right to access to the Platform, if CNTNDR determines that you are, or a third-party is, a repeat infringer.</li>
                        <li>If you believe that any material has been posted via the Platform by any third-party in a way that constitutes copyright infringement, and you would like to bring it to CNTNDR's attention, you must provide notice to <a href="#">legal@cntndr.io</a> with the following information:
                            <ol>
                                <li>an electronic or physical signature of the person authorized to act on behalf of the owner of the copyrighted work;</li>
                                <li>an identification of the copyrighted work and the location on the Platform of the allegedly infringing work;</li>
                                <li>a written statement that you have a good faith belief that the disputed use is not authorized by the owner, its agent, or the law;</li>
                                <li>your name and contact information, including, without limitation, telephone number and email address; and</li>
                                <li>a statement by you that the above information in your notice is accurate and, under penalty of perjury, that you are the copyright owner or authorized to act on the copyright owner’s behalf.</li>
                            </ol>
                        </li>
                    </ul>
                </ul>
                <p>COPPA. The Platform is designed and intended for persons above the age of thirteen (13) years of age. We encourage you to review our policies and procedures in place to comply with the Children’s Online Privacy Protection Act (“COPPA”), contained within our Privacy Policy.  By contributing User Content or other content on or through the communities, you affirm that you are over the age thirteen (13). We will promptly delete User Content or other content associated with any account we obtain actual knowledge of that is associated with a registered user who is not at least thirteen (13) years old.</p>
            </div>
            <div className='legal-info-title'>2. Term</div>
            <div className='legal-info-content'>
                <strong>a) </strong>The term of this Agreement will commence on the date of you signing up for the Platform and will continue, unless and until terminated by either you or us, upon up to three (3) days written notice (the “Term”), which notice, if sent by <br></br> (a) CNTNDR, may be sent to you at the last e-mail address you provided to CNTNDR, and <br></br> (b) you to CNTNDR, must be sent only to the following e‑mail address: <a href='#'>support@cntndr.io</a> with the following information:<br></br> (a) your username; <br></br> (b) the e‑mail address associated with your account; <br></br> (c) all titles for which you are requesting termination. Any termination notice provided by you pursuant to this section shall be permanent and irreversible. Notwithstanding anything to the contrary herein, CNTNDR, may at any time in its sole discretion, with or without notice to you: <br></br>(i) suspend or limit your access to or your use of the Platform and/or <br></br>(ii) suspend or limit your access to your account.<br></br>
                <strong>b) </strong>The expiration or termination of the Agreement will not relieve either you or us from our respective obligations incurred prior to the effective date of your termination of the Agreement. In addition, provisions of this Agreement intended to survive the termination of this Agreement shall survive termination, including, but not limited to, the Indemnification, Disclaimers, Limitation of Liability; and General Provisions.<br></br>
                <strong>c) </strong>The Agreement is effective upon the date you agree to it (by signing up or electronically indicating acceptance) and continues so long as you use the Platform, or until terminated by you or by CNTNDR.
                <strong>d) </strong>You may terminate this Agreement by closing your account at any time by reaching out to <a href='#'>support@cntndr.io</a> formally requesting a termination of your account. We may terminate this Agreement and close your account at any time, for any reason, upon notice to you in accordance with the provisions herein. We may suspend your account and your access to the Platform and any rights in respect of your account, or terminate this Agreement, at any time, for any reason, including if:<br></br> (i) we determine that you may be ineligible for the Platform because of the risk associated with your account, including, without limitation, significant credit or fraud risk, or for any other reason; or <br></br>(ii) you do not comply with any of the provisions of this Agreement, our Privacy Policy, and/or the Third Party Processor’s applicable Terms. Termination of this Agreement shall entitle CNTNDR to cause the Processor to terminate the Third Party Processor’s applicable Terms.<br></br>
            </div>
            <div className='legal-info-title'>3. Accounts</div>
            <div className='legal-info-content'>
                <p> Platform, User Account Management, and Content Use Restrictions</p>
                <strong>a) </strong> Monitoring of Your Content; Removal of Content from Website<br></br>
                <ul>
                    <li>CNTNDR does not control your User Content and does not have any obligation to monitor your User Content for any purpose.</li>
                    <li>CNTNDR may choose, in its sole discretion, to monitor, review, or otherwise access some or all of your User Content, but by doing so:
                        <ul>
                            <li>CNTNDR assumes no responsibility for your User Content.</li>
                            <li>CNTNDR assumes no obligation to modify or remove any inappropriate elements of your User Content.</li>
                            <li>CNTNDR assumes no obligation to monitor, review, or otherwise access any other artist’s content or artwork.</li>
                        </ul>
                    </li>
                    <li>CNTNDR reserves the right, in its sole and absolute discretion, to remove any of Your Content from the Website if such content:
                        <ul>
                            <li>Is patently offensive, pornographic, or defamatory;</li>
                            <li>Is the subject of a dispute between you or us and a third party;</li>
                            <li>Is content to which you cannot document your rights therein upon CNTNDR’s request;</li>
                            <li>Violates the intellectual property rights or other protected interests of a third party;</li>
                            <li>Is the subject of a takedown notice by a party claiming to own the rights therein;</li>
                            <li>Is the subject of any fraudulent activity;</li>
                            <li>For any other reason in CNTNDR’s sole and absolute judgment is necessary to protect the business interests of CNTNDR and any of its business partners or Licensees.</li>
                        </ul>
                    </li>
                    <li>CNTNDR may also remove Your Content from the Website if you are:
                        <ul>
                            <li>Abusive or rude;</li>
                            <li>Provide false or intentionally misleading information to any CNTNDR employees or agents.</li>
                        </ul>
                    </li>
                    <li>CNTNDR shall have no liability to you for the removal of any of Your Content from the Website or any Licensee website or service other than to provide you a credit (but not a refund) for any fees previously paid by you for making Your Content available via the Website or through Licensees.</li>
                    <li>The removal of any of Your Content shall not relieve CNTNDR of the obligation to pay you any royalties that may have accrued prior to the removal of Your Content.</li>
                    <li>This Agreement shall not be terminated automatically by CNTNDR’s removal of Your Content from the Website or Licensee’s websites or services.</li>
                    <li>In order for you to terminate this Agreement following the removal of any of Your Content, you must send CNTNDR a Termination Notice.</li>
                </ul>
                <strong>b) </strong> Wallet Management
                <ul>
                    <li>CNTNDR reserves the right, in its sole and absolute discretion, to add, edit, remove, modify, items within your wallet, including but not limited to your Clout balance, badges, and trophies.</li>
                </ul>
                <strong>c) </strong>Account Information; Disclosures
                <ul>
                    <li>In order to access some features of the Website, including your account information and periodic statements, you will have to create an online account. You hereby represent and warrant that the information you provide to CNTNDR upon registration will be true, accurate, current, and complete. You also hereby represent and warrant that you will ensure that your account information, including your e-mail address, is kept accurate and up‑to-date at all times during the Term of this Agreement.</li>
                    <li>As a registered user of the Platform you will have login information, including a username and password. Your account is personal to you, and you may not share your account information with, or allow access to your account by, any third party, other than an agent authorized to act on your behalf. As you will be responsible for all activity that occurs under your account, you should take care to preserve the confidentiality of your username and password, and any device that you use to access the Platform. You agree to notify us immediately of any breach in secrecy of your login information. If you have any reason to believe that your account information has been compromised or that your account has been accessed by a third party not authorized by you, then you agree to immediately notify CNTNDR by e-mail to <a href='#'>support@cntndr.io </a>. You will be solely responsible for the losses incurred by CNTNDR and others (including other users) due to any unauthorized use of your account that takes place prior to notifying CNTNDR that your account has been compromised.</li>
                    <li>You acknowledge, consent, and agree that CNTNDR may access, preserve, and disclose your account information and your User Content if required to do so by law or in a good faith belief that such access, preservation or disclosure is reasonably necessary to <br></br> (i) comply with legal process; <br></br>(ii) enforce this Agreement;<br></br> (iii) respond to a claim that any of your User Content violates the rights of third parties;<br></br> (iv) to respond to your requests for customer service; or <br></br> (v) to protect the rights, business interests, property or personal safety of CNTNDR and its employees and users, and the public.</li>
                </ul>
            </div>
            <div className='legal-info-title'>4. DISCLAIMER OF WARRANTY</div>
            <div className='legal-info-content'>
                <p>As Permitted By Applicable Law, You Expressly Agree That Use Of The Platform Is At Your Sole Risk. Neither Cntndr, Nor Its Subsidiaries Or Affiliates Or Any Of Their Respective Employees, Agents, Merchants, Brands, Third Party Content Providers Or Licensors Or Any Of Their Officers, Directors, Employees Or Agents, Warrant That Use Of The Platform Will Be Uninterrupted, Secure, Virus Free, Or Error Free, Nor Do They Make Any Warranty As To A The Results That May Be Obtained From Use Of The Platform, Or B The Accuracy, Completeness, Or Reliability Of I The Content On The Platform, Including, Without Limitation, Brand Offerings, Products, Or Other Available Programs, II Descriptions Of Brand Offerings, Products, Or Other Available Programs, Or III User Content Provided Through The Platform. The Platform And All Content, User Content And Other Information Contained On The Platform, Brand Offerings, Products And Other Available Programs Accessible Or Available Through The Platform, Are Made Accessible Or Available On An “As Is'' And “As Available” Basis. To The Extent Allowed By Applicable Law, Cntndr Hereby Disclaims Any And All Representations, Warranties, And Conditions, Whether Express, Implied, Or Statutory, As To The Operation Of The Platform Or The Content, User Content, Or Other Information Contained On The Platform Or The Brand Offerings, Products, Or Other Available Programs Accessible Or Available Through The Platform, Including, Without Limitation, Those Of Title, Non Infringement, Non Interference, Merchantability, Suitability, And Fitness For A Particular Purpose, As Well As Warranties Implied From A Course Of Performance Or Course Of Dealing. The Warranty Limitations In This Section Are Not Intended To Limit Any Warranty Provided Directly By A Brand Or By The Applicable Manufacturer Of Physical Products Or Any Express Representations Or Warranties By Cntndr That Are Included In Other Applicable Terms.</p>
            </div>
            <div className='legal-info-title'>5. LIMITATION OF LIABILITY</div>
            <div className='legal-info-content'>
                <p>As permitted by applicable law, in no event shall CNTNDR, its subsidiaries or affiliates or any of their respective employees, officers, directors, agents, sponsors, partners, third-party content providers or licensors, or any of their officers, directors, employees, or agents, be liable for any direct or indirect lost profits or lost business damages, indirect, incidental, special, consequential, or punitive damages arising out of, related to, or in connection with any of the following : <br></br> (a) your use of the platform, the content, user content, including, without limitation, any personally identifiable information, and any other information either contained in the platform or submitted by you to the platform <br></br> (b) your inability to use the platform <br></br> (c) modification or removal of content submitted on the platform <br></br> (d) the sponsor offerings, products, and other available programs accessible or available through the platform <br></br> (e) any products or services purchased or obtained directly from a sponsor <br></br> (f) these terms:  <br></br>(g) any improper use of information you provide to the platform, including, without limitation, any personally identifiable information. In no event will CNTNDR’s liability in connection with a sponsor offering, product, and other available programs exceed the amounts paid for the applicable voucher, product, or service. The liability limitations in this section are not intended to limit any warranty provided directly by a sponsor or by the applicable manufacturer of physical products or any express representations or warranties by CNTNDR that are included in other applicable terms, nor are they intended to limit remedies you might have for product-related injury.</p>

            </div>
            <div className='legal-info-title'>6. Dispute Resolution</div>
            <div className='legal-info-content'>
                <p>Choice of Law. Any disputes arising out of or related to these Terms and/or any use by you of the Platform or CNTNDR’s services shall be governed by the laws of the State of California, without regard to its choice of law rules and without regard to conflicts of laws principles.<br></br>
                    Dispute Resolution/Confidential Mediation/Arbitration Agreement. Except as specifically stated herein, any dispute or claim between you and CNTNDR and/or its subsidiaries, affiliates, and/or any of their respective members, officers, directors, and employees (all such entities collectively referred to herein as the “CNTNDR Entities”) arising out of, relating in any way to, or in connection with these Terms, the Platform or your use of the Platform, your personally identifiable information, or any products or brand offerings (“Dispute(s)”) shall be resolved exclusively by : </p>
                <strong>a) </strong> First, upon written notice furnished by one party to the other party (in the case of a CNTNDR Entity, to <a href='#'>legal@cntndr.io</a>), the parties shall first try in good faith to settle the dispute by confidential mediation administered by a mediator selected by CNTNDR. The Parties shall bear the mediator’s costs in equal portions.<br></br>
                <strong>b) </strong> Second, if the parties cannot settle the dispute in the confidential mediation, then final, binding arbitration. By virtue of this Dispute Agreement (defined below), you and CNTNDR are each giving up the right to go to court and have a Dispute heard by a judge or jury (except as otherwise set forth herein).  These provisions shall constitute your and CNTNDR’s written agreement to arbitrate Disputes under the Federal Arbitration Act (“Dispute Agreement”). The arbitration will be administered by the American Arbitration Association (“AAA”), conducted before a single arbitrator, and except as otherwise set forth herein, pursuant to its rules, including, without limitation, the AAA’s Consumer-Arbitration Rules, available at the ADR Consumer Platform. The arbitrator will apply and be bound by this Agreement, apply applicable law and the facts, and issue a reasoned award, if appropriate.
                <p>To begin an arbitration proceeding, you must submit the Dispute by utilizing the forms available at ADR Consumer Platform, and simultaneously sending a copy of the completed form to <a href='legal@cntndr.io.'>legal@cntndr.io.</a>  </p>
                <p>Each party shall bear fifty percent (50%) of all filing, administration, and arbitrator fees. The arbitration will be conducted based upon written submissions unless you request, and/or the arbitrator determines, that a telephone or in-person hearing is necessary. In addition, you hereby unconditionally agree that <br></br> (1) the arbitrator’s decision shall be controlled by these Terms and any of the other agreements referenced herein that you may have entered into in connection with the Platform<br></br> (2) the arbitrator shall apply California law consistent with the FAA and applicable statutes of limitations, and shall honor claims of privilege recognized at law <br></br> (3) in the event the AAA is unavailable or unwilling to hear the Dispute, you and the applicable CNTNDR entity(ies) shall agree to, or a court shall select, another arbitration provider.</p>
                <p>No Class Action Matters. <br></br> We each agree that we shall bring any Dispute against the other in our respective individual capacities and not as a plaintiff or class member in any purported class, representative proceeding or as an association. In addition, we each agree that Disputes shall be arbitrated only on an individual basis and not in a class, consolidated, or representative action and that the arbitrator may award relief (including injunctive relief) only on an individual basis. The arbitrator does not have the power to vary these provisions.</p>
                <p>Choice of Law and Forum <br></br> No Jury Trial. If for any reason a Dispute proceeds in court: <br></br> (i) you and CNTNDR agree that any Dispute may only be instituted in a state or federal court in San Francisco County, California <br></br> (ii) you and CNTNDR irrevocably consent and submit to the exclusive personal jurisdiction and venue of such courts for resolution of such Disputes <br></br> (iii) you and CNTNDR agree to waive any right to a trial by jury. You and CNTNDR agree that the Federal Arbitration Act, the AAA rules, applicable federal law, and the laws of the State of California without regard to principles of conflicts of law, will govern this Dispute Agreement and any Disputes.</p>
                <p>Injunctive Relief. Notwithstanding anything to the contrary in this Dispute Agreement, either party may bring suit in court seeking an injunction or other equitable relief arising out of or relating to the infringement of a party’s intellectual property, or any illegal or intentional act affecting the accessibility, functionality, or the security of the Platform, and/or any illegal or intentional act against your interests or the general business interests of CNTNDR.</p>
                <p>Severability. If any part of this Dispute Resolution provision is ruled to be unenforceable, then the balance of this Dispute Resolution provision shall remain in full effect and construed and enforced as if the portion ruled unenforceable were not contained herein.</p>
            </div>
            <div className='legal-info-title'>7. General Provisions </div>
            <div className='legal-info-content'>
                <p>Electronic Communications : <br></br> When you use the Platform or send emails to CNTNDR, you are communicating with us electronically and consent to receive electronic communications related to your use of the Platform. We will communicate with you by email or by posting notices on the Platform. You agree that all agreements, notices, disclosures, and other communications that are provided to you electronically satisfy any legal requirement that such communications be in writing. Notices from us will be considered delivered to you and effective when sent to the email address you provide on the Platform or from which you otherwise email us.</p>
                <p>Websites of Others : <br></br> The Platform contains links to websites maintained by other parties. These links are provided solely as a convenience to you and not because we endorse or have an opinion about the contents on such websites. We expressly disclaim any representations regarding the content or accuracy of materials on such websites or the privacy practices of those websites. If you decide to access websites maintained by other parties, you do so at your own risk. We are not responsible or liable, directly or indirectly, for any damage, loss, or liability caused or alleged to be caused by or in connection with any use of or reliance on any content, products, or services available on or through any such linked Platform or resource.</p>
                <p>Indemnification/Release : <br></br> You agree to defend, indemnify, and hold harmless CNTNDR, its subsidiaries and affiliates, and their respective directors, officers, employees and agents from and against all claims and expenses, including, without limitation, attorneys’ fees, arising out of, related to, or in connection with any of the following: <br></br> (a) any User Content submitted or posted by you, in connection with the Platform, or any use of the Platform in violation of these Terms; (b) fraud you commit or your intentional misconduct or gross negligence; or (c) your violation of any applicable U.S., Canadian, or foreign law or rights of a third-party.</p>
                <p>You are solely responsible for your interactions on the Platform. To the extent permitted under applicable laws, you hereby release CNTNDR from any and all claims or liability related to the Platform, and any conduct, speech or User Content, whether online or offline, of any other third-party.</p>
                <p>Force Majeure. CNTNDR shall be excused from performance under these Terms, to the extent it or a Sponsor is prevented or delayed from performing, in whole or in part, as a result of an event or series of events caused by or resulting from:<br></br> (a) weather conditions or other elements of nature or acts of God <br></br> (b) acts of war, acts of terrorism, insurrection, riots, civil disorders, or rebellion<br></br> (c) pandemics, epidemics, quarantines or embargoes<br></br> (d) labor strikes <br></br> (e) error or disruption to major computer hardware or networks or software failures <br></br> (g) other causes beyond the reasonable control of CNTNDR or a Sponsor, as applicable.</p>
                <p>Assignment. You may not assign these Terms, or any rights, benefits, or obligations hereunder, by operation of law or otherwise, without the express written permission of CNTNDR. Any attempted assignment that does not comply with these Terms shall be null and void. CNTNDR may assign these Terms, in whole or in part, to any third-party in its sole discretion.</p>
                <p>Entire Agreement : The Terms, including, without limitation, the incorporated Privacy Policy, and other terms incorporated by reference, constitute the entire agreement and understanding between you and CNTNDR with respect to the subject matter hereof and supersedes all prior or contemporaneous communications and proposals, whether oral or written, between you and CNTNDR with respect to such subject matter.</p>
            </div>
            <div className='legal-info-title'>8. Modifications to Terms </div>
            <div className='legal-info-content'>
                <p>CNTNDR reserves the right to modify or update these Terms at any time without prior notice. Any changes will be effective immediately upon posting. Continued use of the Platform after any such changes constitutes acceptance of the modified Terms.</p>
            </div>
            <div className='legal-info-title'>9. Contact Information </div>
            <div className='legal-info-content'>
                <p>For questions or concerns regarding these Terms, please contact us at <a href='#'>legal@cntndr.io</a>.</p>
            </div>
        </div>
    </>
};

export default Terms;