// validationSchemas.js
import * as Yup from 'yup';


export const loginSchema = Yup.object().shape({
  // email: Yup
  //   .string()
  //   .required('Email is required.')
  //   .matches(/^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/, 'Email is not valid.'),
  password: Yup
    .string()
    .required('Password is required.')
    .min(6, 'Password should be at least 6 characters.')

});


export const signUpSchema = Yup.object().shape({
  email: Yup
    .string()
    .required('Email is required.')
    .matches(/^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/, 'Email is not valid.'),
  password: Yup
    .string()
    .required('Password is required.')
    .min(6, 'Password should be at least 6 characters.'),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Repeat Password is required.'),
});;


export const trackSchema = Yup.object().shape({
  battleOption: Yup.string().required('Please select an option'),
  selectedTrack: Yup.string().test({
    name: 'selectedTrack',
    message: 'Please select a track',
    test: function (value) {
      return this.parent.battleOption === 'chooseFromMyTrack' ? !!value : true;
    },
  }),
  fileInput: Yup.mixed()
    .test({
      name: 'fileInput',
      message: 'Please upload a music file',
      test: function (value) {
        if (this.parent.battleOption === 'addNewTrack') {
          if (!value || !value[0]) {
            return false;
          }
        }
        return true;
      },
    }).test(
      'fileFormat',
      'Only .mp3 .mp4 .mov files are allowed',
      function (value) {
        if (value && value[0]) {
          const fileName = value[0].name.toLowerCase();
          return fileName.endsWith('.mp3') || fileName.endsWith('.mp4') || fileName.endsWith('.mov');
        }
        return true;
      },
    ),
  imageInput: Yup.mixed()
    .test({
      name: 'imageInput',
      message: 'Please upload an image',
      test: function (value) {
        return this.parent.battleOption === 'addNewTrack' ? !!value : true;
      },
    }).test(
      'imageFormat',
      'Only image files are allowed',
      function (value) {
        if (this.parent.battleOption === 'addNewTrack') {
          return value && value[0] && value[0].type.startsWith('image/');
        }
        return true;
      },
    ),
  trackNameInput: Yup.string().test({
    name: 'trackNameInput',
    message: 'Please enter a track name',
    test: function (value) {
      return this.parent.battleOption === 'addNewTrack' ? !!value : true;
    },
  }),
  genreSelect: Yup.string().test({
    name: 'genreSelect',
    message: 'Please select a genre',
    test: function (value) {
      return this.parent.battleOption === 'addNewTrack' ? !!value : true;
    },
  }),
});


export const openBattleschema = Yup.object().shape({
  descriptionInput: Yup.string().required('Description is required'),
  tag: Yup.array()
    .of(Yup.string()) // Ensure each tag is a string
    .min(1, 'At least one tag is required')
    .test({
      name: 'max-tags',
      message: 'You can select at most 3 tags',
      test: function (value) {
        // Validate maximum of 3 tags
        return !value || value.length <= 3;
      },
    })
    .required('Tag is required'),
  entryFee: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .nullable()
    .required('Entry Fee is required')
    .test({
      name: 'numeric-entryFee',
      message: 'Entry Fee is not valid',
      test: function (value) {
        return /^\d+$/.test(value);
      },
    })
    .test('greater-than-zero', 'Entry Fee must be greater than 0', value => value > 0),
  opponentSearch: Yup.string().test({
    name: 'opponentSearch',
    test: function (value) {
      const selectedOption = this.parent.selectedOption;
      return selectedOption === 'challenge' ? !!value : true;
    },
    message: 'Find Opponent is required for Challenge Battle',
  }),
});

export const trackSchemaToJoinBattle = Yup.object().shape({
  battleOption: Yup.string().required('Please select an option'),
  selectedTrack: Yup.string().test({
    name: 'selectedTrack',
    message: 'Please select a track',
    test: function (value) {
      return this.parent.battleOption === 'chooseFromMyTrack' ? !!value : true;
    },
  }),
  fileInput: Yup.mixed()
    .test({
      name: 'fileInput',
      message: 'Please upload a music file',
      test: function (value) {
        if (this.parent.battleOption === 'addNewTrack') {
          if (!value || !value[0]) {
            return false;
          }
        }
        return true;
      },
    }).test(
      'fileFormat',
      'Only .mp3 .mp4 .mov files are allowed',
      function (value) {
        if (value && value[0]) {
          const fileName = value[0].name.toLowerCase();
          return fileName.endsWith('.mp3') || fileName.endsWith('.mp4') || fileName.endsWith('.mov');
        }
        return true;
      },
    ),
  imageInput: Yup.mixed()
    .test({
      name: 'imageInput',
      message: 'Please upload an image',
      test: function (value) {
        return this.parent.battleOption === 'addNewTrack' ? !!value : true;
      },
    }).test(
      'imageFormat',
      'Only image files are allowed',
      function (value) {
        if (this.parent.battleOption === 'addNewTrack') {
          return value && value[0] && value[0].type.startsWith('image/');
        }
        return true;
      },
    ),
  trackNameInput: Yup.string().test({
    name: 'trackNameInput',
    message: 'Please enter a track name',
    test: function (value) {
      return this.parent.battleOption === 'addNewTrack' ? !!value : true;
    },
  }),
  trackDescriptionInput: Yup.string().test({
    name: 'trackDescriptionInput',
    message: 'Please enter a track description',
    test: function (value) {
      return this.parent.battleOption === 'addNewTrack' ? !!value : true;
    },
  }),
  genreSelect: Yup.string().test({
    name: 'genreSelect',
    message: 'Please select a genre',
    test: function (value) {
      return this.parent.battleOption === 'addNewTrack' ? !!value : true;
    },
  }),
});

export const emailLinkSchema = Yup.object().shape({
  email: Yup
    .string()
    .required('Email is required.')
    .matches(/^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/, 'Email is not valid.')

});



export const forgetPasswordSchema = Yup.object().shape({
  email: Yup
    .string()
    .required('Email is required.')
    .matches(/^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/, 'Email is not valid.'),
  password: Yup
    .string()
    .required('Password is required.')
    .min(6, 'Password should be at least 6 characters.')
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{6,}$/,
      'Password should contain at least one uppercase letter, one numeric digit, and one special character.'
    ),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Repeat Password is required.'),
});;




export const updateProfileSchema = Yup.object().shape({
  username: Yup.string()
    .required('Username is required')
    .min(3, 'Username should be at least 3 characters')
    .matches(/^[a-zA-Z0-9_]+$/, 'Invalid username. Only alphanumeric characters and underscores are allowed.'),
  bio: Yup.string()
    .required('Bio is required')
    .max(250, 'Bio must be at most 250 characters'),
  contactNumber: Yup.string()
    .required('Contact number is required')
    .matches(/^[0-9]{10}$/, 'Invalid contact number. It should be a 10-digit number.'),
  profilePicture: Yup.mixed().test('fileType', 'Invalid file format. Only images are allowed.', (value) => {
    if (value) {
      const acceptedFormats = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];
      const fileType = value[0]?.type;
      return acceptedFormats.includes(fileType);
    }
    return true; // Allow if no file is selected
  }),
});


export const setUpAccountSchema = Yup.object().shape({
  username: Yup
    .string()
    .required('Username is required')
    .matches(/^[a-zA-Z0-9_]*$/, 'Username should contain only letters, numbers, or underscore'),

  name: Yup
    .string()
    .nullable()
    .matches(/^[a-zA-Z0-9 ]*$/, 'Name should contain only letters, numbers, or spaces'),

  contactNumber: Yup
    .string()
    .nullable()
    .matches(/^\d*$/, 'Contact number should contain only numbers'),

  profilePicture: Yup.mixed().test('fileType', 'Profile picture should be an image', (value) => {
    if (!value) {
      // No file uploaded, which is allowed
      return true;
    }
    return value && value[0].type.startsWith('image/');
  }),
});




export const validateGeneralForm = Yup.object().shape({
  name: Yup.string().required('Name is required').min(3, "atleast 3 characters required"),
  // bio: Yup.string().required('Bio is required'),
  // phoneNumber: Yup.string().required('Phone number is required'),
  // genre: Yup.string().required('Genre is required'),
});

// Social form validation function
export const validateSocialForm = Yup.object().shape({
  instagram: Yup.string().url('Invalid URL').nullable(),
  twitter: Yup.string().url('Invalid URL').nullable(),
  youtube: Yup.string().url('Invalid URL').nullable(),
  tiktok: Yup.string().url('Invalid URL').nullable(),
});

// Email and Password form validation function
export const validateEmailForm = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  newPassword: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Repeat Password is required.'),
});

export const editProfileSchemas = (activeButton) => {
  switch (activeButton) {
    case 'general':
      return validateGeneralForm;
    case 'social':
      return validateSocialForm;
    case 'email':
      return validateEmailForm;
    default:
      return null;
  }
};



// export const addTrackSchema = Yup.object().shape({
//   fileInput: Yup.mixed().test(
//     'fileInput',
//     'Please upload a music file',
//     function (value) {
//       return !!value;
//     }
//   ).test(
//     'fileFormat',
//     'Only .mp3 files are allowed',
//     function (value) {
//       if (value) {
//         const fileName = value[0].name.toLowerCase();
//         return fileName.endsWith('.mp3');
//       }
//       return true;
//     }
//   ),
//   imageInput: Yup.mixed().test(
//     'imageInput',
//     'Please upload an image',
//     function (value) {
//       return !!value;
//     }
//   ).test(
//     'imageFormat',
//     'Only image files are allowed',
//     function (value) {
//       if (value) {
//         return value[0].type.startsWith('image/');
//       }
//       return true;
//     }
//   ),
//   trackNameInput: Yup.string().required('Please enter a track name'),
//   trackDescriptionInput: Yup.string().required('Please enter a track description'), // No specific validation for description provided
//   genreSelect: Yup.string().required('Please select a genre'),
//   mood: Yup.array()
//     .of(Yup.string()) // Ensure each tag is a string
//     .test({
//       name: 'max-moods',
//       message: 'You can select at most 3 moods',
//       test: function (value) {
//         // Validate maximum of 3 tags
//         return !value || value.length <= 3;
//       },
//     })
//     ,// Assuming mood is an array
//   tag: Yup.array()
//     .of(Yup.string()) // Ensure each tag is a string
//     .test({
//       name: 'max-tags',
//       message: 'You can select at most 3 tags',
//       test: function (value) {
//         // Validate maximum of 3 tags
//         return !value || value.length <= 3;
//       },
//     })
//     , // Assuming tag is an array
// });

export const addTrackSchema = Yup.object().shape({
  fileInput: Yup.mixed()
    .required('Please upload a music file')
    .test(
      'fileFormat',
      'Only .mp3 .mp4 .mov files are allowed',
      function (value) {
        if (value) {
          const fileName = value[0].name.toLowerCase();
          return fileName.endsWith('.mp3') || fileName.endsWith('.mp4') || fileName.endsWith('.mov');
        }
        return true;
      }
    ),
  imageInput: Yup.mixed()
    .required('Please upload an image')
    .test(
      'imageFormat',
      'Only image files are allowed',
      function (value) {
        if (value) {
          return value[0].type.startsWith('image/');
        }
        return true;
      }
    ),
  trackNameInput: Yup.string().required('Please enter a track name'),
  trackDescriptionInput: Yup.string().required('Please enter a track description'),
  genreSelect: Yup.string().required('Please select a genre'),
  mood: Yup.array()
    .of(Yup.string())
    .test({
      name: 'max-moods',
      message: 'You can select at most 3 moods',
      test: function (value) {
        return !value || value.length <= 3;
      },
    }),
  tag: Yup.array()
    .of(Yup.string())
    .test({
      name: 'max-tags',
      message: 'You can select at most 3 tags',
      test: function (value) {
        return !value || value.length <= 3;
      },
    }),
});

// export const editTrackSchema = Yup.object().shape({

//   imageInput: Yup.mixed().test(
//     'imageInput',
//     'Please upload an image',
//     function (value) {
//       return !!value;
//     }
//   ).test(
//     'imageFormat',
//     'Only image files are allowed',
//     function (value) {
//       if (value) {
//         return value[0].type.startsWith('image/');
//       }
//       return true;
//     }
//   ),
//   trackNameInput: Yup.string().required('Please enter a track name'),
//   trackDescriptionInput: Yup.string().required('Please enter a track description'), // No specific validation for description provided
//   genreSelect: Yup.string().required('Please select a genre'),
//   mood: Yup.array()
//     .of(Yup.string()) // Ensure each tag is a string
//     .test({
//       name: 'max-moods',
//       message: 'You can select at most 3 moods',
//       test: function (value) {
//         // Validate maximum of 3 tags
//         return !value || value.length <= 3;
//       },
//     })
//     ,// Assuming mood is an array
//   tag: Yup.array()
//     .of(Yup.string()) // Ensure each tag is a string
//     .test({
//       name: 'max-tags',
//       message: 'You can select at most 3 tags',
//       test: function (value) {
//         // Validate maximum of 3 tags
//         return !value || value.length <= 3;
//       },
//     })
//     , // Assuming tag is an array
// });

export const editTrackSchema = (imageIsNull) => {
  let imageInputValidation = Yup.mixed();

  if (imageIsNull) {
    imageInputValidation = imageInputValidation.test(
      'imageInput',
      'Please upload an image',
      function (value) {
        return !!value;
      }
    ).test(
      'imageFormat',
      'Only image files are allowed',
      function (value) {
        if (value) {
          return value[0].type.startsWith('image/');
        }
        return true;
      }
    );
  }

  return Yup.object().shape({
    imageInput: imageInputValidation,
    trackNameInput: Yup.string().required('Please enter a track name'),
    trackDescriptionInput: Yup.string().required('Please enter a track description'),
    mood: Yup.array()
      .of(Yup.string())
      .test({
        name: 'max-moods',
        message: 'You can select at most 3 moods',
        test: function (value) {
          return !value || value.length <= 3;
        },
      }),
    tag: Yup.array()
      .of(Yup.string())
      .test({
        name: 'max-tags',
        message: 'You can select at most 3 tags',
        test: function (value) {
          return !value || value.length <= 3;
        },
      }),
  });
};


export const contactUsSchema = Yup.object().shape({
  email: Yup
    .string()
    .required('Email is required.'),
  name: Yup
    .string()
    .required('Name is required.'),
  message: Yup
    .string()
    .required('Message is required.')
});

