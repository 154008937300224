import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isBadgeInfo : false,
    badgeId : null,
    openDirectly: false
}

export const badgeSlice = createSlice({
  name: 'battle',
  initialState,
  reducers: {
   updateIsBadgeInfo : (state, action) => {
    state.isBadgeInfo= action.payload;
   },
   updateBadgeId : (state,action) =>
   {
     state.badgeId = action.payload
   },
   updateOpenDirectly : (state,action) =>
   {
     state.openDirectly = action.payload
   }
  },
})

export const {updateIsBadgeInfo,updateBadgeId,updateOpenDirectly} = badgeSlice.actions

export default badgeSlice.reducer