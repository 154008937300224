import React, { useState, useEffect, useContext, useRef } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { SocketContext } from '../../Context/socket'
import useHeader from '../../Helper/useHeader'
import * as cs from '../../Api/cs'
import { Socket } from 'socket.io-client'
import { useDispatch, useSelector } from 'react-redux'
import UserImg from '../../../src/assets/images/default.png';
import { AddIcon, PaperBoatIcon, PreviousArrow, SearchIcon } from '../Icons'
import { updateChatUserId, updateShowChat, updateUnreadMessage } from '../../Redux/Slices/chat.slice'

import { InputGroup, Form, Button } from 'react-bootstrap'
import Select, { components } from "react-select";
import { useNavigate } from 'react-router-dom'
import EmojiPicker from 'emoji-picker-react';
import moment from 'moment'
import './Chat.scss'
require('moment-timezone');



const Option = (props) => {

    return (
        <div className='add-user-list'>
            <div className='add-user-img'><img src={props.data.image ? props.data.image : UserImg} /></div>
            <div className='add-user-name'><components.Option {...props} /></div>
        </div>
    );
};

const Chat = (props) => {

    const { userId, showChat, setShowChat, handleLiveStatus } = props;


    const [roomId, setRoomId] = useState()
    const [isChat, setIsChat] = useState(false);
    const [addUser, setAddUser] = useState(false);
    const [searchText, setSearchText] = useState();
    const [filterData, setFilterData] = useState()
    const [isWindow, setIsWindow] = useState(false);
    const [hideChat, setHideChat] = useState(false);
    const [chatMessage, setChatMessage] = useState();
    const [chatHistory, setChatHistory] = useState([]);
    const [activeChatUser, setActiveChatUser] = useState();
    const [userDataList, setUserDataList] = useState()
    const [userData, setUserData] = useState([])
    const [allUsersData, setAllUserData] = useState([])
    const [userDetails, setUserDetails] = useState()
    const [filterChat, setFilterChat] = useState([])
    const [showEmoji, setShowEmoji] = useState(false)
    const { showChatMessage, userReceiverId } = useSelector(state => state.chat);

    const headers = useHeader()
    const bottomRef = useRef(null);
    const navigate = useNavigate()
    const chatRef = useRef(null);
    const dispatch = useDispatch()
    const socket = useContext(SocketContext)

    const handleChat = (activeUser) => {
        setFilterChat([])
        setChatHistory([])
        setAddUser(false)
        setActiveChatUser(activeUser)
        setRoomId(activeUser?._id)
        setIsChat(true);
        console.log('handleChat activeUser', activeUser)
        if (activeUser._id) {
            getChatMessagesList(activeUser._id);
        }

        trackReadMessage(activeUser?._id, activeUser?.lastMessageBy)
    }




    const handleNewChat = (activeUser) => {
        setFilterChat([])
        setChatHistory([])
        setAddUser(false)
        setActiveChatUser(activeUser)
        setIsChat(true);
        if (activeUser.receiver_id) {
            const checkRoomExists = userData?.find(item => item.participants.includes(activeUser.receiver_id));
            if (checkRoomExists) {
                getChatMessagesList(checkRoomExists._id);
            }
        }

    }


    const handleDirectMessage = async (activeUser) => {
        try {
            const response = await cs.get(`roomList/${userId}`, 'chatMessage', {}, headers);
            let user_Data = response?.data?.data;
            let active_user = { receiver_id: activeUser }
            setFilterChat([])
            setChatHistory([])
            setAddUser(false)
            if (active_user?.receiver_id) {
                const checkRoomExists = user_Data?.find(item => item.participants.includes(active_user?.receiver_id));
                setActiveChatUser(checkRoomExists)

                if (checkRoomExists) {
                    getChatMessagesList(checkRoomExists._id);
                }
                else if (!checkRoomExists) {
                    const response = await cs.get('getUserList/' + active_user?.receiver_id, 'users', {}, null);

                    const _data = {
                        receiver_id: response?.data?.data?._id,
                        receiver: {
                            _id: response?.data?.data?._id,
                            image: response?.data?.data?.image,
                            name: response?.data?.data?.name,
                            userName: response?.data?.data?.userName
                        },
                    }
                    setActiveChatUser(_data)
                }
            }
            setIsChat(true);
        }
        catch (error) {

        }

    }

    useEffect(() => {
        console.log('userReceiverId', userReceiverId)
        if (userReceiverId) {
            handleDirectMessage(userReceiverId)
        }
    }, [userReceiverId])


    const handelBackbtn = async () => {
        await fetchRooms()
        setIsChat(false);
    }

    const handleAddUser = () => {
        setAddUser(!addUser)
    }

    const handleAddActive = () => {
        if (addUser)
            setAddUser(false)
    }

    const handleHideChat = () => {
        setShowChat(false)
        setHideChat(true)
        dispatch(updateShowChat(false))
        dispatch(updateChatUserId(null))
        document.body.classList.remove('hidden');

    }


    // useEffect(() => {
    //     if (!showChatMessage) {
    //         handleHideChat()
    //     }
    // }, [showChatMessage])


    /**
     * Get Chat History Function ...
     */

    const getChatMessagesList = async (room_Id) => {

        try {


            console.log('handleChat getChatMessagesList room_Id', room_Id)
            const response = await cs.get(`getList/${room_Id}`, 'chatMessage', {}, headers);

            console.log('handleChat getChatMessagesList', response)
            if (response?.data?.code === 200) {
                setChatHistory(response?.data?.data)
                setFilterChat(response?.data?.data)

                setTimeout(() => {
                    scrollToBottom();
                }, 500)

            }
        }
        catch (error) {

        }

    }


    /**
     * Get User Details ...
     */

    const getUsersDetails = async () => {
        try {
            const response = await cs.get(`getUserList/${userId}`, 'users', {}, headers);
            if (response?.data?.code === 200) {
                setUserDetails(response?.data?.data)
            }
        }
        catch (error) {

        }
    }

    useEffect(() => {
        getUsersDetails()
        getAllUsersList()
        fetchRooms();
    }, [])


    useEffect(() => {


        const handleClickOutside = (event) => {

            if (chatRef.current && !chatRef.current.contains(event.target) && !event.target.closest('.header-icons')) {

                handleHideChat();
            }
        }


        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };


    }, []);

    /**
     * Socket to Handle Live and Offline ...
     */
    useEffect(() => {

        // let data = {
        //     id: userId,
        //     status: showChat,
        // }

        // socket.emit('live', data);

        //socket to track read and unread ...
        trackReadMessage(activeChatUser?._id, activeChatUser?.lastMessageBy)


    }, [showChat])





    /**
    * Filter UserData
    */

    useEffect(() => {
        handleSearch()
        handleChatSearch()
    }, [userDataList, userId, searchText]);



    /**
      * Handle Search Function 
    */

    const handleSearch = () => {

        setAddUser(false);

        let filteredUserData;
        if (!searchText || searchText.trim() === "") {
            // If searchText is empty, return all userData
            filteredUserData = userData;
        } else {
            // If searchText is not empty, filter userData based on receiver userName
            filteredUserData = userData?.filter(item =>
                item.receiver.name.toLowerCase().includes(searchText.toLowerCase())
            );
        }

        setFilterData(filteredUserData);
    };

    const handleChatSearch = () => {

        setAddUser(false)
        let filteredUserData;
        if (!searchText || searchText.trim() === "") {
            filteredUserData = chatHistory
        } else {
            filteredUserData = chatHistory?.filter(item =>
                item?.message?.toLowerCase()?.includes(searchText?.toLowerCase())
            );
        }
        setFilterChat(filteredUserData);
    }


    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1200) {
                setIsWindow(true);
            } else {
                setIsWindow(false);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    /**
     * Get All User List ...
     */

    const getAllUsersList = async () => {
        try {
            const response = await cs.get('getUserList', 'users', {}, headers);
            if (response?.data?.code === 200) {

                const userList = response?.data?.data.filter(user => user._id !== userId);
                let filterOption = userList?.map(user => (
                    {
                        value: user?._id,
                        label: user?.name ? user?.name : user?.userName,
                        receiver_id: user?._id,
                        image: user?.image,
                        receiver: {
                            _id: user?.id,
                            image: user?.image,
                            name: user?.name,
                            userName: user?.userName
                        },
                    }
                ))
                setAllUserData(filterOption)
            }
        }
        catch (error) {

        }
    }


    // useEffect(() => {

    // }, [])



    useEffect(() => {
        if (isWindow) {
            document.body.classList.add('hidden');
        } else {
            document.body.classList.remove('hidden');
        }
    }, [isWindow]);



    /**
     * Handle Send Message Function
     */

    const handleSendMessage = () => {

        if (chatMessage) {
            let receiverId;

            if (activeChatUser?.receiver_id) {
                receiverId = activeChatUser?.receiver_id
            } else {
                const newReceiverId = userData.find(item => item._id === activeChatUser._id && item.participants.find(participantId => participantId !== userId));
                receiverId = newReceiverId?.receiver?._id;
            }
            const data = {
                senderId: userId,
                reciverId: receiverId,
                message: chatMessage
            };

            //socket to send message ...
            console.log('dataaaaa', data)
            socket.emit('message', data);
            getMessage();
            getChatMessagesList()
            document.getElementById('messageInput').textContent = '';
            fetchRooms();
            setChatMessage('');
            setTimeout(() => {
                scrollToBottom();
            }, 500);
        }

    };

    const handleKeyPress = (event) => {

        if (event.key === 'Enter') {
            event.preventDefault();
            handleSendMessage()
        }
        fetchRooms()

    }


    /**
     * Get Rooms List ...
     */
    const fetchRooms = async () => {
        try {
            const response = await cs.get(`roomList/${userId}`, 'chatMessage', {}, headers);
            if (response?.data?.code == 200) {
                setFilterData(response?.data?.data)
                setUserData(response?.data?.data);
                let readAll = 0
                for (const item of response?.data?.data || []) {
                    if (item?.unreadMessageCount > 0 && userId !== item?.lastMessageBy) {
                        dispatch(updateUnreadMessage(true));
                        readAll = 1
                        break;
                    }
                }
                if (readAll == 0) {
                    dispatch(updateUnreadMessage(false));
                }
            }
        }
        catch (error) {

        }
    }


    /**
     * Socket to Track Unread Messages ...
     */

    const trackReadMessage = (room_Id, lastMessageBy) => {

        let data = {
            roomId: room_Id,
            userId: lastMessageBy,
        }

        socket.emit('updateRoomCount', data);
    }


    useEffect(() => {
        if (activeChatUser)
            trackReadMessage(activeChatUser?._id, activeChatUser?.lastMessageBy)
    }, [activeChatUser])



    /**
     * Scroll Chat to Bottom ...
     */
    const scrollToBottom = () => {

        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }

    };


    useEffect(() => {
        fetchRooms()
        trackReadMessage(activeChatUser?._id, activeChatUser?.lastMessageBy)
    }, [isChat])



    const getMessage = (receivedData) => {

        setChatHistory(prevHistory => [...prevHistory, receivedData]);
        setFilterChat(prevHistory => [...prevHistory, receivedData]);


        if (!isChat) {
            setUserData(prevUserDataList => {
                if (prevUserDataList?._id !== userId) {
                    let updatedUserDataList = prevUserDataList;
                    updatedUserDataList = updatedUserDataList?.map((item) => {
                        if (item._id === receivedData?.roomId) {
                            item.lastMessage = receivedData.message;
                            item.lastMessageDate = receivedData.lastMessageDate;
                            item.unreadMessageCount = receivedData.unreadCount;
                        }
                        return item;
                    })
                    return updatedUserDataList;
                }
            });
        }

        setTimeout(() => {
            scrollToBottom()
        }, 500)

    };


    useEffect(() => {
        socket.on('newMessage', getMessage);
        // getMessage()
        return () => {
            socket.off('newMessage', getMessage);
        };
    }, [socket, handleSendMessage]);


    useEffect(() => {
        scrollToBottom();
    }, [isChat]);


    const handleInput = (e) => {
        setChatMessage(e.target.textContent);
    };


    const handleEmojiClick = (emoji) => {
        const inputElement = document.getElementById('messageInput');
        const newMessage = inputElement.innerHTML + emoji.emoji;
        setChatMessage(newMessage); // Update the state with the new message
        inputElement.innerHTML = newMessage;
    };

    // const formatTime = (time) => {
    //     const currentTime = moment();
    //     const localTime = moment(time);
    //     const daysDifference = localTime.diff(currentTime, 'days');


    //     if (daysDifference === 0 && localTime.isSame(currentTime, 'day')) {
    //         return `Today ${localTime.format('h:mma')}`;
    //     } else if (daysDifference > -7) {
    //         return localTime.format('dddd h:mma');
    //     } else if (localTime.year() !== moment().year()) {
    //         return localTime.format('MMM D, YYYY h:mma');
    //     } else {
    //         return localTime.format('MMM D h:mma');
    //     }
    // };

    const formatTime = (time) => {
        const currentTime = moment();
        const localTime = moment(time);

        // Start of the current day (00:00)
        const startOfToday = currentTime.clone().startOf('day');

        if (localTime.isSame(currentTime, 'day') && localTime.isSameOrAfter(startOfToday)) {
            return `Today ${localTime.format('h:mma')}`;
        } else if (localTime.isSame(currentTime, 'week')) {
            return localTime.format('dddd h:mma');
        } else if (localTime.year() !== currentTime.year()) {
            return localTime.format('MMM D, YYYY h:mma');
        } else {
            return localTime.format('MMM D h:mma');
        }
    };



    return (
        <>
            <div className='chat-wrapper' ref={chatRef} onClick={() => handleAddActive()} >
                <div className='chat-header' >
                    {isChat ? '' : <div className='chat-close' onClick={() => handleHideChat()}><PreviousArrow /></div>}
                    {isChat ? <div className='back-btn' onClick={() => handelBackbtn()}><PreviousArrow /></div> : ''}
                    <div className='chat-title' onClick={handleAddActive}>Chat</div>
                    {!isChat ? <div className={`add-icon ${true ? 'active' : ''}`} onClick={() => handleAddUser()}><AddIcon /></div>
                        : <div className='active-user-wrap cursor-pointer' onClick={() => { navigate(`/profile/${activeChatUser?.receiver?._id}`, { state: { type: "user", id: activeChatUser?.receiver?._id } }) }}>
                            <div className='active-user-name'>{activeChatUser?.receiver?.name ? activeChatUser?.receiver?.name : activeChatUser?.receiver?.userName}</div>
                            {/* You can add inactive className when user in not active */}
                            <div className={`active-user-img  ${activeChatUser?.receiver?.live ? 'active' : 'inactive'}`}>
                                <img src={activeChatUser?.receiver?.image ? activeChatUser?.receiver?.image : UserImg} alt='User' />
                            </div>
                        </div>}
                    {!isChat && <div className={`add-user-wrap react-select ${addUser ? 'active' : ''}`} onClick={(e) => e.stopPropagation()}>
                        <div className='icon-wrap'><SearchIcon /></div>
                        <Select className="react-select-container"
                            classNamePrefix="react-select" options={allUsersData} components={{ Option }} onChange={(selectedOption) => handleNewChat(selectedOption)}
                            placeholder="Start new chat with…" />
                    </div>}
                </div>
                <div className='chat-container'>
                    {!isChat && <div className='chat-search-wrap'>
                        <div className='chat-search'>
                            <InputGroup>
                                <Form.Control
                                    placeholder="Search..."
                                    name="search"
                                    onChange={(e) => { setSearchText(e.target.value) }}
                                />
                                <InputGroup.Text  >
                                    <div className='icon-wrap' onClick={handleSearch} ><SearchIcon /></div>
                                </InputGroup.Text>
                            </InputGroup>
                        </div>
                    </div>}

                    {isChat ?
                        <>
                            <div className='chat-area-wrapper' >
                                <Scrollbars className='chat-scroll-wrapper' style={{ width: '100%', height: '100%', paddingBottom: '20px' }} disableHorizontalScrolling>
                                    {filterChat && filterChat?.map((me) => (
                                        me && <div className='chat-area'>
                                            {me?.senderId !== userId && <div className='chat-content-row'>
                                                <div className={`user-img-wrap cursor-pointer ${activeChatUser?.receiver?.live ? 'active' : 'inactive'}`} onClick={() => { navigate(`/profile/${activeChatUser?.receiver?._id}`, { state: { type: "user", id: activeChatUser?.receiver?._id } }) }}>
                                                    <img src={activeChatUser?.receiver?.image ? activeChatUser?.receiver?.image : UserImg} alt='Avatar' />
                                                </div>
                                                <div className='chat-content-wrap'>
                                                    <div className='user-info-wrap'>
                                                        <div className='user-name'>{activeChatUser?.receiver?.name ? activeChatUser?.receiver?.name : activeChatUser?.receiver?.userName}</div>
                                                        <div className='chat-time-wrap'>
                                                            {formatTime(me?.createdAt)}
                                                        </div>
                                                    </div>
                                                    <div className='chat-message-wrap'>{me?.message}</div>
                                                </div>
                                            </div>}
                                            {me?.senderId === userId && <div className='chat-content-row sender'>
                                                <div className={`user-img-wrap cursor-pointer ${userDetails?.live ? 'active' : 'inactive'}`} onClick={() => { navigate(`/profile/${userDetails?._id}`, { state: { type: "user", id: userDetails?._id } }) }}>
                                                    <img src={userDetails?.image ? userDetails?.image : UserImg} alt='Avatar' />
                                                </div>
                                                <div className='chat-content-wrap'>
                                                    <div className='user-info-wrap'>
                                                        <div className='user-name'>{userDetails?.name ? userDetails?.name : userDetails?.userName}</div>
                                                        <div className='chat-time-wrap'>
                                                            {formatTime(me?.createdAt)}
                                                        </div>
                                                    </div>
                                                    <div className='chat-message-wrap'>{me?.message}</div>
                                                </div>
                                            </div>}
                                        </div>
                                    ))}
                                    <div ref={bottomRef}></div>
                                </Scrollbars>

                                <div className='chat-footer'>
                                    <span style={{ cursor: 'pointer' }} onClick={() => { setShowEmoji(!showEmoji) }}>😊</span>
                                    <div className='enter-mesage-wrap'>
                                        <span role="textbox"
                                            contentEditable
                                            onInput={handleInput}
                                            id="messageInput"
                                            onKeyPress={handleKeyPress}
                                            suppressContentEditableWarning={true}
                                        ></span>

                                    </div>
                                    <div className='send-message'>
                                        <Button variant='link' type='submit' onClick={handleSendMessage}><PaperBoatIcon /></Button>
                                    </div>

                                </div>
                            </div>
                            {showEmoji && <EmojiPicker open={showEmoji} onEmojiClick={(emoji) => handleEmojiClick(emoji)} style={{ position: 'absolute', top: '-8px', right: '390px', width: '300px', height: '380px' }} />}
                        </>
                        :
                        <>
                            <div className='chat-user-list-wrap' onClick={handleAddActive}>

                                <Scrollbars className='chat-scroll-wrapper' style={{ width: '100%', height: '100%' }} disableHorizontalScrolling>
                                    <div className='chat-user-list'>
                                        {filterData && filterData?.map((item) => (
                                            item && item?._id !== userId && (
                                                <div className='chat-user' key={item?._id}>
                                                    <div className='chat-user-row' onClick={() => handleChat(item)}>
                                                        <div className={`user-thumbnail ${item?.receiver?.live ? 'active' : 'inactive'}`}>
                                                            <img src={item?.receiver?.image ? item?.receiver?.image : UserImg} alt='' />
                                                        </div>
                                                        <div className='chat-user-info-wrap'>
                                                            <div className='chat-user-title'>{item?.receiver?.name ? item?.receiver?.name : item?.receiver?.userName}</div>
                                                            <div className='chat-notification-message'>{item?.lastMessage}</div>
                                                        </div>
                                                        <div className='chat-notification'>
                                                            {item?.lastMessageBy !== userId && item && item?.unreadMessageCount !== 0 && (
                                                                <div className='chat-notification-count'>{item?.unreadMessageCount}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                </Scrollbars>
                            </div>

                        </>
                    }
                </div>
            </div>

        </>
    )
}

export default Chat